import React from 'react'
import moment from 'moment'
import socketIOClient from 'socket.io-client'

class Main extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.socket = socketIOClient(`${process.env.REACT_APP_SERVER_URL}/`, {
            withCredentials: true
        })

        this.weekdays = {
            est: ["Esmaspäev", "Teisipäev", "Kolmapäev", "Neljapäev", "Reede", "Laupäev", "Pühapäev"]
        }

        this.months = {
            est: ["Jaanuar", "Veebruar", "Märts", "Aprill", "Mai", "Juuni", "Juuli", "August", "September", "Oktoober", "November", "Detsember"]
        }

        this.state = {
            _id: "",
            cardID: "",
            workers: {},
            time: moment().format("HH:mm:ss"),
            modal: "",
            notif: {},
            summary: '',
            summary2: '',
            fbconfirm: false
        }
    }

    parseTimeToArr = time => {
        return time?.split(":")
    }

    onCardInsert = e => {
        if (this.state.modal) return
        const date = moment().format("YYYY-MM-DD")
        const time = moment().format("HH:mm:ss")
        if (e.key === "F9") {
            this.setState({modal: "feedback"})
        } else if (e.key === "Enter") {
            this.socket.emit("employeeValidate", {cardID: this.state.cardID, date, time})
        } else {
            this.setState({cardID: this.state.cardID + e.key})
        }
    }

    getEmployee = data => {
        data?.forEach((worker, i) => {
            let seconds = 0
            let currentDate = moment().format("YYYY-MM-DD")
            let datesArr = Object.keys(worker.logs)

            if (datesArr[datesArr.length-1] !== currentDate || worker.logs[datesArr[datesArr.length-1]]?.times[worker.logs[datesArr[datesArr.length-1]]?.times-1]?.split(":")[0] === "end") {
                this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                return
            }
            
            if (datesArr[datesArr.length-1] === currentDate) {
                if (!Object.keys(worker.logs[currentDate]).length) {
                    this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                    return
                }
                if (worker.logs[currentDate].times[0]?.split(":")[0] === "end") {
                    let a = this.parseTimeToArr(worker.logs[currentDate].times[0])
                    let b = this.parseTimeToArr(worker.logs[datesArr[datesArr.length-2]]?.times[worker.logs[datesArr[datesArr.length-2]].times.length-1])
                    if (!a || !b) return
                    seconds += moment(`${currentDate} ${a[1]}:${a[2]}:${a[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${datesArr[datesArr.length-2]} ${b[1]}:${b[2]}:${b[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                    
                    worker.logs[currentDate].times.forEach((key, i) => {
                        if (i === 0) {
                            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                            return
                        }
                        if (!worker.logs[currentDate].times[i*2-1]) {
                            if (i+1 === worker.logs[currentDate].times.length) {
                                this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                            }
                            return
                        }
                        
                        if (worker.logs[currentDate].times[i*2]) {
                            a = this.parseTimeToArr(worker.logs[currentDate].times[i*2])
                            b = this.parseTimeToArr(worker.logs[currentDate].times[i*2-1])
                            
                            seconds += moment(`${currentDate} ${a[1]}:${a[2]}:${a[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${currentDate} ${b[1]}:${b[2]}:${b[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                        }

                        if (i+1 === worker.logs[currentDate].times.length) {
                            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                        }
                    })
                } else {
                    worker.logs[currentDate].times.forEach((key, i) => {
                        let a
                        let b
                        if (!worker.logs[currentDate].times[i*2]) {
                            if (i+1 === worker.logs[currentDate].times.length) {
                                this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                            }
                            return
                        }
                        
                        if (worker.logs[currentDate].times[i*2+1]) {
                            a = this.parseTimeToArr(worker.logs[currentDate].times[i*2+1])
                            b = this.parseTimeToArr(worker.logs[currentDate].times[i*2])

                            seconds += moment(`${currentDate} ${a[1]}:${a[2]}:${a[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${currentDate} ${b[1]}:${b[2]}:${b[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                        }

                        if (i+1 === worker.logs[currentDate].times.length) {
                            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                        }
                    })
                }
            }
        })
    }

    changeEmployee = data => {
        // const date = moment().format("YYYY-MM-DD")
        // const time = moment().format("HH:mm:ss")
        this.socket.emit("employeeGet", {start: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD")})
        
        // console.log(data)
        if (data?.error || !data) {
            // console.log(data)
            this.setState({cardID: ""})
            return
        }

        if (!data?.origin) {
            this.setState({modal: "main", cardID: "", _id: data._id})
        }
        // if (data?.origin) {
        //     this.setState({
        //         _id: "",
        //         cardID: "",
        //         workers: {},
        //         modal: "",
        //         notif: {}
        //     })
        //     return
        // }

        // if (!data.checkedIn) { //data = changed worker, if changed worker not working after change
        //     let datesArr = Object.keys(this.state.workers[data._id]?.logs) // tootaja koik logid
        //     if (Object.keys(this.state.workers[data._id]?.logs || {}).length) {
        //         if (this.state.workers[data._id]?.logs?.[date]?.times?.length) { // kui tana on logid
        //             let latest = this.state.workers[data._id].logs?.[date]?.times?.[this.state.workers[data._id].logs[date].times.length-1]?.split(":") // teeb viimase aja arrayks
        //             let seconds = moment().diff(moment(`${date} ${latest[1]}:${latest[2]}:${latest[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds') // mitu sekundit on moodas viimasest ajast
        //             this.setState({modal: data?.origin ? "" : "main", cardID: "", _id: data?.origin ? "" : data._id, workers: {...this.state.workers, [data._id]: {...this.state.workers[data._id], checkedIn: data.checkedIn, seconds: this.state.workers[data._id].seconds + seconds, logs: {...this.state.workers[data._id].logs, [date]: {...this.state.workers[data._id].logs[date], times: [...this.state.workers[data._id].logs[date].times, `end:${time}`]}}}}})
        //         } else {
        //             let latest = this.parseTimeToArr(this.state.workers[data._id]?.logs[datesArr[datesArr.length-1]]?.times[this.state.workers[data._id]?.logs[datesArr[datesArr.length-1]].times.length-1])
        //             let seconds = moment().diff(moment(`${datesArr[datesArr.length-1]} ${latest[1]}:${latest[2]}:${latest[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
        //             this.setState({modal: data?.origin ? "" : "main", cardID: "", _id: data?.origin ? "" : data._id, workers: {...this.state.workers, [data._id]: {...this.state.workers[data._id], checkedIn: data.checkedIn, seconds, logs: {...this.state.workers[data._id].logs, [date]: {...this.state.workers[data._id].logs[date], times: [`end:${time}`]}}}}})
        //         }
        //     } else {
        //         this.setState({modal: data?.origin ? "" : "main", cardID: "", _id: data?.origin ? "" : data._id, workers: {...this.state.workers, [data._id]: {...this.state.workers[data._id], checkedIn: data.checkedIn, seconds, logs: {...this.state.workers[data._id].logs, [date]: {...this.state.workers[data._id].logs[date], times: [`end:${time}`]}}}}})
        //     }
        // } else {
        //     this.setState({modal: data?.origin ? "" : "main", cardID: "", _id: data?.origin ? "" : data._id, workers: {...this.state.workers, [data._id]: {...this.state.workers[data._id], checkedIn: data.checkedIn, logs: {...this.state.workers[data._id].logs, [date]: {...this.state.workers[data._id].logs?.[date], times: this.state.workers[data._id].logs?.[date]?.times ? [...this.state.workers[data._id]?.logs?.[date]?.times, `start:${time}`] : [`start:${time}`]}}}}})
        // }
    }

    componentDidMount() {
        window.removeEventListener('keydown', this.onCardInsert)
        window.addEventListener('keydown', this.onCardInsert)

        this.socket.emit("employeeGet", {start: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD")})
        this.socket.on("get_employee", this.getEmployee)
        this.socket.on("change_employee", this.changeEmployee);


        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(settings => {
            this.setState({settings})
        }).catch(err => {
            console.log(err)
        })

        fetch(`${process.env.REACT_APP_SERVER_URL}/notifications/get`, {
            method: 'POST',
            body: JSON.stringify({
                type: "workertime"
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            data.forEach(doc => {
                this.setState({notif: {...this.state.notif, [doc.worker]: {deviation: doc.data?.deviation, update: doc.update}}})
            })
        }).catch(err => {
            console.log(err)
        })

        setInterval(() => {
            this.setState({time: moment().format("HH:mm:ss")})
        }, 1000)
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onCardInsert)
        this.socket.off("get_employee", this.getEmployee);
        this.socket.off("change_employee", this.changeEmployee);
    }

    back = e => {
        e.preventDefault()
        this.setState({modal: "main"})
    }

    report = e => {
        e.preventDefault()

        let id = this.state._id
        let seconds = 0
        let limited = []
        let timesArr = []
        let seconds2 = 0
        let limited2 = []
        let timesArr2 = []
        Object.keys(this.state.workers[id]?.logs || {}).forEach(day => {
            if (day < moment().startOf('month').format("YYYY-MM-DD") || day > moment().endOf('month').format("YYYY-MM-DD")) return
            let times = this.state.workers[id].logs[day].times
            if (times?.[0]?.split(":")[0] === "end") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        seconds += moment(`${day} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                        limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                    }
                    if (times[i*2+1]) {
                        let a = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+2]) {
                            let b = moment(`${day} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds += b.diff(a, 'seconds')
                            timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                        } else {
                            if (day !== moment().format("YYYY-MM-DD")) {
                                let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                seconds += b.diff(a, 'seconds')
                                limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            }
                        }
                    }
                    if (i+1===times.length) {
                        let minutes = Math.floor(seconds / 60)
                        let secondss = seconds%60
                        let hours = Math.floor(minutes/60)
                        minutes = minutes%60

                        this.setState({summary: `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${secondss < 10 ? "0" + secondss : secondss}`})
                    }
                })
            } else if (times?.[0]?.split(":")[0] === "start") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        limited.push(0)
                    }
                    if (times[i*2]) {
                        let a = moment(`${day} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+1]) {
                            let b = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds += b.diff(a, 'seconds')
                            timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                            timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                        } else {
                            if (day !== moment().format("YYYY-MM-DD")) {
                                let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                seconds += b.diff(a, 'seconds')
                                limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                            }
                        }
                    }
                    if (i+1===times.length) {
                        let minutes = Math.floor(seconds / 60)
                        let secondss = seconds%60
                        let hours = Math.floor(minutes/60)
                        minutes = minutes%60

                        this.setState({summary: `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${secondss < 10 ? "0" + secondss : secondss}`})
                    }
                })
            }
        })

        Object.keys(this.state.workers[id]?.logs || {}).forEach(day => {
            if (day < moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD") || day > moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD")) return
            let times = this.state.workers[id].logs[day].times
            if (times?.[0]?.split(":")[0] === "end") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        seconds2 += moment(`${day} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                        limited2.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                    }
                    if (times[i*2+1]) {
                        let a = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+2]) {
                            let b = moment(`${day} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds2 += b.diff(a, 'seconds')
                            timesArr2.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            timesArr2.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                        } else {
                            let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                            seconds2 += b.diff(a, 'seconds')
                            limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                        }
                    }
                    if (i+1===times.length) {
                        let minutes2 = Math.floor(seconds2 / 60)
                        let seconds2s = seconds2%60
                        let hours2 = Math.floor(minutes2/60)
                        minutes2 = minutes2%60
                        
                        this.setState({summary2: `${hours2 < 10 ? "0" + hours2 : hours2}:${minutes2 < 10 ? "0" + minutes2 : minutes2}:${seconds2s < 10 ? "0" + seconds2s : seconds2s}`})
                    }
                })
            } else if (times?.[0]?.split(":")[0] === "start") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        limited2.push(0)
                    }
                    if (times[i*2]) {
                        let a = moment(`${day} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+1]) {
                            let b = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds2 += b.diff(a, 'seconds')
                            timesArr2.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                            timesArr2.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                        } else {
                            let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                            seconds2 += b.diff(a, 'seconds')
                            limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                        }
                    }
                    if (i+1===times.length) {
                        let minutes2 = Math.floor(seconds2 / 60)
                        let seconds2s = seconds2%60
                        let hours2 = Math.floor(minutes2/60)
                        minutes2 = minutes2%60
                        
                        this.setState({summary2: `${hours2 < 10 ? "0" + hours2 : hours2}:${minutes2 < 10 ? "0" + minutes2 : minutes2}:${seconds2s < 10 ? "0" + seconds2s : seconds2s}`})
                    }
                })
            }
        })

        this.setState({modal: "report"})
    }

    continue = e => {
        e.preventDefault()

        if (e.target.id === "summary") {
            this.setState({modal: "summary"})
        } else {
            this.setState({modal: "", _id: "", summary: '', summary2: ''})
        }
    }

    closeFB = e => {
        e.preventDefault()
        this.setState({modal: ""})
    }

    sendFB = e => {
        e.preventDefault()

        if (e.target.id === "send") {
            this.setState({fbconfirm: true})
        } else if (e.target.id === "confirm") {
            let title = document.getElementById("title").value
            let feedback = document.getElementById("feedback").value
    
            fetch(`${process.env.REACT_APP_SERVER_URL}/feedback/send`, {
                method: 'POST',
                body: JSON.stringify({title, feedback}),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(() => {
                this.socket.emit("trigger_notify")
                this.setState({modal: "", fbconfirm: false})
            }).catch(err => {
                console.log(err)
            })
        } else this.setState({fbconfirm: false})
    }

    sendNotif = (id, deviation) => {
        const fetchFunc = () => {
            fetch(`${process.env.REACT_APP_SERVER_URL}/notifications/set`, {
                method: 'POST',
                body: JSON.stringify({
                    worker: id,
                    deviation,
                    type: "workertime"
                }),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(res => {
                return res.json()
            }).then(data => {
                this.setState({notif: {...this.state.notif, [data.worker]: {deviation: data.data?.deviation, update: data.update}}})
            }).catch(err => {
                console.log(err)
            })
        }

        if (deviation === 1) {
            if (this.state.notif?.[id]) {
                if (this.state.notif?.[id]?.deviation && (moment().diff(moment(this.state.notif?.[id]?.update), 'hours') > 10)) {
                    fetchFunc()
                }
            } else {
                fetchFunc()
            }
        } 
        // else if (deviation === 2) {
        //     if (this.state.notif?.[id]) {
        //         if (this.state.notif?.[id]?.deviation === 1) {
        //             fetchFunc()
        //         }
        //         if (this.state.notif?.[id]?.deviation === 2 && (moment().diff(moment(this.state.notif?.[id]?.update), 'hours') > 10)) {
        //             fetchFunc()
        //         }
        //     } else {
        //         fetchFunc()
        //     }
        // }
        
    }

    render() {
        const settings = this.state.settings

        return (
            <div className="main-page-content">
                <div className="main-page">
                    <div className="table">
                        <div className="row row-sticky">
                            <h1 className="name name-header">Nimi</h1>
                            <h1 className="duration duration-header">Tööl viibitud aeg</h1>
                            <div className="times">
                                <h1 className="timeheader">Sisse-välja registreerimise ajad</h1>
                            </div>
                        </div>
                        {Object.keys(this.state.workers).sort((a, b) => {
                            if(this.state.workers[a].firstName.toLowerCase() < this.state.workers[b].firstName.toLowerCase()) return -1
                            if(this.state.workers[a].firstName.toLowerCase() > this.state.workers[b].firstName.toLowerCase()) return 1
                            return 0
                        }).map(key => {
                            let {firstName, lastName, logs, checkedIn} = this.state.workers[key]
                            let currentDate = moment().format("YYYY-MM-DD")
                            let datesArr = Object.keys(logs)
                            let yesterdayLast = datesArr[datesArr.length-1] === currentDate ? logs[datesArr[datesArr.length-2]]?.times?.[logs[datesArr[datesArr.length-2]]?.times?.length-1] : logs[datesArr[datesArr.length-1]]?.times?.[logs[datesArr[datesArr.length-1]]?.times?.length-1]
                            let yl = this.parseTimeToArr(yesterdayLast)

                            if (!logs?.[currentDate] && (!yesterdayLast || yl[0] === "end")) return
                            let seconds = this.state.workers[key].seconds
                            let latest = this.parseTimeToArr(logs?.[currentDate]?.times?.[logs[currentDate].times.length-1])
                            
                            if (latest?.[0] === "start") {
                                seconds += moment().diff(moment(`${currentDate} ${latest[1]}:${latest[2]}:${latest[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                            } else {
                                if (yl?.[0] === "start" && latest?.[0] !== "end") {
                                    seconds += moment().diff(moment(`${datesArr[datesArr.length-1] === currentDate ? datesArr[datesArr.length-2] : datesArr[datesArr.length-1]} ${yl[1]}:${yl[2]}:${yl[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                                }
                            }

                            if (settings?.workers) {
                                if (this.state.workers[key].type === "production") {
                                    if (seconds > (settings.workers.prod1+settings.workers.prod2)*3600) {
                                        this.sendNotif(key, 1)
                                    }
                                }
                                if (this.state.workers[key].type === "forklift") {
                                    if (seconds > (settings.workers.fork1+settings.workers.fork2)*3600) {
                                        this.sendNotif(key, 1)
                                    }
                                }
                                if (this.state.workers[key].type === "nightguard") {
                                    if (seconds > (settings.workers.night1+settings.workers.night2)*3600) {
                                        this.sendNotif(key, 1)
                                    }
                                }
                                if (this.state.workers[key].type === "processor") {
                                    if (seconds > (settings.workers.proc1+settings.workers.proc2)*3600) {
                                        this.sendNotif(key, 1)
                                    }
                                }
                                
                                // if (seconds > (settings.workers.night1+settings.workers.night3)*3600) {
                                //     this.sendNotif(key, 2)
                                // } else 
                            }

                            let minutes = Math.floor(seconds / 60)
                            seconds = seconds%60
                            let hours = Math.floor(minutes/60)
                            minutes = minutes%60
                            return (
                                <div className="row" key={key}>
                                    <h1 className="name">{firstName} {lastName}</h1>
                                    <h1 className="duration">{`${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`}</h1>
                                    <div className="times">
                                        {yesterdayLast?.split(":")[0] === "start" &&
                                            <h1 className="time">{`${yesterdayLast.split(":")[1]}:${yesterdayLast.split(":")[2]}`} - {`${logs?.[currentDate]?.times[0]?.split(":")[1] || "--"}:${logs?.[currentDate]?.times[0]?.split(":")[2] || "--"}`}</h1>
                                        }
                                        {logs[currentDate]?.times.map((key, i) => {
                                            if (yesterdayLast?.split(":")[0] === "start") {
                                                if (!logs[currentDate].times[i*2+1]) return
                                                return <h1 className="time" key={i}>{`${logs[currentDate].times[i*2+1].split(":")[1]}:${logs[currentDate].times[i*2+1].split(":")[2]}`} - {`${logs[currentDate].times[i*2+2]?.split(":")[1] || "--"}:${logs[currentDate].times[i*2+2]?.split(":")[2] || "--"}`}</h1>
                                            } else {
                                                if (!logs[currentDate].times[i*2]) return
                                                return <h1 className="time" key={i}>{`${logs[currentDate].times[i*2].split(":")[1]}:${logs[currentDate].times[i*2].split(":")[2]}`} - {`${logs[currentDate].times[i*2+1]?.split(":")[1] || "--"}:${logs[currentDate].times[i*2+1]?.split(":")[2] || "--"}`}</h1>                                                
                                            }
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {this.state.modal &&
                    <div className="modal">
                        {this.state.modal === "main" &&
                        <div className="modal-content">
                            <h1 className="welcometext">{this.state.workers[this.state._id]?.checkedIn ? `Tere, ${this.state.workers[this.state._id]?.firstName}, ilusat tööpäeva!` : `Tere, ${this.state.workers[this.state._id]?.firstName}. Tööaeg peatatud!`}</h1>
                            <div className="buttons">
                                <div className="check-button" onClick={this.report}>
                                    <i className="fas fa-tasks"/>
                                    <h1>Vaata aruannet</h1>
                                </div>
                                
                                <div className="check-button" id="choose" onClick={this.continue}>
                                    <i className="fas fa-arrow-right"/>
                                    <h1>Jätka</h1>
                                </div>
                            </div>
                        </div> ||
                        this.state.modal === "summary" &&
                        <div className="modal-content">
                            <h1 className="heading">Kokkuvote</h1>
                            <div className="">

                            </div>
                            <div className="backbutton" onClick={this.back}>
                                <i className="fas fa-arrow-left"/>
                            </div>
                        </div> ||
                        this.state.modal === "report" &&
                        <div className="modal-content">
                            <h1 className="heading">Sinu 2 kuu kokkuvõte</h1>
                            <div className="summary">
                                <div className="summary-last2">
                                    <p>Eelmine kuu</p>
                                    <p>{this.state.summary2 || "00:00:00"}</p>
                                </div>
                                <div className="summary-last">
                                    <p>See kuu</p>
                                    <p>{this.state.summary || "00:00:00"}</p>
                                </div>
                            </div>
                            <div className="report">
                                <div className="row row-sticky">
                                    <h1 className="name name-header">Päev</h1>
                                    <h1 className="duration">Tööl viibitud aeg</h1>
                                    <div className="times">
                                        <h1 className="timeheader">Sisse-välja registreerimise ajad</h1>
                                    </div>
                                </div>
                                {Array(62).fill(0,0,62).map((key, j) => {
                                    
                                    if (moment().subtract(j, 'days').startOf('month').format("YYYY-MM-DD") !== moment().startOf('month').format("YYYY-MM-DD") && moment().subtract(j, 'days').startOf('month').format("YYYY-MM-DD") !== moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD")) return
                                    
                                    let day = moment().subtract(j, 'days')
                                    let times = this.state.workers[this.state._id]?.logs?.[day.format("YYYY-MM-DD")]?.times
                                    let seconds = 0
                                    let minutes = 0
                                    let hours = 0

                                    let limited = []
                                    let timesArr = []

                                    if (times?.[0]?.split(":")[0] === "end") {
                                        times.forEach((key, i) => {
                                            if (i === 0) {
                                                seconds += moment(`${day.format("YYYY-MM-DD")} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                                                limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                                            }
                                            if (times[i*2+1]) {
                                                let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                if (times[i*2+2]) {
                                                    let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                    seconds += b.diff(a, 'seconds')
                                                    timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                    timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                                                } else {
                                                    let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                                    seconds += b.diff(a, 'seconds')
                                                    limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                }
                                            }
                                            if (i+1===times.length) {
                                                minutes = Math.floor(seconds / 60)
                                                seconds = seconds%60
                                                hours = Math.floor(minutes/60)
                                                minutes = minutes%60
                                            }
                                        })
                                    } else if (times?.[0]?.split(":")[0] === "start") {
                                        times.forEach((key, i) => {
                                            if (i === 0) {
                                                limited.push(0)
                                            }
                                            if (times[i*2]) {
                                                let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                if (times[i*2+1]) {
                                                    let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                    seconds += b.diff(a, 'seconds')
                                                    timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                                    timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                } else {
                                                    let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                                    seconds += b.diff(a, 'seconds')
                                                    limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                                }
                                            }
                                            if (i+1===times.length) {
                                                minutes = Math.floor(seconds / 60)
                                                seconds = seconds%60
                                                hours = Math.floor(minutes/60)
                                                minutes = minutes%60
                                            }
                                        })
                                    }
                                    
                                    return <div className="row" key={j}>
                                        {/* <h1 className="name">{this.weekdays.est[day.format('d')-1 >= 0 ? day.format('d')-1 : 6]} {j === 6 && "(Täna)"}</h1> */}
                                        <h1 className="name">{`${day.format("DD")}. ${this.months.est[day.format('M')-1]}`}</h1>
                                        <h1 className="duration">{`${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`}</h1>
                                        <div className="times">
                                            {!!limited[0] && <h1 className="time">{`--:-- - ${limited[0]}`}</h1>}
                                            {timesArr.map((key, i) => {
                                                if (!timesArr[i*2]) return
                                                return <h1 className="time" key={i}>{`${timesArr[i*2]} - ${timesArr[i*2+1]}`}</h1>
                                            })}
                                            {!!limited[1] && <h1 className="time">{`${limited[1]} - --:--`}</h1>}
                                        </div>
                                    </div>
                                })}
                            </div>
                            <div className="backbutton" onClick={this.back}>
                                <i className="fas fa-arrow-left"/>
                                {/* <h1>Tagasi</h1> */}
                            </div>
                        </div> ||
                        this.state.modal === "feedback" &&
                        <div className="modal-content">
                            <h1 className="heading">Tagasiside</h1>
                            <input className="title" id="title" placeholder="Pealkiri" />
                            <textarea className="feedback" id="feedback" placeholder="Tagasiside sisu" />
                            <a className="close" onClick={this.closeFB}>Sulge</a>
                            <a className="send" id="send" onClick={this.sendFB}>Saada</a>
                            {this.state.fbconfirm &&
                            <div className="modal-confirm">
                                <div className="confirm-content">
                                    <h1>Kas oled kindel?</h1>
                                    <a className="close" onClick={this.sendFB}>Ei</a>
                                    <a className="send" id="confirm" onClick={this.sendFB}>Jah</a>
                                </div>
                            </div>
                            }
                        </div> ||
                        this.state.modal === "fbconfirm" &&
                        <div className="modal-content">
                        </div>
                        }
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default Main