import React from 'react'
import moment from 'moment'
import socketIOClient from 'socket.io-client'
import FileSaver from 'file-saver'
import ExcelJS from 'exceljs'

import Workers from './Workers'
import {history} from '../../../routers/AppRouter'

class Overview extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        let params = new URLSearchParams(window.location.search)
        let view = params.get('view')
        
        this.socket = socketIOClient(`${process.env.REACT_APP_SERVER_URL}/`, {
            withCredentials: true
        })

        this.weekdays = {
            est: ["Esmaspäev", "Teisipäev", "Kolmapäev", "Neljapäev", "Reede", "Laupäev", "Pühapäev"]
        }

        this.months = {
            est: ["Jaanuar", "Veebruar", "Märts", "Aprill", "Mai", "Juuni", "Juuli", "August", "September", "Oktoober", "November", "Detsember"]
        }
        
        this.state = {
            view,
            waresView: "yardstorage",
            modal: {
                view: '',
                date: '',
                id: '',
                data: {}
            },
            _id: "",
            data: {},
            archive: {},
            archiveToggle: false,
            filters: {
                active: false
            },
            stock: {},
            editStock: {},
            toggledDates: [],
            workers: {},
            page: 2,
            time: moment().format("HH:mm:ss"),
            settings: {},
            restid: {},
            summary: '',
            summary2: '',
            warehouseUnits: false,
            toggleMoreRoutes: false,
            showDates: false,
            historyModal: "",
            latestSE: {
                start: moment().startOf("month").format("YYYY-MM-DD"),
                end: moment().endOf("month").format("YYYY-MM-DD")
            },
            deleteModal: null,
            deltime: 5
        }

        this.wrapperRef = React.createRef()
    }

    // handleClickOutside = e => { SELLEGA SAI HANDLEDA EDITIMIST
    //     if (this.wrapperRef && !this.wrapperRef.current?.contains(e.target)) {
    //         let toggledValues = this.state.toggledValues
    //         let type = e.target.getAttribute("valuetype")
    //         let item = e.target.getAttribute("item")
    //         let date = moment(e.target.getAttribute("date"), "DD/MM/YY").format("YYYY-MM-DD")

    //         if (e.target.id === "resetbutton") {
    //             this.setState({toggledValues: {}})
    //             return
    //         }

    //         if (toggledValues?.item !== item || toggledValues?.date !== date || toggledValues?.type !== type) {
    //             let old = this.state.stock[toggledValues.item]?.log?.[toggledValues.date]?.[toggledValues.type]
    //             if (!toggledValues.value || (parseFloat(old) === parseFloat(toggledValues.value))) {
    //                 this.setState({toggledValues: {}})
    //                 return
    //             }

    //             this.socket.emit("saveWarehouseValue", {type: toggledValues.type, item: toggledValues.item, date: toggledValues.date, old: old || 0, value: toggledValues.value})
    //             // this.setState({/*loading: "stocktable", */toggledValues: {}})
    //         }
    //     }
    // }

    handleClickOutside = e => {
        if (this.wrapperRef && !this.wrapperRef.current?.contains(e.target)) {
            this.setState({toggleMoreRoutes: false})
        }
    }

    updateTime = () => {
        this.setState({time: moment().format("HH:mm:ss")})
    }

    fetchEmployees = (start, end) => {
        this.setState({latestSE: {start, end}})
        this.socket.emit("employeeGet", {start, end})
    }

    parseTimeToArr = time => {
        return time?.split(":")
    }

    getRestid = data => {
        if (data?.error || !data) return
        this.setState({restid: {}})
        let restid = {}
        data.forEach((item, i) => {
            restid = {...restid, [item._id]: item}
            if (i+1===data.length) this.setState({restid})
        })
    }

    getAlused = data => {
        if (data?.error || !data) return
        this.setState({alused: {}})
        let alused = {}
        data.forEach((item, i) => {
            alused = {...alused, [item._id]: item}
            if (i+1===data.length) this.setState({alused})
        })
    }

    getYardstorage = data1 => {
        if (data1?.error || !data1) return
        let archive = {}
        let data = {}
        data1.forEach((item, j) => {
            let maht = Number(item.received) - Number(item.defect || 0)
            Object.keys(item.logs || {'':''}).forEach((key, i) => {
                if (item.logs) maht -= item.logs[key] * this.state.settings?.wares?.logs?.[key]?.size || 0

                if (i+1 === Object.keys(item.logs || {'':''}).length) {
                    if (item.finished) {
                        archive = {...archive, [item._id]: {...item, maht}}
                    } else {
                        data = {...data, [item._id]: {...item, maht}}
                    }

                }
            })
        })
        this.setState({data, archive})
    }

    fetchStock = (start, end) => {
        this.setState({latestSE: {start, end}})
        this.socket.emit("stockGet", {start, end})
    }
    
    getStock = data => {
        if (data?.error || !data) return
        let stock = {}
        data.forEach((item, i) => {
            stock = {...stock, [item.article]: {name: item.name, count: item.count, unit: item.unit, type: item.type, log: item.log, convertible: item.convertible, myyk: item.myyk, haljala: item.haljala, fsc: item.fsc}}
        })
        this.setState({/*loading: null, */stock})
    }

    getEmployee = data => {
        if (data?.error || !data) return
        data.forEach((worker, i) => {
            let seconds = 0
            let currentDate = moment().format("YYYY-MM-DD")
            let datesArr = Object.keys(worker.logs)
            
            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
            if (datesArr[datesArr.length-1] !== currentDate || worker.logs[datesArr[datesArr.length-1]]?.times[worker.logs[datesArr[datesArr.length-1]]?.times-1]?.split(":")[0] === "end") {
                this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                return
            }
            
            if (datesArr[datesArr.length-1] === currentDate) {
                if (worker.logs[currentDate].times[0]?.split(":")[0] === "end") {
                    let a = this.parseTimeToArr(worker.logs[currentDate].times[0])
                    let b = this.parseTimeToArr(worker.logs[datesArr[datesArr.length-2]].times[worker.logs[datesArr[datesArr.length-2]].times.length-1])
                    seconds += moment(`${currentDate} ${a?.[1]}:${a?.[2]}:${a?.[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${datesArr[datesArr.length-2]} ${b?.[1]}:${b?.[2]}:${b?.[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                    
                    worker.logs[currentDate].times.forEach((key, i) => {
                        if (i === 0) {
                            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                            if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                            return
                        }
                        if (!worker.logs[currentDate].times[i*2-1]) {
                            if (i+1 === worker.logs[currentDate].times.length) {
                                this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                                if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                            }
                            return
                        }
                        
                        if (worker.logs[currentDate].times[i*2]) {
                            a = this.parseTimeToArr(worker.logs[currentDate].times[i*2])
                            b = this.parseTimeToArr(worker.logs[currentDate].times[i*2-1])
                            
                            seconds += moment(`${currentDate} ${a?.[1]}:${a?.[2]}:${a?.[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${currentDate} ${b?.[1]}:${b?.[2]}:${b?.[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                        }

                        if (i+1 === worker.logs[currentDate].times.length) {
                            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                            if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                        }
                    })
                } else {
                    worker.logs[currentDate].times.forEach((key, i) => {
                        let a
                        let b
                        if (!worker.logs[currentDate].times[i*2]) {
                            if (i+1 === worker.logs[currentDate].times.length) {
                                this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                                if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                            }
                            return
                        }
                        
                        if (worker.logs[currentDate].times[i*2+1]) {
                            a = this.parseTimeToArr(worker.logs[currentDate].times[i*2+1])
                            b = this.parseTimeToArr(worker.logs[currentDate].times[i*2])

                            seconds += moment(`${currentDate} ${a?.[1]}:${a?.[2]}:${a?.[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${currentDate} ${b?.[1]}:${b?.[2]}:${b?.[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                        }

                        if (i+1 === worker.logs[currentDate].times.length) {
                            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                            if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                        }
                    })
                }
            }
        })
    }

    changeEmployee = () => this.socket.emit("employeeGet", {start: this.state.latestSE.start, end: this.state.latestSE.end})

    changeRestid = () => this.socket.emit("restidGet")
    changeAlused = () => this.socket.emit("alusedGet")
    changeYardstorage = () => this.socket.emit("yardstorageGet")
    changeStock = () => this.socket.emit("stockGet", {start: this.state.latestSE.start, end: this.state.latestSE.end})

    componentDidMount() {
        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data})
        }).catch(err => {
            console.log(err)
        })

        document.addEventListener('mousedown', this.handleClickOutside)

        this.socket.emit("restidGet")
        this.socket.on("get_restid", this.getRestid)
        this.socket.on("change_restid", this.changeRestid)
        
        this.socket.emit("alusedGet")
        this.socket.on("get_alused", this.getAlused)
        this.socket.on("change_alused", this.changeAlused)

        this.socket.emit("yardstorageGet")
        this.socket.on("get_yardstorage", this.getYardstorage)
        this.socket.on("change_yardstorage", this.changeYardstorage)

        this.socket.emit("stockGet", {start: this.state.latestSE.start, end: this.state.latestSE.end})
        this.socket.on("get_stock", this.getStock)
        this.socket.on("change_stock", this.changeStock)
    }

    componentDidUpdate(prevProps) {
        let params = new URLSearchParams(window.location.search)
        let view = params.get('view')
        if (view !== "wares" && view !== "workers") {
            history.push("/management/overview?view=wares")
            return
        }
        if (prevProps.location.search.includes(view)) return
        // if (view === "workers") {
        //     this.socket.emit("yardstorageGet")
        //     this.setState({waresView: "yardstorage", view: e.target.id, filters: {active: false}})
        // } else {
        //     this.setState({view})
        // }
        this.setState({view})
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
        this.socket.off("get_restid", this.getRestid)
        this.socket.off("change_restid", this.changeRestid)
        this.socket.off("get_yardstorage", this.getYardstorage)
        this.socket.off("change_yardstorage", this.changeYardstorage)
        this.socket.off("get_stock", this.getStock)
        this.socket.off("change_stock", this.changeStock)
    }

    // componentDidUpdate(prevProps, prevState) { SELLEGA SAI HANDLEDA EDITIMIST
    //     let toggledValues = this.state.toggledValues
    //     if (prevState.toggledValues !== toggledValues) {
    //         if (toggledValues.item !== prevState.toggledValues.item || toggledValues.date !== prevState.toggledValues.date || toggledValues.type !== prevState.toggledValues.type) {
    //             if (toggledValues.item) {
    //                 let input = document.getElementById(toggledValues.type+toggledValues.item+moment(toggledValues.date).format("DD/MM/YY"))
    //                 if (!input) return
    //                 input.focus()
    //             }
    //         }
    //     }
    // }

    exportCSV = e => {
        e.preventDefault()

        let settings = this.state.settings
        let wb = new ExcelJS.Workbook()
        let view = this.state.view

        if (view === "wares") {
            let waresView = this.state.waresView
        
            if (waresView === "yardstorage") {
                let archiveToggle = this.state.archiveToggle
                let ws = wb.addWorksheet(archiveToggle ? "Arhiiv" : "Palgiladu")

                let logHeaders = []
                Object.keys(settings?.wares?.logs || {}).forEach(key => {
                    logHeaders.push({header: key, key, width: 10})
                })

                ws.columns = [
                    {header: "Koorma nr", key: "koorem", width: 10},
                    {header: "Saatja", key: "sender", width: 20},
                    {header: "Veoseleht", key: "veoseleht", width: 10},
                    {header: "Saadetud", key: "sent", width: 11},
                    {header: "Vastuvõetud", key: "received", width: 11},
                    {header: "Praak", key: "defect", width: 8},
                    {header: "Tsoon", key: "zone", width: 8},
                    {header: "Vastuvõtu aeg", key: "startDate", width: 20},
                    {header: "Materjal", key: "material", width: 10},
                    {header: "Lõhkuja", key: "chopper", width: 12},
                    {header: "Algus", key: "chopStart", width: 12},
                    {header: "Sortiment", key: "sortiment", width: 12},
                    {header: "FSC", key: "fsc", width: 10},
                    {header: "Maha", key: "maha", width: 8},
                    ...logHeaders,
                    {header: "Maht", key: "maht", width: 10},
                    {header: "Kuupäev", key: "finishDate", width: 12},
                    {header: "Tõstukijuht", key: "driver", width: 14},
                    {header: "Kommentaarid", key: "comments", width: 20},
                    // {header: "Kontrollitud", key: "verified", width: 10},
                ]
                
                ws.getRow(1).font = {bold: true}
                ws.views = [{state: 'frozen', xSplit: 1, ySplit: 1}]

                Object.keys((archiveToggle ? {...this.state.archive, empty: ""} : {...this.state.data, empty: ""}) || {}).forEach((key, i) => {
                    if (Object.keys(archiveToggle ? this.state.archive : this.state.data).length === i) {
                        ws.eachRow({includeEmpty: true}, (row, rowNumber) => {
                            ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                        })
                    
                        wb.xlsx.writeBuffer().then(buffer => {
                            FileSaver.saveAs(new Blob([buffer]), archiveToggle ? "Arhiiv.xlsx" : "Palgiladu.xlsx")
                        }).catch(err => console.log(err))

                        return
                    }
                    let {koorem, sender, veoseleht, sent, received, defect, zone, startDate, material, chopper, chopStart, sortiment, maha, logs, finishDate, driver, comments, verified, maht, fsc} = archiveToggle ? this.state.archive[key] : this.state.data[key]
                    let filters = this.state.filters


                    if (filters.koorem && !(koorem||"").toLowerCase().includes(filters.koorem.toLowerCase())) return
                    if (filters.sender && !(sender||"").toLowerCase().includes(filters.sender.toLowerCase())) return
                    if (filters.veoseleht && !(veoseleht||"").toLowerCase().includes(filters.veoseleht.toLowerCase())) return
                    if (filters.zone && !(zone||"").toLowerCase().includes(filters.zone.toLowerCase())) return
                    if (filters.material && !(material||"").toLowerCase().includes(filters.material.toLowerCase())) return
                    if (filters.chopper && !(chopper||"").toLowerCase().includes(filters.chopper.toLowerCase())) return
                    if (filters.sortiment && !(sortiment||"").toLowerCase().includes(filters.sortiment.toLowerCase())) return
                    if (filters.tm && (!sent.includes(filters.tm) && !received.includes(filters.tm) && !defect.includes(filters.tm)/* && !MAHT.includes(filters.tm)*/)) return
                    if (filters.fsc === "1" && !fsc) return
                    if (filters.fsc === "0" && fsc) return

                    let receivedDate = moment(startDate, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD")
                    if (filters.receivedfrom > receivedDate || filters.receivedto < receivedDate) return
                    let chopStartDate = moment(chopStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                    if (filters.chopfrom > chopStartDate || filters.chopto < chopStartDate) return
                    if (filters.finishfrom > finishDate || filters.finishto < finishDate) return

                    let logsObject = {}
                    Object.keys(settings?.wares?.logs || {}).map(key => {
                        logsObject = {...logsObject, [key]: logs?.[key]}
                    })

                    ws.addRow({koorem, sender, veoseleht, sent, received, defect, zone, startDate, material, chopper, chopStart, sortiment, fsc: fsc ? "Jah" : "Ei", maha: maha ? "Jah" : "Ei", ...logsObject, maht, finishDate: finishDate ? moment(finishDate).format("DD/MM/YYYY") : "", driver: driver.name, comments})
                })
            } else if (waresView === "rest") {
                const ws = wb.addWorksheet("Lõhutud restid")

                if (this.state.showDates) ws.columns = [
                    {header: "Resti nr", key: "restNr", width: 10},
                    {header: "Koorma nr", key: "koorem", width: 10},
                    {header: "Lõhkuja", key: "chopper", width: 12},
                    {header: "Lõhkumine", key: "chopStart", width: 12},
                    {header: "Sortiment", key: "sortiment", width: 12},
                    {header: "Artikkel", key: "article", width: 12},
                    {header: "Tootmisjärk", key: "phase", width: 12},
                    {header: "Tsoon", key: "zone", width: 10},
                    {header: "Asukoht", key: "location", width: 10},
                    {header: "Tükiarv", key: "amount", width: 10},
                    {header: "Kuivatisse", key: "dryStart", width: 13},
                    {header: "Kuivatist välja", key: "dryEnd", width: 13},
                    {header: "FSC", key: "fsc", width: 10}
                ]
                else ws.columns = [
                    {header: "Resti nr", key: "restNr", width: 10},
                    {header: "Koorma nr", key: "koorem", width: 10},
                    {header: "Lõhkuja", key: "chopper", width: 12},
                    {header: "Sortiment", key: "sortiment", width: 12},
                    {header: "Artikkel", key: "article", width: 12},
                    {header: "Tootmisjärk", key: "phase", width: 12},
                    {header: "Tsoon", key: "zone", width: 10},
                    {header: "Asukoht", key: "location", width: 10},
                    {header: "Tükiarv", key: "amount", width: 10},
                    {header: "FSC", key: "fsc", width: 10}
                ]

                ws.getRow(1).font = {bold: true}
                ws.views = [{state: 'frozen', xSplit: 1, ySplit: 1}]

                Object.keys({...this.state.restid, empty: ""}).forEach((key, i) => {
                    if (Object.keys(this.state.restid).length === i) {
                        ws.eachRow({includeEmpty: true}, (row, rowNumber) => {
                            ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                        })
                    
                        wb.xlsx.writeBuffer().then(buffer => {
                            FileSaver.saveAs(new Blob([buffer]), "Lõhutud restid.xlsx")
                        }).catch(err => console.log(err))

                        return
                    }

                    let {chopper, finished, parent, phase, restNr, sortiment, fsc, article, location, amount, chopStart, dryStart, dryEnd} = this.state.restid[key]
                    if (finished) return
                    let filters = this.state.filters
                    if (filters.restnr && !(restNr||"").toLowerCase().includes(filters.restnr.toLowerCase())) return
                    if (filters.koorem && !((this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem)||"").toLowerCase().includes(filters.koorem.toLowerCase())) return
                    if (filters.chopper && !(chopper||"").toLowerCase().includes(filters.chopper.toLowerCase())) return
                    if (filters.sortiment && !(sortiment||"").toLowerCase().includes(filters.sortiment.toLowerCase())) return
                    if (filters.phase && !(phase||"").toLowerCase().includes(filters.phase.toLowerCase())) return
                    if (filters.zone && !(location.mainzone||"Kuivati").toLowerCase().includes(filters.zone.toLowerCase())) return
                    if (filters.location && !((location.subzone||location.chamber)||"").toLowerCase().includes(filters.location.toLowerCase())) return
                    if (filters.fsc === "1" && !fsc) return
                    if (filters.fsc === "0" && fsc) return
                    
                    let chopStartDate = moment(chopStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                    if (filters.chopfrom > chopStartDate || filters.chopto < chopStartDate) return
                    let dryStartDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                    if (filters.drystartfrom > dryStartDate || filters.drystartto < dryStartDate) return
                    let dryEndDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                    if (filters.drystartfrom > dryEndDate || filters.drystartto < dryEndDate) return

                    if (this.state.showDates) ws.addRow({restNr, koorem: this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem, chopper, chopStart, sortiment, article, phase, dryStart, dryEnd, zone: location.mainzone || "Kuivati", location: location.subzone || location.chamber, amount, fsc: fsc ? "Jah" : "Ei"})
                    else ws.addRow({restNr, koorem: this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem, chopper, sortiment, article, phase, zone: location.mainzone || "Kuivati", location: location.subzone || location.chamber, amount, fsc: fsc ? "Jah" : "Ei"})
                })
            } else if (waresView === "alus") {
                const ws = wb.addWorksheet("Alused")

                ws.columns = [
                    {header: "Aluse nr", key: "restNr", width: 10},
                    {header: "Artikkel", key: "article", width: 12},
                    {header: "Tootmisjärk", key: "phase", width: 12},
                    {header: "Tsoon", key: "zone", width: 10},
                    {header: "Asukoht", key: "location", width: 10},
                    {header: "Tükiarv", key: "amount", width: 10},
                    {header: "Tellimus", key: "order", width: 14},
                    {header: "FSC", key: "fsc", width: 10},
                    {header: "Loomise aeg", key: "createdAt", width: 16}
                ]

                ws.getRow(1).font = {bold: true}
                ws.views = [{state: 'frozen', xSplit: 1, ySplit: 1}]

                Object.keys({...this.state.restid, empty: ""}).forEach((key, i) => {
                    if (Object.keys(this.state.restid).length === i) {
                        ws.eachRow({includeEmpty: true}, (row, rowNumber) => {
                            ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                        })
                    
                        wb.xlsx.writeBuffer().then(buffer => {
                            FileSaver.saveAs(new Blob([buffer]), "Lõhutud restid.xlsx")
                        }).catch(err => console.log(err))

                        return
                    }

                    let {finished, phase, restNr, article, location, amount, order, fsc, createdAt} = this.state.restid[key]
                    if (finished) return
                    let filters = this.state.filters
                    if (filters.restnr && !(restNr||"").toLowerCase().includes(filters.restnr.toLowerCase())) return
                    if (filters.phase && !(phase||"").toLowerCase().includes(filters.phase.toLowerCase())) return
                    if (filters.zone && !(location.mainzone||"Kuivati").toLowerCase().includes(filters.zone.toLowerCase())) return
                    if (filters.location && !((location.subzone||location.chamber)||"").toLowerCase().includes(filters.location.toLowerCase())) return
                    if (filters.order && !(order||"").toLowerCase().includes(filters.order.toLowerCase())) return
                    if (filters.fsc === "1" && !fsc) return
                    if (filters.fsc === "0" && fsc) return

                    let createdAtF = moment(createdAt).format("YYYY-MM-DD")
                    if (filters.createdfrom > createdAtF || filters.createdto < createdAtF) return

                    ws.addRow({restNr, article, phase, zone: location.mainzone || "Kuivati", location: location.subzone || location.chamber, amount, order, fsc: fsc ? "Jah" : "Ei", createdAt: moment(createdAt).format("DD/MM/YYYY HH:mm")})
                })
            } else if (waresView === "finished") {
                const ws = wb.addWorksheet("Lõpetatud restid")

                if (this.state.showDates) ws.columns = [
                    {header: "Resti nr", key: "restNr", width: 10},
                    {header: "Koorma nr", key: "koorem", width: 10},
                    {header: "Lõhkuja", key: "chopper", width: 12},
                    {header: "Lõhkumine", key: "chopStart", width: 12},
                    {header: "Sortiment", key: "sortiment", width: 12},
                    {header: "Artikkel", key: "article", width: 12},
                    {header: "Tootmisjärk", key: "phase", width: 12},
                    {header: "Kuivatisse", key: "dryStart", width: 13},
                    {header: "Kuivatist välja", key: "dryEnd", width: 13},
                    {header: "Loomine", key: "createdAt", width: 16},
                    {header: "Lõpetamine", key: "finishDate", width: 16},
                    {header: "Tükiarv", key: "amount", width: 10},
                    {header: "Tellimus", key: "order", width: 14}
                ]
                else ws.columns = [
                    {header: "Resti nr", key: "restNr", width: 10},
                    {header: "Koorma nr", key: "koorem", width: 10},
                    {header: "Lõhkuja", key: "chopper", width: 12},
                    {header: "Sortiment", key: "sortiment", width: 12},
                    {header: "Artikkel", key: "article", width: 12},
                    {header: "Tootmisjärk", key: "phase", width: 12},
                    {header: "Tükiarv", key: "amount", width: 10},
                    {header: "Tellimus", key: "driver", width: 14}
                ]

                ws.getRow(1).font = {bold: true}
                ws.views = [{state: 'frozen', xSplit: 1, ySplit: 1}]

                Object.keys({...this.state.restid, empty: ""}).forEach((key, i) => {
                    if (Object.keys(this.state.restid).length === i) {
                        Object.keys({...this.state.alused, empty: ""}).forEach((key, j) => {
                            if (Object.keys(this.state.alused).length === j) {
                                ws.eachRow({includeEmpty: true}, (row, rowNumber) => {
                                    ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                                })
                            
                                wb.xlsx.writeBuffer().then(buffer => {
                                    FileSaver.saveAs(new Blob([buffer]), "Lõpetatud restid.xlsx")
                                }).catch(err => console.log(err))
        
                                return
                            }    
                            let {finished, phase, restNr, article, driver, location, amount, order, createdAt, fsc, finishDate} = this.state.alused[key]
                            if (!finished) return
                            let filters = this.state.filters
                            if (filters.restnr && !(restNr||"").toLowerCase().includes(filters.restnr.toLowerCase())) return
                            if (filters.phase && !(phase||"").toLowerCase().includes(filters.phase.toLowerCase())) return
                            if (filters.zone && !(location.mainzone||"Kuivati").toLowerCase().includes(filters.zone.toLowerCase())) return
                            if (filters.location && !((location.subzone||location.chamber)||"").toLowerCase().includes(filters.location.toLowerCase())) return
                            if (filters.order && !(order||"").toLowerCase().includes(filters.order.toLowerCase())) return
                            if (filters.fsc === "1" && !fsc) return
                            if (filters.fsc === "0" && fsc) return

                            let createdAtF = moment(createdAt).format("YYYY-MM-DD")
                            if (filters.createdfrom > createdAtF || filters.createdto < createdAtF) return
                            if (filters.finishedfrom > finishDate || filters.finishedto < finishDate) return

                            if (this.state.showDates) ws.addRow({restNr, koorem: "", chopper: "", chopStart: "", sortiment: "", article, phase, dryStart: "", dryEnd: "", createdAt: moment(createdAt).format("DD/MM/YYYY HH:mm"), finishDate: moment(finishDate).format("DD/MM/YYYY"), amount, fsc: fsc ? "Jah" : "Ei", order})
                            else ws.addRow({restNr, koorem: "", chopper: "", sortiment: "", article, phase, amount, fsc: fsc ? "Jah" : "Ei", order})
                        })
                    } else {
                        let {chopper, finished, parent, phase, restNr, sortiment, article, driver, location, chopStart, dryStart, dryEnd, createdAt, finishDate, amount, order, fsc} = this.state.restid[key]
                        if (!finished) return
                        let filters = this.state.filters
                        if (filters.restnr && !(restNr||"").toLowerCase().includes(filters.restnr.toLowerCase())) return
                        if (filters.koorem && !((this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem)||"").toLowerCase().includes(filters.koorem.toLowerCase())) return
                        if (filters.chopper && !(chopper||"").toLowerCase().includes(filters.chopper.toLowerCase())) return
                        if (filters.sortiment && !(sortiment||"").toLowerCase().includes(filters.sortiment.toLowerCase())) return
                        if (filters.phase && !(phase||"").toLowerCase().includes(filters.phase.toLowerCase())) return
                        // if (filters.zone && !(location.mainzone||"Kuivati").toLowerCase().includes(filters.zone.toLowerCase())) return
                        // if (filters.location && !((location.subzone||location.chamber)||"").toLowerCase().includes(filters.location.toLowerCase())) return
                        if (filters.fsc === "1" && !fsc) return
                        if (filters.fsc === "0" && fsc) return

                        let chopStartDate = moment(chopStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                        if (filters.chopfrom > chopStartDate || filters.chopto < chopStartDate) return
                        let dryStartDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                        if (filters.drystartfrom > dryStartDate || filters.drystartto < dryStartDate) return
                        let dryEndDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                        if (filters.drystartfrom > dryEndDate || filters.drystartto < dryEndDate) return
                        if (filters.finishedfrom > finishDate || filters.finishedto < finishDate) return
                        let createdAtF = moment(createdAt).format("YYYY-MM-DD")
                        if (filters.createdfrom > createdAtF || filters.createdto < createdAtF) return

                        if (this.state.showDates) ws.addRow({restNr, koorem: this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem, chopper, chopStart, sortiment, article, phase, dryStart, dryEnd, createdAt: moment(createdAt).format("DD/MM/YYYY HH:mm"), finishDate: moment(finishDate).format("DD/MM/YYYY"), amount, fsc: fsc ? "Jah" : "Ei", order})
                        else ws.addRow({restNr, koorem: this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem, chopper, sortiment, article, phase, amount, fsc: fsc ? "Jah" : "Ei", order})
                    }    
                })
            } else if (waresView === "stock") {
                const ws = wb.addWorksheet(`Inventuur ${moment().format("DD-MM-YYYY HH.mm")}`)

                ws.columns = [
                    {header: "Artikkel", key: "article", width: 15},
                    {header: "Nimi", key: "name", width: 25},
                    {header: "Ühik", key: "unit", width: 8},
                    {header: "Laoseis", key: "count", width: 10},
                    {header: "Laoseis FSC", key: "fsc", width: 10}
                ]

                ws.getRow(1).font = {bold: true}
                ws.views = [{state: 'frozen', xSplit: 1, ySplit: 1}]

                
                Object.keys({...this.state.stock, empty: ""}).map((key, i) => {
                    if (Object.keys(this.state.stock).length === i) {
                        ws.eachRow({includeEmpty: true}, (row, rowNumber) => {
                            ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                        })
                        
                        wb.xlsx.writeBuffer().then(buffer => {
                            FileSaver.saveAs(new Blob([buffer]), `Inventuur ${moment().format("DD-MM-YYYY HH.mm")}.xlsx`)
                        }).catch(err => console.log(err))
                        
                        return
                    }
                    
                    let {name, type, unit, count, fsc} = this.state.stock[key]
                    if (this.state.filters.article && !key.toLowerCase().includes(this.state.filters.article.toLowerCase())) return
                    if (this.state.filters.name && !name.toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                    if (this.state.filters.type && !type.toLowerCase().includes(this.state.filters.type.toLowerCase())) return
                    count = Math.round((count + Number.EPSILON) * 100) / 100
                    fsc = Math.round((fsc + Number.EPSILON) * 100) / 100
                    ws.addRow({article: key, name, unit, count, fsc})
                })
            } else if (waresView === "warehouse") {
                const ws = wb.addWorksheet("Põhiladu")

                let {from, to} = this.state.filters
                let toggledDates = this.state.toggledDates
                let dates = []

                Array((from && to) ? moment(to).diff(moment(from), 'days')+3 : moment().daysInMonth()+1).fill(0,0,(from && to) ? moment(to).diff(moment(from), 'days')+3 : moment().daysInMonth()+2).forEach((key, i) => {
                    if (i===0) return
                    if (Array((from && to) ? moment(to).diff(moment(from), 'days')+3 : moment().daysInMonth()+1).fill(0,0,(from && to) ? moment(to).diff(moment(from), 'days')+3 : moment().daysInMonth()+2).length === i+1) {
                        ws.getRow(1).font = {bold: true}
                        ws.views = [{state: 'frozen', xSplit: 1, ySplit: 2}]
                        let columns = []
                        let lastNumber = 1

                        dates.forEach((date, i) => {
                            if (date.isOpen) {
                                columns.push({key: date.date}, {header: date.date, key: date.date, width: 10}, {key: date.date})
                                ws.mergeCells(1, lastNumber+1, 1, lastNumber+3)
                                ws.getCell(2, lastNumber+1).value = "Seis"
                                ws.getCell(2, lastNumber+2).value = "Sisse"
                                ws.getCell(2, lastNumber+3).value = "Välja"
                                lastNumber += 3
                            } else {
                                columns.push({header: date.date, key: date.date, width: 10})
                                ws.getCell(2, lastNumber+1).value = "Seis"
                                lastNumber += 1
                            }

                            let isOpen = false

                            if (i+1 === dates.length) {
                                let stock = this.state.stock
                                Object.keys(stock).forEach((item, i) => {
                                    ws.getCell(i+3, 1).value = stock[item]?.name || ""
                                    columns.forEach((column, j) => {
                                        let currentDate = moment(column.key, "DD/MM/YY").format("YYYY-MM-DD")
                                        
                                        if (this.state.warehouseUnits) {
                                            if (!isOpen) {
                                                if (Object.keys(column).length === 1) {
                                                    ws.getCell(i+3, j+2).value = typeof stock[item]?.log?.[currentDate]?.restid?.count === "number" ? stock[item].log[currentDate].restid.count : ""
                                                    isOpen = true
                                                } else {
                                                    ws.getCell(i+3, j+2).value = typeof stock[item]?.log?.[currentDate]?.restid?.count === "number" ? stock[item].log[currentDate].restid.count : ""
                                                }
                                            } else {
                                                if (Object.keys(column).length === 1) {
                                                    ws.getCell(i+3, j+2).value = typeof stock[item]?.log?.[currentDate]?.restid?.out === "number" ? stock[item].log[currentDate].restid.out : ""
                                                    isOpen = false
                                                } else {
                                                    ws.getCell(i+3, j+2).value = typeof stock[item]?.log?.[currentDate]?.restid?.in === "number" ? stock[item].log[currentDate].restid.in : ""
                                                }
                                            }
                                        } else {
                                            if (!isOpen) {
                                                if (Object.keys(column).length === 1) {
                                                    ws.getCell(i+3, j+2).value = typeof stock[item]?.log?.[currentDate]?.units?.count === "number" ? stock[item].log[currentDate].units.count : ""
                                                    isOpen = true
                                                } else {
                                                    ws.getCell(i+3, j+2).value = typeof stock[item]?.log?.[currentDate]?.units?.count === "number" ? stock[item].log[currentDate].units.count : ""
                                                }
                                            } else {
                                                if (Object.keys(column).length === 1) {
                                                    ws.getCell(i+3, j+2).value = typeof stock[item]?.log?.[currentDate]?.units?.out === "number" ? stock[item].log[currentDate].units.out : ""
                                                    isOpen = false
                                                } else {
                                                    ws.getCell(i+3, j+2).value = typeof stock[item]?.log?.[currentDate]?.units?.in === "number" ? stock[item].log[currentDate].units.in : ""
                                                }
                                            }
                                        }
                                        
                                        if (i+1 === Object.keys(stock).length && j+1 === columns.length) {
                                            ws.columns = [{header: "", key: "", width: 40}, ...columns]
                                            ws.eachRow({includeEmpty: false}, (row, rowNumber) => {
                                                ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                                            })

                                            wb.xlsx.writeBuffer().then(buffer => {
                                                FileSaver.saveAs(new Blob([buffer]), "Põhiladu.xlsx")
                                            }).catch(err => console.log(err))
                                        }
                                    })
                                })

                            }
                        })
                    }
                    let date = from && to ? moment(from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                    if ((from && to) && moment(date, "DD/MM/YY") > moment(to)) return
                    if (toggledDates.includes(date)) {
                        dates.push({date, isOpen: true})
                    } else {
                        dates.push({date, isOpen: false})
                    }
                })
            } else if (waresView === "haljala" || waresView === "tootmine" || waresView === "myyk") {
                let title = waresView === "haljala" ? "Haljala" : waresView === "tootmine" ? "Tootmine" : "Müük"
                const ws = wb.addWorksheet(title)

                let {from, to} = this.state.filters
                let n = 0

                Array((from && to) ? moment(to).diff(moment(from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(from && to) ? moment(to).diff(moment(from), 'days')+2 : moment().daysInMonth()+1).forEach((key, i) => {
                    if (i===0) return <h1 key={i} className="empty"></h1>
                    let date = from && to ? moment(from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                    if ((from && to) && moment(date, "DD/MM/YY") > moment(to)) return

                    if ((from && to) ? i+1 === moment(to).diff(moment(from), 'days')+2 : i+1 === moment().daysInMonth()+1) {
                        ws.getCell(1, i+1).value = date
                        ws.getCell(1, i+2).value = "Kokku"
                    } else ws.getCell(1, i+1).value = date
                })

                Object.keys(this.state.stock).forEach((key, j) => {
                    let {name, type, count, unit, log, myyk, haljala} = this.state.stock[key]
                    if (waresView === "haljala" && !haljala) return
                    if (waresView === "haljala" && !Object.keys(haljala).length) return
                    if (waresView === "myyk" && !myyk) return
                    if (waresView === "myyk" && !Object.keys(myyk).length) return
                    if (this.state.filters.article) {
                        if (!key.toLowerCase().includes(this.state.filters.article.toLowerCase())) return
                    }
                    if (this.state.filters.name) {
                        if (!name.toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                    }
                    if (this.state.filters.type) {
                        if (type !== this.state.filters.type) return
                    }
                    
                    let inSum = 0
                    
                    Array((from && to) ? moment(to).diff(moment(from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(from && to) ? moment(to).diff(moment(from), 'days')+2 : moment().daysInMonth()+1).forEach((key1, i) => {
                        if (i===0) return
                        let date = from && to ? moment(from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                        if ((from && to) && moment(date, "DD/MM/YY") > moment(to)) return
                        let inValue
                        if (waresView === "myyk") {
                            if (this.state.filters.fsc === "0") {
                                inValue = myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0
                            } else if (this.state.filters.fsc === "1") {
                                inValue = myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0
                            } else {
                                inValue = (myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0) + (myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0)
                            }
                        } else if (waresView === "haljala") {
                            if (this.state.filters.fsc === "0") {
                                inValue = haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0
                            } else if (this.state.filters.fsc === "1") {
                                inValue = haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0
                            } else {
                                inValue = (haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0) + (haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0)
                            }
                        }
                        inSum += inValue ? Number(inValue) : 0
                        
                        if ((from && to) ? i+1 === moment(to).diff(moment(from), 'days')+2 : i+1 === moment().daysInMonth()+1) {
                            ws.getCell(n+2, 1).value = name
                            ws.getCell(n+2, i+1).value = inValue || ""
                            ws.getCell(n+2, i+2).value = inSum
                        } else {
                            ws.getCell(n+2, i+1).value = inValue || ""
                        }
                    })
                    n += 1
                })

                ws.getRow(1).font = {bold: true}
                ws.views = [{state: 'frozen', xSplit: 1, ySplit: 1}]
                ws.columns = [{header: title, key: title, width: 40}]
                
                ws.eachRow({includeEmpty: false}, (row, rowNumber) => {
                    ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                })

                wb.xlsx.writeBuffer().then(buffer => {
                    FileSaver.saveAs(new Blob([buffer]), `${title}.xlsx`)
                }).catch(err => console.log(err))
            }
        } else if (view === "workers") {
            if (this.state.modal?.view === "report") {
                let worker = this.state.workers[this.state.modal.id]
                const ws = wb.addWorksheet(`${worker.firstName} ${worker.lastName}`)

                ws.columns = [
                    {header: "Päev", key: "day", width: 15},
                    {header: "Tööl viibitud aeg", key: "duration", width: 20},
                    {header: `Sisse-välja registreerimise ajad - ${worker.firstName} ${worker.lastName}`, key: "times", width: 15},
                    {width: 15},
                    {width: 15},
                    {width: 15},
                    {width: 15}
                    // {header: `${worker.firstName} ${worker.lastName}`, key: "name", width: 15}
                ]

                ws.mergeCells("C1:G1")
                ws.getRow(1).font = {bold: true}
                ws.views = [{state: 'frozen', xSplit: 2, ySplit: 1}]

                Array(((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).diff(moment(this.state.filters.from).subtract(1, 'day'), 'days')+1 : 63).fill(0,0,((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).diff(moment(this.state.filters.from).subtract(1, 'day'), 'days')+1 : 63).forEach((key, j) => {
                    if ((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) {
                        if (j === moment(this.state.filters.to).diff(moment(this.state.filters.from).subtract(1, 'day'), 'days')) {
                            ws.eachRow({includeEmpty: false}, (row, rowNumber) => {
                                ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                            })
            
                            wb.xlsx.writeBuffer().then(buffer => {
                                FileSaver.saveAs(new Blob([buffer]), `${worker.firstName} ${worker.lastName}.xlsx`)
                            }).catch(err => console.log(err))
                            
                            return
                        }
                    } else {
                        if (j === 62) {
                            ws.eachRow({includeEmpty: false}, (row, rowNumber) => {
                                ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                            })
            
                            wb.xlsx.writeBuffer().then(buffer => {
                                FileSaver.saveAs(new Blob([buffer]), `${worker.firstName} ${worker.lastName}.xlsx`)
                            }).catch(err => console.log(err))

                            return
                        }
                    }

                    if ((!this.state.filters.to || !this.state.filters.from) || this.state.filters.to < this.state.filters.from) {
                        if (moment().subtract(j, 'days').startOf('month').format("YYYY-MM-DD") !== moment().startOf('month').format("YYYY-MM-DD") && moment().subtract(j, 'days').startOf('month').format("YYYY-MM-DD") !== moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD")) return
                    }
                    
                    let day = ((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).subtract(j, 'days') : moment().subtract(j, 'days') 
                    let times = worker?.logs?.[day.format("YYYY-MM-DD")]?.times
                    let seconds = 0
                    let minutes = 0
                    let hours = 0

                    let limited = []
                    let timesArr = []

                    if (times?.[0]?.split(":")[0] === "end") {
                        times.forEach((key, i) => {
                            if (i === 0) {
                                seconds += moment(`${day.format("YYYY-MM-DD")} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                                limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                            }
                            if (times[i*2+1]) {
                                let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                if (times[i*2+2]) {
                                    let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                    seconds += b.diff(a, 'seconds')
                                    timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                    timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                                } else {
                                    let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                    seconds += b.diff(a, 'seconds')
                                    limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                }
                            }
                            if (i+1===times.length) {
                                minutes = Math.floor(seconds / 60)
                                seconds = seconds%60
                                hours = Math.floor(minutes/60)
                                minutes = minutes%60
                            }
                        })
                    } else if (times?.[0]?.split(":")[0] === "start") {
                        times.forEach((key, i) => {
                            if (i === 0) {
                                limited.push(0)
                            }
                            if (times[i*2]) {
                                let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                if (times[i*2+1]) {
                                    let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                    seconds += b.diff(a, 'seconds')
                                    timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                    timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                } else {
                                    let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                    seconds += b.diff(a, 'seconds')
                                    limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                }
                            }
                            if (i+1===times.length) {
                                minutes = Math.floor(seconds / 60)
                                seconds = seconds%60
                                hours = Math.floor(minutes/60)
                                minutes = minutes%60
                            }
                        })
                    }

                    ws.addRow({day: `${day.format("DD")}. ${this.months.est[day.format('M')-1]}`, duration: `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`})
                    
                    if (limited[0]) ws.getCell(j+2, 3).value = `--:-- - ${limited[0]}`
                    timesArr.forEach((key, i) => {
                        if (!timesArr[i*2]) return
                        if (limited[0]) ws.getCell(j+2, i+4).value = `${timesArr[i*2]} - ${timesArr[i*2+1]}`
                        else ws.getCell(j+2, i+3).value = `${timesArr[i*2]} - ${timesArr[i*2+1]}`
                    })
                    if (limited[1]) ws.getCell(j+2, timesArr.length/2+3).value = `${limited[1]} - --:--`
                })
            }
        }
    }

    calcSummary = id => {
        let seconds = 0
        let limited = []
        let timesArr = []
        let seconds2 = 0
        let limited2 = []
        let timesArr2 = []
        Object.keys(this.state.workers[id]?.logs || {}).forEach(day => {
            if (day < moment().startOf('month').format("YYYY-MM-DD") || day > moment().endOf('month').format("YYYY-MM-DD")) return
            let times = this.state.workers[id].logs[day].times
            if (times?.[0]?.split(":")[0] === "end") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        seconds += moment(`${day} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                        limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                    }
                    if (times[i*2+1]) {
                        let a = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+2]) {
                            let b = moment(`${day} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds += b.diff(a, 'seconds')
                            timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                        } else {
                            if (day !== moment().format("YYYY-MM-DD")) {
                                let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                seconds += b.diff(a, 'seconds')
                                limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            }
                        }
                    }
                    if (i+1===times.length) {
                        let minutes = Math.floor(seconds / 60)
                        let secondss = seconds%60
                        let hours = Math.floor(minutes/60)
                        minutes = minutes%60

                        this.setState({summary: `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${secondss < 10 ? "0" + secondss : secondss}`})
                    }
                })
            } else if (times?.[0]?.split(":")[0] === "start") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        limited.push(0)
                    }
                    if (times[i*2]) {
                        let a = moment(`${day} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+1]) {
                            let b = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds += b.diff(a, 'seconds')
                            timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                            timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                        } else {
                            if (day !== moment().format("YYYY-MM-DD")) {
                                let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                seconds += b.diff(a, 'seconds')
                                limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                            }
                        }
                    }
                    if (i+1===times.length) {
                        let minutes = Math.floor(seconds / 60)
                        let secondss = seconds%60
                        let hours = Math.floor(minutes/60)
                        minutes = minutes%60

                        this.setState({summary: `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${secondss < 10 ? "0" + secondss : secondss}`})
                    }
                })
            }
        })

        Object.keys(this.state.workers[id]?.logs || {}).forEach(day => {
            if (day < moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD") || day > moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD")) return
            let times = this.state.workers[id].logs[day].times
            if (times?.[0]?.split(":")[0] === "end") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        seconds2 += moment(`${day} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                        limited2.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                    }
                    if (times[i*2+1]) {
                        let a = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+2]) {
                            let b = moment(`${day} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds2 += b.diff(a, 'seconds')
                            timesArr2.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            timesArr2.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                        } else {
                            let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                            seconds2 += b.diff(a, 'seconds')
                            limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                        }
                    }
                    if (i+1===times.length) {
                        let minutes2 = Math.floor(seconds2 / 60)
                        let seconds2s = seconds2%60
                        let hours2 = Math.floor(minutes2/60)
                        minutes2 = minutes2%60
                        
                        this.setState({summary2: `${hours2 < 10 ? "0" + hours2 : hours2}:${minutes2 < 10 ? "0" + minutes2 : minutes2}:${seconds2s < 10 ? "0" + seconds2s : seconds2s}`})
                    }
                })
            } else if (times?.[0]?.split(":")[0] === "start") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        limited2.push(0)
                    }
                    if (times[i*2]) {
                        let a = moment(`${day} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+1]) {
                            let b = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds2 += b.diff(a, 'seconds')
                            timesArr2.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                            timesArr2.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                        } else {
                            let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                            seconds2 += b.diff(a, 'seconds')
                            limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                        }
                    }
                    if (i+1===times.length) {
                        let minutes2 = Math.floor(seconds2 / 60)
                        let seconds2s = seconds2%60
                        let hours2 = Math.floor(minutes2/60)
                        minutes2 = minutes2%60
                        
                        this.setState({summary2: `${hours2 < 10 ? "0" + hours2 : hours2}:${minutes2 < 10 ? "0" + minutes2 : minutes2}:${seconds2s < 10 ? "0" + seconds2s : seconds2s}`})
                    }
                })
            }
        })
    }

    openReport = id => {
        this.setState({modal: {view: 'report', id}, oldSE: this.state.latestSE})
        this.fetchEmployees(moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
        this.calcSummary(id)
    }

    openTime = e => {
        e.preventDefault()
        this.setState({modal: {...this.state.modal, date: e.target.getAttribute("date"), data: {from: e.target.getAttribute("from"), to: e.target.getAttribute("to")}}})
    }

    closeModal = e => {
        e.preventDefault()

        if (e.target.getAttribute("modal") === "datemodal") {
            this.setState({modal: {...this.state.modal, view: 'report', date: '', data: {}}, filters: {active: false}})
        } else {
            this.fetchEmployees(this.state.oldSE.start, this.state.oldSE.end)
            this.setState({modal: {view: '', id: '', data: {}}, filters: {active: false}, summary: '', summary2: '', latestSE: this.state.oldSE, oldSE: {}})
        }
    }

    toggleHistoryModal = e => {
        e.preventDefault()
        if (this.state.historyModal) this.setState({historyModal: ""})
        else this.setState({historyModal: e.target.id})
    }

    // route = e => {
    //     e.preventDefault()

    //     // document.removeEventListener('mousedown', this.handleClickOutside)
    //     if (e.target.id === "workers") {
    //         this.socket.emit("yardstorageGet")
    //         this.setState({waresView: "yardstorage", view: e.target.id, filters: {active: false}})
    //     } else {
    //         this.setState({view: e.target.id})
    //     }
    // }

    waresRoute = e => {
        e.preventDefault()
        
        // if (e.target.id === "warehouse") document.addEventListener('mousedown', this.handleClickOutside)
        // else document.removeEventListener('mousedown', this.handleClickOutside) 

        let {start, end} = this.state.latestSE
        if (start !== moment().startOf("month").format("YYYY-MM-DD") || end !== moment().endOf("month").format("YYYY-MM-DD")) this.fetchStock(moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD"))

        this.setState({waresView: e.target.id, filters: {active: false}, page: 1, toggledDates: [], toggleMoreRoutes: false})
    }

    archiveRoute = e => {
        e.preventDefault()

        this.setState({archiveToggle: e.target.getAttribute("toggle") === "on" ? true : false})
    }

    toggleMoreRoutes = e => {
        e.preventDefault()
        this.setState({toggleMoreRoutes: !this.state.toggleMoreRoutes})
    }

    toggleFilters = e => {
        e.preventDefault()
        this.setState({filters: {...this.state.filters, active: !this.state.filters.active}})
    }

    toggleEnabled = e => {
        this.setState({filters: {...this.state.filters, [e.target.id]: !this.state.filters[e.target.id]}})
    }

    toggleWarehouseUnits = e => {
        this.setState({warehouseUnits: !this.state.warehouseUnits})
    }

    toggleShowDates = e => {
        this.setState({showDates: !this.state.showDates})
    }

    onChange = e => {
        e.preventDefault()
        
        let id = e.target.id
        let date = e.target.value

        if (id === "from") {
            if (this.state.filters.to) {
                if (this.state.view === "workers") this.fetchEmployees(date, this.state.filters.to)
                if (this.state.waresView === "warehouse") this.fetchStock(date, this.state.filters.to)
            }
        } else if (id === "to") {
            if (this.state.filters.from) {
                if (this.state.view === "workers") this.fetchEmployees(this.state.filters.from, date)
                if (this.state.waresView === "warehouse") this.fetchStock(this.state.filters.from, date)
            }
        }

        this.setState({filters: {...this.state.filters, [id]: date}})
    }

    onChangeStock = e => {
        e.preventDefault()
        this.setState({editStock: {...this.state.editStock, [e.target.id]: {...this.state.editStock[e.target.id], [e.target.className]: e.target.value}}})
    }

    editStock = e => {
        e.preventDefault()

        this.socket.emit("editcount", {article: e.target.id, count: this.state.editStock[e.target.id][e.target.className], type: e.target.className})
        this.setState({editStock: {...this.state.editStock, [e.target.id]: {}}})
    }

    clear = e => {
        e.preventDefault()
        this.setState({filters: {active: true}})
    }

    paginate = e => {
        e.preventDefault()
        this.setState({page: Number(e.target.getAttribute("page"))})
    }

    // resetField = e => { SELLEGA SAI HANDLEDA EDITIMIST
    //     e.preventDefault()
    //     this.setState({toggledValues: {}})
    // }

    // onClickValue = e => { SELLEGA SAI HANDLEDA EDITIMIST
    //     e.preventDefault()

    //     let toggledValues = this.state.toggledValues
    //     let type = e.target.getAttribute("valuetype")
    //     let item = e.target.getAttribute("item")
    //     let date = e.target.getAttribute("date")

        
        
    //     this.setState({toggledValues: {type, item, date: moment(date, "DD/MM/YY").format("YYYY-MM-DD")}})
    // }

    onChangeValue = e => {
        e.preventDefault()
        this.setState({toggledValues: {...this.state.toggledValues, value: e.target.value}})
    }

    // refresh = e => {
    //     e.preventDefault()

    //     fetch(`${process.env.REACT_APP_SERVER_URL}/employee/get`, {
    //         credentials: 'include'
    //     }).then(res => {
    //         return res.json()
    //     }).then(data => {
    //         data.forEach((worker, i) => {
    //             let seconds = 0
    //             let currentDate = moment().format("YYYY-MM-DD")
    //             let datesArr = Object.keys(worker.logs)

    //             if (datesArr[datesArr.length-1] !== currentDate || worker.logs[datesArr[datesArr.length-1]]?.times[worker.logs[datesArr[datesArr.length-1]]?.times-1]?.split(":")[0] === "end") {
    //                 this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
    //                 return
    //             }
                
    //             if (datesArr[datesArr.length-1] === currentDate) {
    //                 if (worker.logs[currentDate].times[0]?.split(":")[0] === "end") {
    //                     let a = this.parseTimeToArr(worker.logs[currentDate].times[0])
    //                     let b = this.parseTimeToArr(worker.logs[datesArr[datesArr.length-2]].times[worker.logs[datesArr[datesArr.length-2]].times.length-1])
    //                     seconds += moment(`${currentDate} ${a?.[1]}:${a?.[2]}:${a?.[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${datesArr[datesArr.length-2]} ${b?.[1]}:${b?.[2]}:${b?.[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                        
    //                     worker.logs[currentDate].times.forEach((key, i) => {
    //                         if (i === 0) {
    //                             this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
    //                             return
    //                         }
    //                         if (!worker.logs[currentDate].times[i*2-1]) {
    //                             if (i+1 === worker.logs[currentDate].times.length) {
    //                                 this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
    //                             }
    //                             return
    //                         }

    //                         if (worker.logs[currentDate].times[i*2]) {
    //                             a = this.parseTimeToArr(worker.logs[currentDate].times[i*2])
    //                             b = this.parseTimeToArr(worker.logs[currentDate].times[i*2-1])

    //                             seconds += moment(`${currentDate} ${a?.[1]}:${a?.[2]}:${a?.[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${currentDate} ${b?.[1]}:${b?.[2]}:${b?.[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
    //                         }

    //                         if (i+1 === worker.logs[currentDate].times.length) {
    //                             this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
    //                         }
    //                     })
    //                 } else {
    //                     worker.logs[currentDate].times.forEach((key, i) => {
    //                         let a
    //                         let b
    //                         if (!worker.logs[currentDate].times[i*2]) {
    //                             if (i+1 === worker.logs[currentDate].times.length) {
    //                                 this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
    //                             }
    //                             return
    //                         }
                            
    //                         if (worker.logs[currentDate].times[i*2+1]) {
    //                             a = this.parseTimeToArr(worker.logs[currentDate].times[i*2+1])
    //                             b = this.parseTimeToArr(worker.logs[currentDate].times[i*2])

    //                             seconds += moment(`${currentDate} ${a?.[1]}:${a?.[2]}:${a?.[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${currentDate} ${b?.[1]}:${b?.[2]}:${b?.[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
    //                         }

    //                         if (i+1 === worker.logs[currentDate].times.length) {
    //                             this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
    //                         }
    //                     })
    //                 }
    //             }
    //         })
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }

    dateToggle = e => {
        e.preventDefault()
        let toggledDates = this.state.toggledDates
        if (toggledDates.includes(e.target.id)) {
            toggledDates.splice(toggledDates.indexOf(e.target.id), 1)
            this.setState({toggledDates})
        } else {
            this.setState({toggledDates: [...toggledDates, e.target.id]})
        }
    }

    toggleAll = e => {
        e.preventDefault()

        if (e.target.id !== "open") {
            this.setState({toggledDates: []})
            return
        }

        let {from, to} = this.state.filters
        let dates = []

        Array((from && to) ? moment(to).diff(moment(from), 'days')+3 : moment().daysInMonth()+1).fill(0,0,(from && to) ? moment(to).diff(moment(from), 'days')+3 : moment().daysInMonth()+2).forEach((key, i) => {
            if (i===0) return
            if (Array((from && to) ? moment(to).diff(moment(from), 'days')+3 : moment().daysInMonth()+1).fill(0,0,(from && to) ? moment(to).diff(moment(from), 'days')+3 : moment().daysInMonth()+2).length === i+1) this.setState({toggledDates: dates})
            let date = from && to ? moment(from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
            if ((from && to) && moment(date, "DD/MM/YY") > moment(to)) return
            dates.push(date)
        })
    }

    saveTime = e => {
        e.preventDefault()
        
        let startDate = document.getElementById("startdate").value
        let startTime = document.getElementById("starttime").value
        let endDate = document.getElementById("enddate").value
        let endTime = document.getElementById("endtime").value

        if (!startDate && !endDate) return
        if (!startTime && !endTime) return
        if (!startDate && !endTime) return
        if (!startTime && !endDate) return
        if (!startTime && !startDate) return
        
        if (!this.state.modal.data.from && !this.state.modal.data.to) {
            this.socket.emit("savetime", {startDate, startTime, endDate, endTime, modal: this.state.modal, type: 'add'})
        } else {
            this.socket.emit("savetime", {startDate, startTime, endDate, endTime, modal: this.state.modal, type: 'save'})
        }
        this.setState({modal: {...this.state.modal, date: '', data: {}}})
    }

    deleteTime = e => {
        e.preventDefault()

        this.socket.emit("savetime", {modal: this.state.modal, type: 'delete'})
        this.setState({modal: {...this.state.modal, date: '', data: {}}})
    }

    deleteTimer = () => {
        this.deltimer = setInterval(() => {
            this.setState({deltime: this.state.deltime - 1})
            if (!this.state.deltime) clearInterval(this.deltimer)
        }, 1000)
    }

    deleteWares = e => {
        e.preventDefault()
        
        if (!this.state.deleteModal) {
            this.deleteTimer()
            const id = e.target.id
            const type = e.target.getAttribute("category")
            const obj = type === "yardstorage" ? "data" : type === "rest" ? "restid" : "alused"
            this.setState({deleteModal: {type, nr: this.state[obj][id]?.[type === "yardstorage" ? "koorem" : "restNr"], id}})
        } else {
            this.socket.emit("waresDelete", {_id: this.state.deleteModal?.id, type: this.state.deleteModal?.type})
            this.setState({deleteModal: null, deltime: 5})
        }
    }

    closeDeleteModal = e => {
        e.preventDefault()
        this.setState({deleteModal: null})
        clearInterval(this.deltimer)
        this.setState({deltime: 5})
    }

    render() {
        let settings = this.state.settings

        return (
            <main>
                <div className="overview">
                    {/* <div className="overview-nav">
                        <div className="overwiew-nav__wares" id="wares" onClick={this.route}>
                            <h1 className={this.state.view === "wares" ? "active" : ""}>Ladu</h1>
                        </div>
                        <div className="overwiew-nav__workers" id="workers" onClick={this.route}>
                            <h1 className={this.state.view === "workers" ? "active" : ""}>Töölised</h1>
                        </div>
                    </div> */}
                    <div className="overview-content">
                        {this.state.view === 'wares' &&
                        <div className="overview-wares">
                            <div className={`header${this.state.filters.active ? (this.state.waresView === "yardstorage" ? " header--filters-yardstorage" : (this.state.waresView === "rest" || this.state.waresView === "finished") ? " header--filters-chopped" : (this.state.waresView === "warehouse" || this.state.waresView === "haljala" || this.state.waresView === "tootmine" || this.state.waresView === "myyk"  || this.state.waresView === "alus") ? " header--filters-warehouse" : " header--filters-warehouse--small") : ""}`}>
                                <a className={`linkbutton${this.state.filters.active ? " linkbutton--active" : ""}`} onClick={this.toggleFilters}><i className="fas fa-angle-right"/> Filtreeri</a> 
                                <div className="header-nav">
                                    <a className={`header-nav--button${this.state.waresView === "yardstorage" ? " header-nav--button--active": ""}`} id="yardstorage" onClick={this.waresRoute}>Palgiladu</a>
                                    <a className={`header-nav--button${this.state.waresView === "rest" ? " header-nav--button--active": ""}`} id="rest" onClick={this.waresRoute}>Restid</a>
                                    <a className={`header-nav--button${this.state.waresView === "alus" ? " header-nav--button--active": ""}`} id="alus" onClick={this.waresRoute}>Alused</a>
                                    <a className={`header-nav--button${this.state.waresView === "warehouse" ? " header-nav--button--active": ""}`} id="warehouse" onClick={this.waresRoute}>Põhiladu</a>
                                    {this.state.waresView === "stock" && <a className={`header-nav--button${this.state.waresView === "stock" ? " header-nav--button--active": ""}`} id="stock" onClick={this.waresRoute}>Inventuur</a>}
                                    {this.state.waresView === "haljala" && <a className={`header-nav--button${this.state.waresView === "haljala" ? " header-nav--button--active": ""}`} id="haljala" onClick={this.waresRoute}>Haljala</a>}
                                    {/* {this.state.waresView === "tootmine" && <a className={`header-nav--button${this.state.waresView === "tootmine" ? " header-nav--button--active": ""}`} id="tootmine" onClick={this.waresRoute}>Tootmine</a>} */}
                                    {this.state.waresView === "myyk" && <a className={`header-nav--button${this.state.waresView === "myyk" ? " header-nav--button--active": ""}`} id="myyk" onClick={this.waresRoute}>Müük</a>}
                                    {this.state.waresView === "finished" && <a className={`header-nav--button${this.state.waresView === "finished" ? " header-nav--button--active": ""}`} id="finished" onClick={this.waresRoute}>Lõp. restid</a>}
                                    <div ref={this.wrapperRef} className="moreroutes">
                                        <a className="header-nav--arrow" onClick={this.toggleMoreRoutes}><i className="fas fa-caret-down"/></a>
                                        {this.state.toggleMoreRoutes && <div>
                                            <a className={`header-nav--button${this.state.waresView === "haljala" ? " header-nav--button--active": ""}`} id="haljala" onClick={this.waresRoute}>Haljala</a>
                                            {/* <a className={`header-nav--button${this.state.waresView === "tootmine" ? " header-nav--button--active": ""}`} id="tootmine" onClick={this.waresRoute}>Tootmine</a> */}
                                            <a className={`header-nav--button${this.state.waresView === "myyk" ? " header-nav--button--active": ""}`} id="myyk" onClick={this.waresRoute}>Müük</a>
                                            <a className={`header-nav--button${this.state.waresView === "stock" ? " header-nav--button--active": ""}`} id="stock" onClick={this.waresRoute}>Inventuur</a>
                                            <a className={`header-nav--button${this.state.waresView === "finished" ? " header-nav--button--active": ""}`} id="finished" onClick={this.waresRoute}>Lõp. restid</a>
                                        </div>}
                                    </div>
                                </div>
                                {this.state.filters.active && <a className="linkbutton linkbutton--clear" onClick={this.clear}>Tühjenda filtrid</a>}
                                {(this.state.filters.active && this.state.waresView === "yardstorage") &&
                                <div className="filters">
                                    <div className="filter">
                                        <h1>Koorma number</h1>
                                        <input type="text" id="koorem" value={this.state.filters.koorem || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Saatja</h1>
                                        <input type="text" id="sender" value={this.state.filters.sender || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Veoseleht</h1>
                                        <input type="text" id="veoseleht" value={this.state.filters.veoseleht || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Koorem TM</h1>
                                        <input type="text" id="tm" value={this.state.filters.tm || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Tsoon</h1>
                                        <select id="zone" value={this.state.filters.zone || ""} onChange={this.onChange}>
                                            <option value=""></option>
                                            {Object.keys(settings.wares?.zones || {}).map(key => {
                                                return <option value={key} key={key}>{key}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="filter">
                                        <h1>Materjal</h1>
                                        <input type="text" id="material" value={this.state.filters.material || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Lõhkuja</h1>
                                        <input type="text" id="chopper" value={this.state.filters.chopper || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Sortiment</h1>
                                        <input type="text" id="sortiment" value={this.state.filters.sortiment || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter datefilter-mobile">
                                        {/* {!this.state.archiveToggle && <div className="switchbuttons">
                                            <h1>Lõpetamata</h1>
                                            <input className="switchbutton" type="checkbox" id="unfinished" checked={!this.state.filters.unfinished ? true : false} onChange={this.toggleEnabled}/>
                                        </div>}
                                        {!this.state.archiveToggle && <div className="switchbuttons">
                                            <h1>Lõpetatud</h1>
                                            <input className="switchbutton" type="checkbox" id="finished" checked={this.state.filters.finished || false} onChange={this.toggleEnabled}/>
                                        </div>} */}
                                    </div>
                                    <div className="filter">
                                        <h1>Tõstukijuht</h1>
                                        <input type="text" id="driver" value={this.state.filters.driver || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>FSC</h1>
                                        <select id="fsc" value={this.state.filters.fsc || ""} onChange={this.onChange}>
                                            <option value="2">Kõik</option>
                                            <option value="1">Ainult FSC</option>
                                            <option value="0">Peida FSC</option>
                                        </select>
                                    </div>
                                    <div className="datefilter">
                                        <h1>Vastuvõtmise kuupäev</h1>
                                        <input type="date" id="receivedfrom" value={this.state.filters.receivedfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="receivedto" value={this.state.filters.receivedto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter">
                                        <h1>Lõpetamise kuupäev</h1>
                                        <input type="date" id="finishfrom" value={this.state.filters.finishfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="finishto" value={this.state.filters.finishto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter">
                                        <h1>Lõhkumise algus</h1>
                                        <input type="date" id="chopfrom" value={this.state.filters.chopfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="chopto" value={this.state.filters.chopto || ""} onChange={this.onChange} />
                                    </div>
                                </div> ||
                                (this.state.filters.active && this.state.waresView === "rest") &&
                                <div className="filters">
                                    <div className="filter">
                                        <h1>Resti number</h1>
                                        <input type="text" id="restnr" value={this.state.filters.restnr || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Koorma number</h1>
                                        <input type="text" id="koorem" value={this.state.filters.koorem || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Lõhkuja</h1>
                                        <input type="text" id="chopper" value={this.state.filters.chopper || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Sortiment</h1>
                                        <input type="text" id="sortiment" value={this.state.filters.sortiment || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Tootmisjärk</h1>
                                        <input type="text" id="phase" value={this.state.filters.phase || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Tsoon</h1>
                                        <select id="zone" value={this.state.filters.zone || ""} onChange={this.onChange}>
                                            <option value=""></option>
                                            <option value="kuivati">Kuivati</option>
                                            {Object.keys(settings?.wares?.zones || {}).map(key => {
                                                return <option value={key}>{key}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="filter">
                                        <h1>Asukoht</h1>
                                        <select id="location" value={this.state.filters.location || ""} onChange={this.onChange}>
                                            <option value=""></option>
                                            {(this.state.filters.zone === "kuivati" ? (settings?.wares?.chambers || []) : (settings?.wares?.zones?.[this.state.filters.zone] || [])).map(key => {
                                                return <option value={key}>{key}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="filter">
                                        <h1>Tellimus</h1>
                                        <input type="text" id="order" value={this.state.filters.order || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter">
                                        <h1>FSC</h1>
                                        <select id="fsc" value={this.state.filters.fsc || ""} onChange={this.onChange}>
                                            <option value="2">Kõik</option>
                                            <option value="1">Ainult FSC</option>
                                            <option value="0">Peida FSC</option>
                                        </select>
                                    </div>
                                    <div className="datefilter">
                                        <h1>Kuivatisse mineku kuupäev</h1>
                                        <input type="date" id="drystartfrom" value={this.state.filters.drystartfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="drystartto" value={this.state.filters.drystartto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter">
                                        <h1>Kuivatist tuleku kuupäev</h1>
                                        <input type="date" id="dryendfrom" value={this.state.filters.dryendfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="dryendto" value={this.state.filters.dryendto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter">
                                        <h1>Lõhkumise algus</h1>
                                        <input type="date" id="chopfrom" value={this.state.filters.chopfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="chopto" value={this.state.filters.chopto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter" />
                                </div> ||
                                (this.state.filters.active && this.state.waresView === "alus") &&
                                <div className="filters">
                                    <div className="filter">
                                        <h1>Resti number</h1>
                                        <input type="text" id="restnr" value={this.state.filters.restnr || ""} onChange={this.onChange} />
                                    </div>
                                    {/* <div className="filter">
                                        <h1>Tõstukijuht</h1>
                                        <input type="text" id="driver" value={this.state.filters.driver || ""} onChange={this.onChange} />
                                    </div> */}
                                    <div className="filter">
                                        <h1>Tsoon</h1>
                                        <select id="zone" value={this.state.filters.zone || ""} onChange={this.onChange}>
                                            <option value=""></option>
                                            <option value="kuivati">Kuivati</option>
                                            {Object.keys(settings?.wares?.zones || {}).map(key => {
                                                return <option value={key}>{key}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="filter">
                                        <h1>Asukoht</h1>
                                        <select id="location" value={this.state.filters.location || ""} onChange={this.onChange}>
                                            <option value=""></option>
                                            {(this.state.filters.zone === "kuivati" ? (settings?.wares?.chambers || []) : (settings?.wares?.zones?.[this.state.filters.zone] || [])).map(key => {
                                                return <option value={key}>{key}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="filter">
                                        <h1>Tellimus</h1>
                                        <input type="text" id="order" value={this.state.filters.order || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>FSC</h1>
                                        <select id="fsc" value={this.state.filters.fsc || ""} onChange={this.onChange}>
                                            <option value="2">Kõik</option>
                                            <option value="1">Ainult FSC</option>
                                            <option value="0">Peida FSC</option>
                                        </select>
                                    </div>
                                    <div className="datefilter">
                                        <h1>Loomise aeg</h1>
                                        <input type="date" id="createdfrom" value={this.state.filters.createdfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="createdto" value={this.state.filters.createdto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter" />
                                </div> ||
                                (this.state.filters.active && (this.state.waresView === "warehouse" || this.state.waresView === "haljala" || this.state.waresView === "tootmine" || this.state.waresView === "myyk")) &&
                                <div className="warehouse-filters">
                                    <div className="filter">
                                        <h1>Artikkel</h1>
                                        <input type="text" id="article" value={this.state.filters.article || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Nimi</h1>
                                        <input type="text" id="name" value={this.state.filters.name || ""} onChange={this.onChange} />
                                    </div>
                                    {/* <div className="filter">
                                        <h1>Vaade</h1>
                                        <select type="text" id="view" value={this.state.filters.view || "day"} onChange={this.onChange}>
                                            <option value="day">Päev</option>
                                            <option value="month">Kuu</option>
                                            <option value="year">Aasta</option>
                                        </select>
                                    </div> */}
                                    <div className="filter">
                                        <h1>Alates</h1>
                                        <input type="date" id="from" value={this.state.filters.from || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Kuni</h1>
                                        <input type="date" id="to" value={this.state.filters.to || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Tüüp</h1>
                                        <select className="article-type" id="type" value={this.state.filters.type || ""} onChange={this.onChange}>
                                            <option value=""></option>
                                            <option value="Palgid">Palgid</option>
                                            <option value="Toore, märg">Toore, märg</option>
                                            <option value="Kuivatis">Kuivatis</option>
                                            <option value="Kuiv pakkimata">Kuiv pakkimata</option>
                                            <option value="Valmis toodang">Valmis toodang</option>
                                            <option value="Küttepuu">Küttepuu</option>
                                            <option value="Pakkevahendid">Pakkevahendid</option>
                                            <option value="Sildid">Sildid</option>
                                        </select>
                                    </div>
                                    <div className="filter">
                                        <h1>FSC</h1>
                                        <select id="fsc" value={this.state.filters.fsc || ""} onChange={this.onChange}>
                                            <option value="2">Kõik</option>
                                            <option value="1">Ainult FSC</option>
                                            <option value="0">Mitte FSC</option>
                                        </select>
                                    </div>
                                    {this.state.waresView === "warehouse" && <div className="filter filter-margin">
                                        <a id="open" onClick={this.toggleAll}>Ava kõik</a>
                                        <a id="close" onClick={this.toggleAll}>Sulge kõik</a>
                                    </div>}
                                </div> ||
                                (this.state.filters.active && this.state.waresView === "stock") &&
                                <div className="warehouse-filters">
                                    <div className="filter">
                                        <h1>Artikkel</h1>
                                        <input type="text" id="article" value={this.state.filters.article || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Nimi</h1>
                                        <input type="text" id="name" value={this.state.filters.name || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Tüüp</h1>
                                        <select className="article-type" id="type" value={this.state.filters.type || ""} onChange={this.onChange}>
                                            <option value=""></option>
                                            <option value="Palgid">Palgid</option>
                                            <option value="Toore, märg">Toore, märg</option>
                                            <option value="Kuivatis">Kuivatis</option>
                                            <option value="Kuiv pakkimata">Kuiv pakkimata</option>
                                            <option value="Valmis toodang">Valmis toodang</option>
                                            <option value="Küttepuu">Küttepuu</option>
                                            <option value="Pakkevahendid">Pakkevahendid</option>
                                            <option value="Sildid">Sildid</option>
                                        </select>
                                    </div>
                                </div> ||
                                (this.state.filters.active && this.state.waresView === "finished") &&
                                <div className="filters">
                                    <div className="filter">
                                        <h1>Resti number</h1>
                                        <input type="text" id="restnr" value={this.state.filters.restnr || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Koorma number</h1>
                                        <input type="text" id="koorem" value={this.state.filters.koorem || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Lõhkuja</h1>
                                        <input type="text" id="chopper" value={this.state.filters.chopper || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Sortiment</h1>
                                        <input type="text" id="sortiment" value={this.state.filters.sortiment || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Tootmisjärk</h1>
                                        <input type="text" id="phase" value={this.state.filters.phase || ""} onChange={this.onChange} />
                                    </div>
                                    {/* <div className="filter">
                                        <h1>Tõstukijuht</h1>
                                        <input type="text" id="driver" value={this.state.filters.driver || ""} onChange={this.onChange} />
                                    </div> */}
                                    <div className="filter">
                                        <h1>Tsoon</h1>
                                        <select id="zone" value={this.state.filters.zone || ""} onChange={this.onChange}>
                                            <option value=""></option>
                                            <option value="kuivati">Kuivati</option>
                                            {Object.keys(settings?.wares?.zones || {}).map(key => {
                                                return <option value={key}>{key}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="filter">
                                        <h1>Asukoht</h1>
                                        <select id="location" value={this.state.filters.location || ""} onChange={this.onChange}>
                                            <option value=""></option>
                                            {(this.state.filters.zone === "kuivati" ? (settings?.wares?.chambers || []) : (settings?.wares?.zones?.[this.state.filters.zone] || [])).map(key => {
                                                return <option value={key}>{key}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="datefilter">
                                        <h1>Kuivatisse mineku kuupäev</h1>
                                        <input type="date" id="drystartfrom" value={this.state.filters.drystartfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="drystartto" value={this.state.filters.drystartto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter">
                                        <h1>Kuivatist tuleku kuupäev</h1>
                                        <input type="date" id="dryendfrom" value={this.state.filters.dryendfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="dryendto" value={this.state.filters.dryendto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter">
                                        <h1>Lõhkumise algus</h1>
                                        <input type="date" id="chopfrom" value={this.state.filters.chopfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="chopto" value={this.state.filters.chopto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter">
                                        <h1>Lõpetamise kuupäev</h1>
                                        <input type="date" id="finishedfrom" value={this.state.filters.finishedfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="finishedto" value={this.state.filters.finishedto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter" />
                                </div>}
                                {this.state.waresView === "yardstorage" &&
                                <div className="archive-toggles">
                                    <a className={this.state.archiveToggle ? "" : "archive-toggles--active"} toggle="off" onClick={this.archiveRoute}>Ladu</a>
                                    <a className={this.state.archiveToggle ? "archive-toggles--active" : ""} toggle="on" onClick={this.archiveRoute}>Arhiiv</a>
                                </div> ||
                                this.state.waresView === "warehouse" &&
                                <div className="archive-toggles">
                                    <input className="switchbutton" type="checkbox" checked={this.state.warehouseUnits} onChange={this.toggleWarehouseUnits}/>
                                    <h1>{this.state.warehouseUnits ? "Restid" : "Ühikud"}</h1> 
                                </div> ||
                                (this.state.waresView === "rest" || this.state.waresView === "finished") &&
                                <div className="archive-toggles">
                                    <input className="switchbutton" type="checkbox" checked={this.state.showDates} onChange={this.toggleShowDates}/>
                                    <h1>Kuupäevad</h1> 
                                </div>}
                                <a className="exportbutton" onClick={this.exportCSV}><i className="fas fa-file-export"></i></a>
                                {/* {(this.state.waresView === "warehouse" && typeof this.state.toggledValues.value === "string") && <a className="resetbutton" id="resetbutton" onClick={this.resetField}>Taasta <i className="fas fa-undo-alt"/></a>} */}
                            </div>
                            <div className="overview-content1">
                                {/* {this.state.loading === "stocktable" && <div className="loading" />} */}
                                {(this.state.waresView === "warehouse" && (!this.state.filters.article && !this.state.filters.name && !this.state.filters.type)) &&
                                <div className="pagination">
                                    {Array(Math.ceil(Object.keys(this.state.stock || {}).length / 50)).fill(0,0,Math.ceil(Object.keys(this.state.stock || {}).length / 50)).map((key, i) => {
                                        return <h1 className={`pagination-button${this.state.page === i+1 ? " pagination-button--active" : ""}`} key={i} page={i+1} onClick={this.paginate}>{i+1}</h1>
                                    })}
                                </div>}
                                {this.state.waresView === "yardstorage" &&
                                <div className="table" key="1">
                                    <div className="row row-sticky">
                                        <h1 className="wares-koorem">Koorma nr</h1>
                                        <h1 className="wares-10">Saatja</h1>
                                        <h1 className="wares-7">Veoseleht</h1>
                                        <h1 className="wares-6">Saadetud</h1>
                                        <h1 className="wares-7">Vastuvõetud</h1>
                                        <h1 className="wares-4">Praak</h1>
                                        <h1 className="wares-4">Tsoon</h1>
                                        <h1 className="wares-11">Vastuvõtu aeg</h1>
                                        <h1 className="wares-6">Materjal</h1>
                                        <h1 className="wares-7">Lõhkuja</h1>
                                        <h1 className="wares-6">Algus</h1>
                                        <h1 className="wares-6">Sortiment</h1>
                                        <h1 className="wares-6">FSC</h1>
                                        <h1 className="wares-4">Maha</h1>
                                        {Object.keys(settings?.wares?.logs || {}).map(key => {
                                            return <h1 className="wares-5" key={key}>{key}</h1>
                                        })}
                                        <h1 className="wares-4">Maht</h1>
                                        {/* <h1 className="wares-6">Lõpetatud</h1> */}
                                        {this.state.archiveToggle && <h1 className="wares-7">Kuupäev</h1>}
                                        <h1 className="wares-10">Tõstukijuht</h1>
                                        <h1 className="wares-15">Kommentaarid</h1>
                                        {!this.state.archiveToggle && <h1 className="wares-del"></h1>}
                                        {/* <h1 className="wares-7">Kontrollitud</h1> */}
                                    </div>
                                    {!this.state.archiveToggle && Object.keys(this.state.data || {}).map(key => {
                                        let {_id, koorem, sender, veoseleht, sent, received, defect, zone, startDate, material, chopper, chopStart, sortiment, maha, logs, finished, finishDate, driver, comments, verified, maht, fsc} = this.state.data[key]
                                        let filters = this.state.filters


                                        if (filters.koorem && !(koorem||"").toLowerCase().includes(filters.koorem.toLowerCase())) return
                                        if (filters.sender && !(sender||"").toLowerCase().includes(filters.sender.toLowerCase())) return
                                        if (filters.veoseleht && !(veoseleht||"").toLowerCase().includes(filters.veoseleht.toLowerCase())) return
                                        if (filters.zone && !(zone||"").toLowerCase().includes(filters.zone.toLowerCase())) return
                                        if (filters.material && !(material||"").toLowerCase().includes(filters.material.toLowerCase())) return
                                        if (filters.driver && !(driver?.name||"").toLowerCase().includes(filters.driver.toLowerCase())) return
                                        if (filters.chopper && !(chopper||"").toLowerCase().includes(filters.chopper.toLowerCase())) return
                                        if (filters.sortiment && !(sortiment||"").toLowerCase().includes(filters.sortiment.toLowerCase())) return
                                        if (filters.tm && (!sent.includes(filters.tm) && !received.includes(filters.tm) && !defect.includes(filters.tm)/* && !MAHT.includes(filters.tm)*/)) return
                                        if (filters.finished && !finished) return
                                        if (filters.unfinished && finished) return
                                        if (filters.fsc === "1" && !fsc) return
                                        if (filters.fsc === "0" && fsc) return
                                        
                                        let receivedDate = moment(startDate, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD")
                                        if (filters.receivedfrom > receivedDate || filters.receivedto < receivedDate) return
                                        let chopStartDate = moment(chopStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                        if (filters.chopfrom > chopStartDate || filters.chopto < chopStartDate) return
                                        if (filters.finishfrom > finishDate || filters.finishto < finishDate) return

                                        return <div className={`row ${this.state._id === key ? "row-highlight" : ""}`} key={key} id={key} onClick={this.highlight}>
                                            <h1 className="wares-koorem">{koorem}</h1>
                                            <h1 className="wares-10">{sender}</h1>
                                            <h1 className="wares-7">{veoseleht}</h1>
                                            <h1 className="wares-6">{sent}</h1>
                                            <h1 className="wares-7">{received}</h1>
                                            <h1 className="wares-4">{defect}</h1>
                                            <h1 className="wares-4">{zone}</h1>
                                            <h1 className="wares-11">{startDate}</h1>
                                            <h1 className="wares-6">{material}</h1>
                                            <h1 className="wares-7">{chopper}</h1>
                                            <h1 className="wares-6">{chopStart}</h1>
                                            <h1 className="wares-6">{sortiment}</h1>
                                            <h1 className="wares-6">{fsc ? "Jah" : "Ei"}</h1>
                                            <h1 className="wares-4">{maha ? "Jah" : "Ei"}</h1>
                                            {Object.keys(settings?.wares?.logs || {}).map(key => {
                                                return <h1 className="wares-5" key={key}>{logs?.[key] || ""}</h1>
                                            })}
                                            <h1 className="wares-4">{Math.round((maht + Number.EPSILON) * 100) / 100}</h1>
                                            {/* <h1 className="wares-6">{finished ? "Lõpetatud" : ""}</h1> */}
                                            {/* <h1 className="wares-7">{finishDate}</h1> */}
                                            <h1 className="wares-10">{driver.name}</h1>
                                            <h1 className="wares-15">{comments}</h1>
                                            <h1 className="wares-del"><i className="fas fa-trash-alt" id={_id} category="yardstorage" onClick={this.deleteWares}/></h1>
                                            {/* <h1 className="wares-7">{verified ? "Jah" : "Ei"}</h1> */}
                                        </div>
                                    })}
                                    {this.state.archiveToggle && Object.keys(this.state.archive || {}).map(key => {
                                        let {koorem, sender, veoseleht, sent, received, defect, zone, startDate, material, chopper, chopStart, sortiment, maha, logs, finished, finishDate, driver, comments, verified, maht, fsc} = this.state.archive[key]
                                        let filters = this.state.filters

                                        if (filters.koorem && !(koorem||"").toLowerCase().includes(filters.koorem.toLowerCase())) return
                                        if (filters.sender && !(sender||"").toLowerCase().includes(filters.sender.toLowerCase())) return
                                        if (filters.veoseleht && !(veoseleht||"").toLowerCase().includes(filters.veoseleht.toLowerCase())) return
                                        if (filters.zone && !(zone||"").toLowerCase().includes(filters.zone.toLowerCase())) return
                                        if (filters.material && !(material||"").toLowerCase().includes(filters.material.toLowerCase())) return
                                        if (filters.chopper && !(chopper||"").toLowerCase().includes(filters.chopper.toLowerCase())) return
                                        if (filters.sortiment && !(sortiment||"").toLowerCase().includes(filters.sortiment.toLowerCase())) return
                                        if (filters.tm && (!sent.includes(filters.tm) && !received.includes(filters.tm) && !defect.includes(filters.tm)/* && !MAHT.includes(filters.tm)*/)) return
                                        if (filters.fsc === "1" && !fsc) return
                                        if (filters.fsc === "0" && fsc) return

                                        let receivedDate = moment(startDate, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD")
                                        if (filters.receivedfrom > receivedDate || filters.receivedto < receivedDate) return
                                        let chopStartDate = moment(chopStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                        if (filters.chopfrom > chopStartDate || filters.chopto < chopStartDate) return
                                        if (filters.finishfrom > finishDate || filters.finishto < finishDate) return

                                        return <div className={`row ${this.state._id === key ? "row-highlight" : ""}`} key={key} id={key} onClick={this.highlight}>
                                            <h1 className="wares-koorem">{koorem}</h1>
                                            <h1 className="wares-10">{sender}</h1>
                                            <h1 className="wares-7">{veoseleht}</h1>
                                            <h1 className="wares-6">{sent}</h1>
                                            <h1 className="wares-7">{received}</h1>
                                            <h1 className="wares-4">{defect}</h1>
                                            <h1 className="wares-4">{zone}</h1>
                                            <h1 className="wares-11">{startDate}</h1>
                                            <h1 className="wares-6">{material}</h1>
                                            <h1 className="wares-7">{chopper}</h1>
                                            <h1 className="wares-6">{chopStart}</h1>
                                            <h1 className="wares-6">{sortiment}</h1>
                                            <h1 className="wares-6">{fsc ? "Jah" : "Ei"}</h1>
                                            <h1 className="wares-4">{maha ? "Jah" : "Ei"}</h1>
                                            {Object.keys(settings?.wares?.logs || {}).map(key => {
                                                return <h1 className="wares-5" key={key}>{logs?.[key] || ""}</h1>
                                            })}
                                            <h1 className="wares-4">{Math.round((maht + Number.EPSILON) * 100) / 100}</h1>
                                            {/* <h1 className="wares-6">{finished ? "Lõpetatud" : ""}</h1> */}
                                            <h1 className="wares-7">{moment(finishDate).format("DD/MM/YYYY")}</h1>
                                            <h1 className="wares-10">{driver.name}</h1>
                                            <h1 className="wares-15">{comments}</h1>
                                            {/* <h1 className="wares-7">{verified ? "Jah" : "Ei"}</h1> */}
                                        </div>
                                    })}
                                </div> ||
                                this.state.waresView === "rest" &&
                                <div className="table centered" key="2">
                                    <div className="row row-sticky">
                                        <h1 className="wares-koorem">Resti nr</h1>
                                        <h1 className="wares-6">Koorma nr</h1>
                                        <h1 className="wares-7">Lõhkuja</h1>
                                        {this.state.showDates && <h1 className="wares-7">Lõhkumine</h1>}
                                        <h1 className="wares-6">Sortiment</h1>
                                        <h1 className="wares-7 wares-left">Artikkel</h1>
                                        <h1 className="wares-7">Tootmisjärk</h1>
                                        <h1 className="wares-7">Tsoon</h1>
                                        <h1 className="wares-7">Asukoht</h1>
                                        <h1 className="wares-6">Tükiarv</h1>
                                        {this.state.showDates && <h1 className="wares-7">Kuivatisse</h1>}
                                        {this.state.showDates && <h1 className="wares-7">Kuivatist välja</h1>}
                                        <h1 className="wares-6">FSC</h1>
                                        {/* <h1 className="wares-7">Lõpetatud</h1> */}
                                        <h1 className="wares-7">Ajalugu</h1>
                                        <h1 className="wares-del"></h1>
                                    </div>
                                    {Object.keys(this.state.restid).map(key => {
                                        let {_id, chopper, finished, parent, phase, restNr, sortiment, fsc, article, driver, location, amount, chopStart, dryStart, dryEnd} = this.state.restid[key]
                                        if (finished) return
                                        let filters = this.state.filters
                                        if (filters.restnr && !(restNr||"").toLowerCase().includes(filters.restnr.toLowerCase())) return
                                        if (filters.koorem && !((this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem)||"").toLowerCase().includes(filters.koorem.toLowerCase())) return
                                        if (filters.chopper && !(chopper||"").toLowerCase().includes(filters.chopper.toLowerCase())) return
                                        if (filters.sortiment && !(sortiment||"").toLowerCase().includes(filters.sortiment.toLowerCase())) return
                                        if (filters.phase && !(phase||"").toLowerCase().includes(filters.phase.toLowerCase())) return
                                        // if (filters.driver && !(driver?.name||"").toLowerCase().includes(filters.driver.toLowerCase())) return
                                        if (filters.zone && !(location.mainzone||"Kuivati").toLowerCase().includes(filters.zone.toLowerCase())) return
                                        if (filters.location && !((location.subzone||location.chamber)||"").toLowerCase().includes(filters.location.toLowerCase())) return
                                        if (filters.fsc === "1" && !fsc) return
                                        if (filters.fsc === "0" && fsc) return
                                        
                                        let chopStartDate = moment(chopStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                        if (filters.chopfrom > chopStartDate || filters.chopto < chopStartDate) return
                                        let dryStartDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                        if (filters.drystartfrom > dryStartDate || filters.drystartto < dryStartDate) return
                                        let dryEndDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                        if (filters.drystartfrom > dryEndDate || filters.drystartto < dryEndDate) return

                                        return <div className="row" key={key}>
                                            <h1 className="wares-koorem">{restNr}</h1>
                                            <h1 className="wares-6">{this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem}</h1>
                                            <h1 className="wares-7">{chopper}</h1>
                                            {this.state.showDates && <h1 className="wares-7">{chopStart}</h1>}
                                            <h1 className="wares-6">{sortiment}</h1>
                                            <h1 className="wares-7 wares-left">{article}</h1>
                                            <h1 className="wares-7">{phase}</h1>
                                            <h1 className="wares-7">{location.mainzone || "Kuivati"}</h1>
                                            <h1 className="wares-7">{location.subzone || location.chamber}</h1>
                                            <h1 className="wares-6">{amount}</h1>
                                            {this.state.showDates && <h1 className="wares-7">{dryStart}</h1>}
                                            {this.state.showDates && <h1 className="wares-7">{dryEnd}</h1>}
                                            <h1 className="wares-6">{fsc ? "Jah" : "Ei"}</h1>
                                            {/* <h1 className="wares-7">{finished ? "Jah" : "Ei"}</h1> */}
                                            <a className="wares-7" id={key} onClick={this.toggleHistoryModal}>Vaata</a>
                                            <h1 className="wares-del"><i className="fas fa-trash-alt" id={_id} category="rest" onClick={this.deleteWares}/></h1>
                                        </div>
                                    })}
                                </div> ||
                                this.state.waresView === "alus" &&
                                <div className="table centered" key="3">
                                    <div className="row row-sticky">
                                        <h1 className="wares-koorem">Aluse nr</h1>
                                        <h1 className="wares-7 wares-left">Artikkel</h1>
                                        <h1 className="wares-7">Tootmisjärk</h1>
                                        <h1 className="wares-7">Tsoon</h1>
                                        <h1 className="wares-7">Asukoht</h1>
                                        <h1 className="wares-6">Tükiarv</h1>
                                        {/* <h1 className="wares-7">Lõpetatud</h1> */}
                                        <h1 className="wares-8">Tellimus</h1>
                                        <h1 className="wares-6">FSC</h1>
                                        <h1 className="wares-11">Loomise aeg</h1>
                                        <h1 className="wares-7">Ajalugu</h1>
                                        {/* <h1 className="wares-del"></h1> */}
                                    </div>
                                    {Object.keys(this.state.alused).map(key => {
                                        let {_id, finished, phase, restNr, article, driver, location, amount, order, fsc, createdAt} = this.state.alused[key]
                                        if (finished) return
                                        let filters = this.state.filters
                                        if (filters.restnr && !(restNr||"").toLowerCase().includes(filters.restnr.toLowerCase())) return
                                        if (filters.phase && !(phase||"").toLowerCase().includes(filters.phase.toLowerCase())) return
                                        // if (filters.driver && !(driver?.name||"").toLowerCase().includes(filters.driver.toLowerCase())) return
                                        if (filters.zone && !(location.mainzone||"Kuivati").toLowerCase().includes(filters.zone.toLowerCase())) return
                                        if (filters.location && !((location.subzone||location.chamber)||"").toLowerCase().includes(filters.location.toLowerCase())) return
                                        if (filters.order && !(order||"").toLowerCase().includes(filters.order.toLowerCase())) return
                                        if (filters.fsc === "1" && !fsc) return
                                        if (filters.fsc === "0" && fsc) return

                                        let createdAtF = moment(createdAt).format("YYYY-MM-DD")
                                        if (filters.createdfrom > createdAtF || filters.createdto < createdAtF) return
                                        
                                        return <div className="row" key={key}>
                                            <h1 className="wares-koorem">{restNr}</h1>
                                            <h1 className="wares-7 wares-left">{article}</h1>
                                            <h1 className="wares-7">{phase}</h1>
                                            <h1 className="wares-7">{location.mainzone || "Kuivati"}</h1>
                                            <h1 className="wares-7">{location.subzone || location.chamber}</h1>
                                            <h1 className="wares-6">{amount}</h1>
                                            {/* <h1 className="wares-7">{finished ? "Jah" : "Ei"}</h1> */}
                                            <h1 className="wares-8">{order}</h1>
                                            <h1 className="wares-6">{fsc ? "Jah" : "Ei"}</h1>
                                            <h1 className="wares-11">{moment(createdAt).format("DD/MM/YYYY HH:mm")}</h1>
                                            <a className="wares-7" id={key} onClick={this.toggleHistoryModal}>Vaata</a>
                                            {/* <h1 className="wares-del"><i className="fas fa-trash-alt" id={_id} category="alus" onClick={this.deleteWares}/></h1> */}
                                        </div>
                                    })}
                                </div> ||
                                this.state.waresView === "warehouse" && 
                                <div className="table" key="4">
                                    <div className="row row-sticky">
                                        {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key, i) => {
                                            if (i===0) return <h1 key={i} className="empty"></h1>
                                            let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                            if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                            return <h1 key={i} id={date} className={`date${this.state.toggledDates.includes(date) ? " date--active" : ""} ${date === moment().format("DD/MM/YY") ? "currentdate" : ""}`} onClick={this.dateToggle}>{date}</h1>
                                        })}
                                        <h1 id="summary" className="date date--active1">Kokku</h1>
                                    </div>
                                    <div className="row row-sticky2">
                                        {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key, i) => {
                                            if (i===0) return <h1 key={i} className="empty"></h1>
                                            let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                            if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                            return <div key={i} className={`datecolumns${this.state.toggledDates.includes(date) ? " datecolumns--active" : ""}`}>
                                                <h1>Seis</h1>
                                                {this.state.toggledDates.includes(date) && <h1>Sisse</h1>}
                                                {this.state.toggledDates.includes(date) && <h1>Välja</h1>}
                                            </div>
                                        })}
                                        <div className="datecolumns datecolumns--active1">
                                            <h1>Algus</h1>
                                            <h1>Sisse</h1>
                                            <h1>Välja</h1>
                                            <h1>Lõpp</h1>
                                            <h1>Muut</h1>
                                        </div>
                                    </div>
                                    {Object.keys(this.state.stock).map((key, i) => {
                                        let {name, count, fsc, unit, log, type, convertible} = this.state.stock[key]
                                        let toggledValues = this.state.toggledValues
                                        if (this.state.filters.article) {
                                            if (!key.toLowerCase().includes(this.state.filters.article.toLowerCase())) return
                                        }
                                        if (this.state.filters.name) {
                                            if (!name.toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                                        }
                                        if (this.state.filters.type) {
                                            if (type !== this.state.filters.type) return
                                        }
                                        if (!this.state.filters.article && !this.state.filters.name && !this.state.filters.type) {
                                            if (i >= this.state.page*50 || i < (this.state.page-1)*50) return
                                        }
                                        let first = 0
                                        let countSum = 0
                                        let inSum = 0
                                        let outSum = 0
                                        let countValue
                                        let inValue
                                        let outValue
                                        return <div className={`row${convertible ? " convertible" : ""}`} key={key}>
                                            {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key1, i) => {
                                                if (i===0) return <h1 key={i} className={`empty${convertible ? " convertible" : ""}`}>{name}<span>{key}</span></h1>
                                                let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                                if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                                countValue = 0
                                                inValue = 0
                                                outValue = 0
                                                if (this.state.filters.fsc === "0") {
                                                    if (this.state.warehouseUnits) {
                                                        if (i===1) first = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0
                                                        countValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count
                                                        inValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.in
                                                        outValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.out
                                                        countSum = countValue ? Number(countValue) : countSum
                                                        inSum += inValue ? Number(inValue) : 0
                                                        outSum += outValue ? Number(outValue) : 0
                                                    } else {
                                                        if (i===1) first = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0
                                                        countValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count
                                                        inValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.in
                                                        outValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.out
                                                        if (convertible) {
                                                            let rm = Number(key.split("-")[key.split("-").length-1].replace(/,/g, '.'))
                                                            if (i===1) first = (first*rm || first*rm === 0) ? first*rm : undefined
                                                            countValue = (countValue*rm || countValue*rm === 0) ? countValue*rm : undefined
                                                            inValue = (inValue*rm || inValue*rm === 0) ? inValue*rm : undefined
                                                            outValue = (outValue*rm || outValue*rm === 0) ? outValue*rm : undefined
                                                        }
                                                        countSum = countValue ? Number(countValue) : countSum
                                                        inSum += inValue ? Number(inValue) : 0
                                                        outSum += outValue ? Number(outValue) : 0
                                                    }
                                                } else if (this.state.filters.fsc === "1") {
                                                    if (this.state.warehouseUnits) {
                                                        if (i===1) first = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count || 0
                                                        countValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count
                                                        inValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.in
                                                        outValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.out
                                                        countSum = countValue ? Number(countValue) : countSum
                                                        inSum += inValue ? Number(inValue) : 0
                                                        outSum += outValue ? Number(outValue) : 0
                                                    } else {
                                                        if (i===1) first = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count || 0
                                                        countValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count
                                                        inValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.in
                                                        outValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.out
                                                        if (convertible) {
                                                            let rm = Number(key.split("-")[key.split("-").length-1].replace(/,/g, '.'))
                                                            if (i===1) first = (first*rm || first*rm === 0) ? first*rm : undefined
                                                            countValue = (countValue*rm || countValue*rm === 0) ? countValue*rm : undefined
                                                            inValue = (inValue*rm || inValue*rm === 0) ? inValue*rm : undefined
                                                            outValue = (outValue*rm || outValue*rm === 0) ? outValue*rm : undefined
                                                        }
                                                        countSum = countValue ? Number(countValue) : countSum
                                                        inSum += inValue ? Number(inValue) : 0
                                                        outSum += outValue ? Number(outValue) : 0
                                                    }
                                                } else {
                                                    if (this.state.warehouseUnits) {
                                                        if (i===1) first = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count || 0)
                                                        countValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count || 0)
                                                        inValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.in || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.in || 0)
                                                        outValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.out || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.out || 0)
                                                        countSum = countValue ? Number(countValue) : countSum
                                                        inSum += inValue ? Number(inValue) : 0
                                                        outSum += outValue ? Number(outValue) : 0
                                                    } else {
                                                        if (i===1) first = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0
                                                        countValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count || 0)
                                                        inValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.in || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.in || 0)
                                                        outValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.out || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.out || 0)
                                                        if (convertible) {
                                                            let rm = Number(key.split("-")[key.split("-").length-1].replace(/,/g, '.'))
                                                            if (i===1) first = (first*rm || first*rm === 0) ? first*rm : undefined
                                                            countValue = (countValue*rm || countValue*rm === 0) ? countValue*rm : undefined
                                                            inValue = (inValue*rm || inValue*rm === 0) ? inValue*rm : undefined
                                                            outValue = (outValue*rm || outValue*rm === 0) ? outValue*rm : undefined
                                                        }
                                                        countSum = countValue ? Number(countValue) : countSum
                                                        inSum += inValue ? Number(inValue) : 0
                                                        outSum += outValue ? Number(outValue) : 0
                                                    }
                                                }
                                                return <div key={i} className={`datecolumns${this.state.toggledDates.includes(date) ? " datecolumns--active" : ""}`}>
                                                    <h1 item={key} date={date} valuetype="count" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{isNaN(countValue) ? "" : Math.round((countValue + Number.EPSILON) * 100) / 100 || ""}</h1>
                                                    {this.state.toggledDates.includes(date) && <h1 item={key} date={date} valuetype="in" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{isNaN(inValue) ? "" : Math.round((inValue + Number.EPSILON) * 100) / 100 || ""}</h1>}
                                                    {this.state.toggledDates.includes(date) && <h1 item={key} date={date} valuetype="out" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{isNaN(outValue) ? "" : Math.round((outValue + Number.EPSILON) * 100) / 100 || ""}</h1>}
                                                    {/* <input id={"out"+key+date} ref={this.wrapperRef} value={typeof toggledValues.value === "string" ? toggledValues.value : (outValue || "")} item={key} date={date} valuetype="out" onChange={this.onChangeValue} /> || */}
                                                </div>
                                            })}
                                            <div className="datecolumns datecolumns--active1">
                                                <h1>{isNaN(first) ? "" : Math.round((first + Number.EPSILON) * 100) / 100}</h1>
                                                <h1>{isNaN(inSum) ? "" : Math.round((inSum + Number.EPSILON) * 100) / 100}</h1>
                                                <h1>{isNaN(outSum) ? "" : Math.round((outSum + Number.EPSILON) * 100) / 100}</h1>
                                                <h1>{isNaN(countSum) ? "" : Math.round((countSum+(inValue||0)-(outValue||0) + Number.EPSILON) * 100) / 100}</h1>
                                                <h1>{(isNaN(countSum) ? "" : Math.round((countSum+(inValue||0)-(outValue||0) + Number.EPSILON) * 100) / 100) - (isNaN(first) ? "" : Math.round((first + Number.EPSILON) * 100) / 100)}</h1>
                                            </div>
                                        </div>
                                    })}
                                </div> ||
                                this.state.waresView === "stock" && 
                                <div className="table" key="5">
                                    <div className="row row-sticky">
                                        <h1 className="stock-nr">Artikkel</h1>
                                        <h1 className="stock-name">Nimi</h1>
                                        <h1 className="stock-unit">Ühik</h1>
                                        <h1 className="stock-count">Laoseis</h1>
                                        <h1 className="stock-edit-header">Muuda</h1>
                                        <h1 className="stock-count">Laoseis FSC</h1>
                                        <h1 className="stock-edit-header">Muuda FSC</h1>
                                    </div>
                                    {Object.keys(this.state.stock).map(key => {
                                        let {name, type, count, unit, fsc} = this.state.stock[key]
                                        if (this.state.filters.article && !key.toLowerCase().includes(this.state.filters.article.toLowerCase())) return
                                        if (this.state.filters.name && !name.toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                                        if (this.state.filters.type && !type.toLowerCase().includes(this.state.filters.type.toLowerCase())) return
                                        return <div className="row" key={key}>
                                            <h1 className="stock-nr">{key}</h1>
                                            <h1 className="stock-name">{name}</h1>
                                            <h1 className="stock-unit">{unit}</h1>
                                            <h1 className="stock-count">{Math.round((count + Number.EPSILON) * 100) / 100}</h1>
                                            <div className="stock-edit">
                                                <input type="number" value={this.state.editStock[key]?.count || ""} className="count" id={key} onChange={this.onChangeStock}/>
                                                {this.state.editStock[key]?.count && <h1 id={key} class="count" onClick={this.editStock}>&#9998;</h1>}
                                            </div>
                                            <h1 className="stock-count">{Math.round((fsc + Number.EPSILON) * 100) / 100}</h1>
                                            <div className="stock-edit">
                                                <input type="number" value={this.state.editStock[key]?.fsc || ""} className="fsc" id={key} onChange={this.onChangeStock}/>
                                                {this.state.editStock[key]?.fsc && <h1 id={key} class="fsc" onClick={this.editStock}>&#9998;</h1>}
                                            </div>
                                        </div>
                                    })}
                                </div> ||
                                this.state.waresView === "haljala" &&
                                <div className="table" key="6">
                                    <div className="row row-sticky">
                                        {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key, i) => {
                                            if (i===0) return <h1 key={i} className="empty"></h1>
                                            let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                            if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                            return <h1 key={i} id={date} className={`date ${date === moment().format("DD/MM/YY") ? "currentdate" : ""}`}>{date}</h1>
                                        })}
                                        <h1 id="summary" className="date">Kokku</h1>
                                    </div>
                                    {Object.keys(this.state.stock).map((key, i) => {
                                        let {name, type, count, unit, log, haljala} = this.state.stock[key]
                                        if (!haljala) return
                                        if (!Object.keys(haljala).length) return
                                        if (this.state.filters.article) {
                                            if (!key.toLowerCase().includes(this.state.filters.article.toLowerCase())) return
                                        }
                                        if (this.state.filters.name) {
                                            if (!name.toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                                        }
                                        if (this.state.filters.type) {
                                            if (type !== this.state.filters.type) return
                                        }
                                        let inSum = 0
                                        return <div className="row" key={key}>
                                            {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key1, i) => {
                                                if (i===0) return <h1 key={i} className="empty">{name}</h1>
                                                let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                                if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                                let inValue
                                                if (this.state.filters.fsc === "0") {
                                                    inValue = haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0
                                                } else if (this.state.filters.fsc === "1") {
                                                    inValue = haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0
                                                } else {
                                                    inValue = (haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0) + (haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0)
                                                }
                                                inSum += inValue ? Number(inValue) : 0
                                                return <div key={i} className="datecolumns">
                                                    <h1 item={key} date={date} valuetype="count" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{isNaN(inValue) ? "" : Math.round((inValue + Number.EPSILON) * 100) / 100 || ""}</h1>
                                                </div>
                                            })}
                                            <div className="datecolumns">
                                                <h1>{isNaN(inSum) ? "" : Math.round((inSum + Number.EPSILON) * 100) / 100}</h1>
                                            </div>
                                        </div>
                                    })}
                                </div> ||
                                // this.state.waresView === "tootmine" &&
                                // <div className="table" key="6">
                                //     <div className="row row-sticky">
                                //         {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key, i) => {
                                //             if (i===0) return <h1 key={i} className="empty"></h1>
                                //             let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                //             if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                //             return <h1 key={i} id={date} className={`date ${date === moment().format("DD/MM/YY") ? "currentdate" : ""}`}>{date}</h1>
                                //         })}
                                //         <h1 id="summary" className="date">Kokku</h1>
                                //     </div>
                                //     {Object.keys(this.state.stock).map((key, i) => {
                                //         let {name, count, unit, log, presence} = this.state.stock[key]
                                //         if (!presence?.includes("tootmine")) return
                                //         if (this.state.filters.name) {
                                //             if (!name.toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                                //         } else {
                                //             // if (i >= this.state.page*50 || i < (this.state.page-1)*50) return 
                                //         }
                                //         let inSum = 0
                                //         return <div className="row" key={key}>
                                //             {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key1, i) => {
                                //                 if (i===0) return <h1 key={i} className="empty">{name}</h1>
                                //                 let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                //                 if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                //                 let inValue = 0
                                //                 Object.keys(this.state.restid).forEach(key => {
                                //                     let rest = this.state.restid[key]
                                //                     if (rest.phase !== "Tootmine" || rest.finishDate !== moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")) return
                                //                     inValue += 1
                                //                 })
                                //                 inSum += inValue ? Number(inValue) : 0
                                //                 return <div key={i} className="datecolumns">
                                //                     <h1 item={key} date={date} valuetype="count" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{inValue || ""}</h1>
                                //                 </div>
                                //             })}
                                //             <div className="datecolumns">
                                //                 <h1>{inSum}</h1>
                                //             </div>
                                //         </div>
                                //     })}
                                // </div> ||
                                this.state.waresView === "myyk" &&
                                <div className="table" key="7">
                                    <div className="row row-sticky">
                                        {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key, i) => {
                                            if (i===0) return <h1 key={i} className="empty"></h1>
                                            let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                            if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                            return <h1 key={i} id={date} className={`date ${date === moment().format("DD/MM/YY") ? "currentdate" : ""}`}>{date}</h1>
                                        })}
                                        <h1 id="summary" className="date">Kokku</h1>
                                    </div>
                                    {Object.keys(this.state.stock).map((key, i) => {
                                        let {name, type, count, unit, log, myyk} = this.state.stock[key]
                                        if (!myyk) return
                                        if (!Object.keys(myyk).length) return
                                        if (this.state.filters.article) {
                                            if (!key.toLowerCase().includes(this.state.filters.article.toLowerCase())) return
                                        }
                                        if (this.state.filters.name) {
                                            if (!name.toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                                        }
                                        if (this.state.filters.type) {
                                            if (type !== this.state.filters.type) return
                                        }
                                        let inSum = 0
                                        return <div className="row" key={key}>
                                            {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key1, i) => {
                                                if (i===0) return <h1 key={i} className="empty">{name}</h1>
                                                let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                                if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                                let inValue
                                                if (this.state.filters.fsc === "0") {
                                                    inValue = myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0
                                                } else if (this.state.filters.fsc === "1") {
                                                    inValue = myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0
                                                } else {
                                                    inValue = (myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0) + (myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0)
                                                }
                                                inSum += inValue ? Number(inValue) : 0
                                                return <div key={i} className="datecolumns">
                                                    <h1 item={key} date={date} valuetype="count" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{isNaN(inValue) ? "" : Math.round((inValue + Number.EPSILON) * 100) / 100 || ""}</h1>
                                                </div>
                                            })}
                                            <div className="datecolumns">
                                                <h1>{isNaN(inSum) ? "" : Math.round((inSum + Number.EPSILON) * 100) / 100}</h1>
                                            </div>
                                        </div>
                                    })}
                                </div> ||
                                this.state.waresView === "finished" &&
                                <div className="table centered" key="8">
                                    <div className="row row-sticky">
                                        <h1 className="wares-koorem">Resti nr</h1>
                                        <h1 className="wares-6">Koorma nr</h1>
                                        <h1 className="wares-7">Lõhkuja</h1>
                                        {this.state.showDates && <h1 className="wares-7">Lõhkumine</h1>}
                                        <h1 className="wares-6">Sortiment</h1>
                                        <h1 className="wares-7 wares-left">Artikkel</h1>
                                        <h1 className="wares-7">Tootmisjärk</h1>
                                        {this.state.showDates && <h1 className="wares-7">Kuivatisse</h1>}
                                        {this.state.showDates && <h1 className="wares-7">Kuivatist välja</h1>}
                                        {this.state.showDates && <h1 className="wares-10">Loomine</h1>}
                                        {this.state.showDates && <h1 className="wares-10">Lõpetamine</h1>}
                                        {/* <h1 className="wares-7">Lõpetatud</h1> */}
                                        <h1 className="wares-8">Tellimus</h1>
                                        <h1 className="wares-6">Kogus</h1>
                                        <h1 className="wares-6">FSC</h1>
                                        <h1 className="wares-7">Ajalugu</h1>
                                    </div>
                                    {Object.keys(this.state.restid).map(key => {
                                        let {chopper, finished, parent, phase, restNr, sortiment, article, driver, location, order, chopStart, dryStart, dryEnd, finishDate, fsc, createdAt, amount} = this.state.restid[key]
                                        if (!finished) return
                                        let filters = this.state.filters
                                        if (filters.restnr && !(restNr||"").toLowerCase().includes(filters.restnr.toLowerCase())) return
                                        if (filters.koorem && !((this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem)||"").toLowerCase().includes(filters.koorem.toLowerCase())) return
                                        if (filters.chopper && !(chopper||"").toLowerCase().includes(filters.chopper.toLowerCase())) return
                                        if (filters.sortiment && !(sortiment||"").toLowerCase().includes(filters.sortiment.toLowerCase())) return
                                        if (filters.phase && !(phase||"").toLowerCase().includes(filters.phase.toLowerCase())) return
                                        // if (filters.driver && !(driver?.name||"").toLowerCase().includes(filters.driver.toLowerCase())) return
                                        if (filters.zone && !(location.mainzone||"Kuivati").toLowerCase().includes(filters.zone.toLowerCase())) return
                                        if (filters.location && !((location.subzone||location.chamber)||"").toLowerCase().includes(filters.location.toLowerCase())) return
                                        if (filters.fsc === "1" && !fsc) return
                                        if (filters.fsc === "0" && fsc) return

                                        let chopStartDate = moment(chopStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                        if (filters.chopfrom > chopStartDate || filters.chopto < chopStartDate) return
                                        let dryStartDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                        if (filters.drystartfrom > dryStartDate || filters.drystartto < dryStartDate) return
                                        let dryEndDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                        if (filters.drystartfrom > dryEndDate || filters.drystartto < dryEndDate) return
                                        if (filters.finishedfrom > finishDate || filters.finishedto < finishDate) return

                                        let createdAtF = moment(createdAt).format("YYYY-MM-DD")
                                        if (filters.createdfrom > createdAtF || filters.createdto < createdAtF) return

                                        return <div className="row" key={key}>
                                            <h1 className="wares-koorem">{restNr}</h1>
                                            <h1 className="wares-6">{this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem}</h1>
                                            <h1 className="wares-7">{chopper}</h1>
                                            {this.state.showDates && <h1 className="wares-7">{chopStart}</h1>}
                                            <h1 className="wares-6">{sortiment}</h1>
                                            <h1 className="wares-7 wares-left">{article}</h1>
                                            <h1 className="wares-7">{phase}</h1>
                                            {this.state.showDates && <h1 className="wares-7">{dryStart}</h1>}
                                            {this.state.showDates && <h1 className="wares-7">{dryEnd}</h1>}
                                            {this.state.showDates && <h1 className="wares-10">{moment(createdAt).format("DD/MM/YYYY HH:mm")}</h1>}
                                            {this.state.showDates && <h1 className="wares-10">{moment(finishDate).format("DD/MM/YYYY")}</h1>}
                                            {/* <h1 className="wares-7">{finished ? "Jah" : "Ei"}</h1> */}
                                            <h1 className="wares-8">{order || ""}</h1>
                                            <h1 className="wares-6">{amount || ""}</h1>
                                            <h1 className="wares-6">{fsc ? "Jah" : "Ei"}</h1>
                                            <a className="wares-7" id={key} onClick={this.toggleHistoryModal}>Vaata</a>
                                        </div>
                                    })}
                                    {Object.keys(this.state.alused).map(key => {
                                        let {finished, phase, restNr, article, driver, location, amount, order, createdAt, fsc, finishDate} = this.state.alused[key]
                                        if (!finished) return
                                        let filters = this.state.filters
                                        if (filters.restnr && !(restNr||"").toLowerCase().includes(filters.restnr.toLowerCase())) return
                                        if (filters.phase && !(phase||"").toLowerCase().includes(filters.phase.toLowerCase())) return
                                        if (filters.driver && !(driver?.name||"").toLowerCase().includes(filters.driver.toLowerCase())) return
                                        if (filters.zone && !(location.mainzone||"Kuivati").toLowerCase().includes(filters.zone.toLowerCase())) return
                                        if (filters.location && !((location.subzone||location.chamber)||"").toLowerCase().includes(filters.location.toLowerCase())) return
                                        if (filters.order && !(order||"").toLowerCase().includes(filters.order.toLowerCase())) return
                                        if (filters.fsc === "1" && !fsc) return
                                        if (filters.fsc === "0" && fsc) return

                                        let createdAtF = moment(createdAt).format("YYYY-MM-DD")
                                        if (filters.createdfrom > createdAtF || filters.createdto < createdAtF) return
                                        if (filters.finishedfrom > finishDate || filters.finishedto < finishDate) return
                                        
                                        return <div className="row" key={key}>
                                            <h1 className="wares-koorem">{restNr}</h1>
                                            <h1 className="wares-6"></h1>
                                            <h1 className="wares-7"></h1>
                                            {this.state.showDates && <h1 className="wares-7"></h1>}
                                            <h1 className="wares-6"></h1>
                                            <h1 className="wares-7 wares-left">{article}</h1>
                                            <h1 className="wares-7">{phase}</h1>
                                            {/* <h1 className="wares-7">{finished ? "Jah" : "Ei"}</h1> */}
                                            {this.state.showDates && <h1 className="wares-7"></h1>}
                                            {this.state.showDates && <h1 className="wares-7"></h1>}
                                            {this.state.showDates && <h1 className="wares-10">{moment(createdAt).format("DD/MM/YYYY HH:mm")}</h1>}
                                            {this.state.showDates && <h1 className="wares-10">{moment(finishDate).format("DD/MM/YYYY")}</h1>}
                                            <h1 className="wares-8">{order}</h1>
                                            <h1 className="wares-6">{amount}</h1>
                                            <h1 className="wares-6">{fsc ? "Jah" : "Ei"}</h1>
                                            <a className="wares-7" id={key} onClick={this.toggleHistoryModal}>Vaata</a>
                                        </div>
                                    })}
                                </div>
                                }
                            </div>
                        </div> ||
                        this.state.view === 'workers' &&
                        <Workers workers={this.state.workers} exportCSV={this.exportCSV} openReport={this.openReport} updateTime={this.updateTime} fetchEmployees={this.fetchEmployees} months={this.months} getEmployee={this.getEmployee} changeEmployee={this.changeEmployee} socket={this.socket} />
                        }
                    </div>
                    {this.state.historyModal &&
                    <div className="historymodal">
                        <div className="historymodal-content">
                            <span className="historymodal-close" onClick={this.toggleHistoryModal}>&times;</span>
                            <h1 className="historymodal-header">{this.state.restid[this.state.historyModal]?.restNr || this.state.alused[this.state.historyModal]?.restNr}</h1>
                            {(this.state.restid[this.state.historyModal]?.comment || this.state.alused[this.state.historyModal]?.comment) && <h1 className="historymodal-subheader">Tootja kommentaar: {this.state.restid[this.state.historyModal]?.comment || this.state.alused[this.state.historyModal]?.comment}</h1>}
                            <div className="history">
                                {((this.state.restid[this.state.historyModal]?.history || this.state.alused[this.state.historyModal]?.history) || []).map(item => {
                                    return <div className="history-row">
                                        <h1>{item.time}</h1>
                                        <h1>{item.driver?.name || item.worker?.name}</h1>
                                        <i className="fas fa-long-arrow-alt-right"/>
                                        <h1>{item.phase}{item.myykAmount ? ` (${item.myykAmount})` : ""}</h1>
                                        <h1>{item.location.mainzone} - {item.location.subzone}</h1>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>}
                    {this.state.modal.view === "report" &&
                    <div className="reportmodal">
                        <div className="reportmodal-content">
                            <span className="reportmodal-close" onClick={this.closeModal}>&times;</span>
                            <div className="modal-header">
                                <div className="datefilters">
                                    <h1>Alates</h1>
                                    <input type="date" id="from" onChange={this.onChange}/>
                                    <h1>Kuni</h1>
                                    <input type="date" id="to" onChange={this.onChange}/>
                                </div>
                                <div className="summary">
                                    <h1>Kokkuvõte:</h1>
                                    <div className="summary-last2">
                                        <p>Eelmine kuu</p>
                                        <p>{this.state.summary2 || "00:00:00"}</p>
                                    </div>
                                    <div className="summary-last">
                                        <p>See kuu</p>
                                        <p>{this.state.summary || "00:00:00"}</p>
                                    </div>
                                </div>
                                <a className="exportbutton" onClick={this.exportCSV}><i className="fas fa-file-export"></i></a>
                            </div>
                            <div className="report">
                                <div className="row row-sticky">
                                    <h1 className="name name-header">Päev</h1>
                                    <h1 className="duration duration-header">Tööl viibitud aeg</h1>
                                    <div className="times">
                                        <h1 className="timeheader">Sisse-välja registreerimise ajad</h1>
                                    </div>
                                </div>
                                {Array(((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).diff(moment(this.state.filters.from).subtract(1, 'day'), 'days') : 62).fill(0,0,((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).diff(moment(this.state.filters.from).subtract(1, 'day'), 'days') : 62).map((key, j) => {
                                    
                                    if ((this.state.filters.from && this.state.filters.to) && (this.state.filters.from > this.state.filters.to)) return
                                    if ((!this.state.filters.to || !this.state.filters.from) || this.state.filters.to < this.state.filters.from) {
                                        if (moment().subtract(j, 'days').startOf('month').format("YYYY-MM-DD") !== moment().startOf('month').format("YYYY-MM-DD") && moment().subtract(j, 'days').startOf('month').format("YYYY-MM-DD") !== moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD")) return
                                    }
                                    
                                    let day = ((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).subtract(j, 'days') : moment().subtract(j, 'days') 
                                    let times = this.state.workers[this.state.modal.id]?.logs?.[day.format("YYYY-MM-DD")]?.times
                                    let seconds = 0
                                    let minutes = 0
                                    let hours = 0

                                    let limited = []
                                    let timesArr = []

                                    if (times?.[0]?.split(":")[0] === "end") {
                                        times.forEach((key, i) => {
                                            if (i === 0) {
                                                seconds += moment(`${day.format("YYYY-MM-DD")} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                                                limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                                            }
                                            if (times[i*2+1]) {
                                                let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                if (times[i*2+2]) {
                                                    let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                    seconds += b.diff(a, 'seconds')
                                                    timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                    timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                                                } else {
                                                    let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                                    seconds += b.diff(a, 'seconds')
                                                    limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                }
                                            }
                                            if (i+1===times.length) {
                                                minutes = Math.floor(seconds / 60)
                                                seconds = seconds%60
                                                hours = Math.floor(minutes/60)
                                                minutes = minutes%60
                                            }
                                        })
                                    } else if (times?.[0]?.split(":")[0] === "start") {
                                        times.forEach((key, i) => {
                                            if (i === 0) {
                                                limited.push(0)
                                            }
                                            if (times[i*2]) {
                                                let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                if (times[i*2+1]) {
                                                    let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                    seconds += b.diff(a, 'seconds')
                                                    timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                                    timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                } else {
                                                    let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                                    seconds += b.diff(a, 'seconds')
                                                    limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                                }
                                            }
                                            if (i+1===times.length) {
                                                minutes = Math.floor(seconds / 60)
                                                seconds = seconds%60
                                                hours = Math.floor(minutes/60)
                                                minutes = minutes%60
                                            }
                                        })
                                    }
                                    
                                    return <div className="row" key={j}>
                                        <h1 className="name">{`${day.format("DD")}. ${this.months.est[day.format('M')-1]}`}</h1>
                                        <h1 className="duration">{`${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`}</h1>
                                        <div className="times">
                                            {!!limited[0] && <h1 className="time" date={day.format("YYYY-MM-DD")} to={0} onClick={this.openTime}>{`--:-- - ${limited[0]}`}</h1>}
                                            {timesArr.map((key, i) => {
                                                if (!timesArr[i*2]) return
                                                return <h1 className="time" key={i} date={day.format("YYYY-MM-DD")} from={limited[0] ? i*2+1 : i*2} to={limited[0] ? i*2+2 : i*2+1} onClick={this.openTime}>{`${timesArr[i*2]} - ${timesArr[i*2+1]}`}</h1>
                                            })}
                                            {!!limited[1] && <h1 className="time" date={day.format("YYYY-MM-DD")} from={times.length-1} onClick={this.openTime}>{`${limited[1]} - --:--`}</h1>}
                                            <i className="fas fa-plus" date={day.format("YYYY-MM-DD")} onClick={this.openTime}/>
                                        </div>
                                    </div>
                                })}
                            </div>
                            {/* <div className="backbutton" onClick={this.back}>
                                <i className="fas fa-arrow-left"/>
                                <h1>Tagasi</h1>
                            </div> */}
                        </div>
                        {this.state.modal.date &&
                        <div className="datemodal">
                            <div className="datemodal-content">
                                <span className="datemodal-close" modal="datemodal" onClick={this.closeModal}>&times;</span>
                                <div className="modal-header">
                                    <h1>Muuda aega</h1>
                                </div>
                                <div className="modal-content">
                                    <div className="date-edit-from">
                                        <h1>Algus</h1>
                                        <input type="date" id="startdate" defaultValue={this.state.modal.date} />
                                        <input type="time" id="starttime" defaultValue={`${this.state.workers[this.state.modal.id]?.logs?.[this.state.modal.date]?.times[this.state.modal.data?.from]?.split(":")[1]}:${this.state.workers[this.state.modal.id]?.logs?.[this.state.modal.date]?.times[this.state.modal.data?.from]?.split(":")[2]}`} />
                                    </div>
                                    <div className="date-edit-to">
                                        <h1>Lõpp</h1>
                                        <input type="date" id="enddate" defaultValue={this.state.modal.date} />
                                        <input type="time" id="endtime" defaultValue={`${this.state.workers[this.state.modal.id]?.logs?.[this.state.modal.date]?.times[this.state.modal.data?.to]?.split(":")[1]}:${this.state.workers[this.state.modal.id]?.logs?.[this.state.modal.date]?.times[this.state.modal.data?.to]?.split(":")[2]}`} />
                                    </div>
                                </div>
                                {(this.state.modal.data.from || this.state.modal.data.to) && <a className="button-delete" onClick={this.deleteTime}>Kustuta sissekanne</a>}
                                <a className="button-edit" onClick={this.saveTime}>Salvesta</a>
                            </div>
                        </div>}
                    </div>}
                    {this.state.deleteModal &&
                    <div className="deletemodal">
                        <div className="deletemodal-content">
                            <span className="deletemodal-close" onClick={this.closeDeleteModal}>&times;</span>
                            <h1 className="deletemodal-header">Kas oled kindel, et tahad {this.state.deleteModal.type === "yardstorage" ? "koorma" : this.state.deleteModal.type === "rest" ? "resti" : "aluse"} numbriga <span>{this.state.deleteModal.nr}</span> jäädavalt kustutada?</h1>
                            <a className="button-back" onClick={this.closeDeleteModal}>Tagasi</a>
                            {this.state.deltime ? <a className="button-delete">Kustuta<span>{this.state.deltime}</span></a> :
                            <a className="button-delete" onClick={this.deleteWares}>Kustuta</a>}
                        </div>
                    </div>
                    }
                    {this.state.view === "wares" && <div className="mobile-footer">
                        <a className={`footer-button${this.state.waresView === "yardstorage" ? " footer-button--active": ""}`} id="yardstorage" onClick={this.waresRoute}>Palgiladu</a>
                        <a className={`footer-button${this.state.waresView === "rest" ? " footer-button--active": ""}`} id="rest" onClick={this.waresRoute}>Restid</a>
                        <a className={`footer-button${this.state.waresView === "alus" ? " footer-button--active": ""}`} id="alus" onClick={this.waresRoute}>Alused</a>
                        <a className={`footer-button${this.state.waresView === "warehouse" ? " footer-button--active": ""}`} id="warehouse" onClick={this.waresRoute}>Põhiladu</a>
                        <a className={`footer-button${this.state.waresView === "stock" ? " footer-button--active": ""}`} id="stock" onClick={this.waresRoute}>Inventuur</a>
                        <a className={`footer-button${this.state.waresView === "haljala" ? " footer-button--active": ""}`} id="haljala" onClick={this.waresRoute}>Haljala</a>
                        {/* <a className={`footer-button${this.state.waresView === "tootmine" ? " footer-button--active": ""}`} id="tootmine" onClick={this.waresRoute}>Tootmine</a> */}
                        <a className={`footer-button${this.state.waresView === "myyk" ? " footer-button--active": ""}`} id="myyk" onClick={this.waresRoute}>Müük</a>
                        <a className={`footer-button${this.state.waresView === "finished" ? " footer-button--active": ""}`} id="finished" onClick={this.waresRoute}>Lõp. restid</a>
                    </div>}
                </div>
            </main>
        )
    }
}

export default Overview