import React from 'react'
import socketIOClient from 'socket.io-client'

class Settings extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.socket = socketIOClient(`${process.env.REACT_APP_SERVER_URL}/`, {
            withCredentials: true
        })

        this.state = {
            view: "general",
            wares: {
                artnr: "",
                artname: "",
                unit: "",
                type: "",
                toggles: [],
                yardstorage: {}
            },
            stock: {},
            settings: {},
            images: {},
            image: null,
            loading: false,
            modal: ""
        }
    }

    getStock = data => {
        if (data?.error || !data) return
        if (!data.length) {
            this.setState({stock: {}})
            return
        }
        data.forEach((item, i) => {
            if (i === 0) {
                this.setState({stock: {[item.article]: {name: item.name, count: item.count, unit: item.unit, type: item.type, convertible: item.convertible}}})
            } else {
                this.setState({stock: {...this.state.stock, [item.article]: {name: item.name, count: item.count, unit: item.unit, type: item.type, convertible: item.convertible}}})
            }
        })
    }

    changeStock = () => this.socket.emit("stockGet", {});
    done = () => this.setState({loading: false})

    componentDidMount() {
        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data})
        }).catch(err => {
            console.log(err)
        })

        fetch(`${process.env.REACT_APP_SERVER_URL}/image/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            let images = {}
            data.forEach(item => {
                images = {...images, [item._id]: {article: item.article, image: item.image}}
            })
            this.setState({images})
        }).catch(err => {
            console.log(err)
        })

        this.socket.emit("stockGet", {})
        this.socket.on("get_stock", this.getStock)
        this.socket.on("change_stock", this.changeStock);

        this.socket.on("done", this.done)
    }

    resetStock = e => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/stock/reset`, {
            credentials: 'include'
        }).catch(err => {
            console.log(err)
        })
    }

    componentWillUnmount() {
        this.socket.off("get_stock", this.getStock);
        this.socket.off("change_stock", this.changeStock);
        this.socket.off("done", this.done)
    }

    route = e => {
        e.preventDefault()

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, view: e.target.id, wares: {artnr: "", artname: "", unit: "", type: "", toggles: [], yardstorage: {}}})
        }).catch(err => {
            console.log(err)
        })
    }

    onChange = e => {
        e.preventDefault()
        this.setState({wares: {...this.state.wares, [e.target.id]: e.target.value}})
    }

    onChangeYardstorage = e => {
        e.preventDefault()
        this.setState({wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, [e.target.id]: e.target.value}}})
    }

    onChangeImage = event => {
        if (event.target.files && event.target.files[0]) {
          let img = event.target.files[0];
          this.setState({
            image: img
          });
        }
    };

    onChangeZoneTable = e => {
        e.preventDefault()
        this.setState({wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, zones: {...this.state.wares.yardstorage?.zones, [e.target.id]: e.target.value}}}})
    }

    onChangeLogTable = e => {
        e.preventDefault()
        this.setState({wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, logs: {...this.state.wares.yardstorage?.logs, [e.target.id]: e.target.value}}}})
    }

    onChangeSortimentTable = e => {
        e.preventDefault()
        this.setState({wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, sortiments: {...this.state.wares.yardstorage?.sortiments, [e.target.id]: e.target.value}}}})
    }

    toggleWares = e => {
        e.preventDefault()

        let toggles = this.state.wares.toggles
        if (toggles.includes(e.target.id)) {
            toggles.splice(toggles.indexOf(e.target.id), 1)
            this.setState({wares: e.target.id === "logs" ? {...this.state.wares, toggles, yardstorage: {...this.state.wares.yardstorage, logs: {}}} : e.target.id === "sortiment" ? {...this.state.wares, toggles, yardstorage: {...this.state.wares.yardstorage, sortiments: {}}} : {...this.state.wares, toggles}})
        } else {
            toggles.push(e.target.id)
            this.setState({wares: {...this.state.wares, toggles}})
        }
    }

    save = e => {
        e.preventDefault()

        if (this.state.view === "workers") {
            let prod1 = Number(document.getElementById("prod1").value)
            let prod2 = Number(document.getElementById("prod2").value)
            let proc1 = Number(document.getElementById("proc1").value)
            let proc2 = Number(document.getElementById("proc2").value)
            let fork1 = Number(document.getElementById("fork1").value)
            let fork2 = Number(document.getElementById("fork2").value)
            let night1 = Number(document.getElementById("night1").value)
            let night2 = Number(document.getElementById("night2").value)

            fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
                method: 'POST',
                body: JSON.stringify({
                    setting: "workers", settings: {prod1, prod2, proc1, proc2, fork1, fork2, night1, night2}
                }),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(res => {
                return res.json()
            }).then(data => {
                this.setState({settings: data})
            }).catch(err => {
                console.log(err)
            })
        }
    }

    addSender = e => { //alertid koigile siin
        e.preventDefault()

        let {sendername, sendercoef} = this.state.wares.yardstorage
        if (!sendername || !sendercoef) return

        sendercoef = sendercoef.replace(/,/g, '.')

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "senders", name: sendername, coef: sendercoef}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, sendername: '', sendercoef: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    removeSender = e => {
        e.preventDefault()

        if (!this.state.wares.yardstorage.senderselect) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresRemove", settings: {list: "senders", name: this.state.wares.yardstorage.senderselect}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, senderselect: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    addMaterial = e => {
        e.preventDefault()

        let {materialname, materialarticle} = this.state.wares.yardstorage
        if (!materialname || !materialarticle) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "materials", name: materialname, article: materialarticle}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, materialname: '', materialarticle: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    removeMaterial = e => {
        e.preventDefault()
        
        if (!this.state.wares.yardstorage.materialselect) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresRemove", settings: {list: "materials", name: this.state.wares.yardstorage.materialselect}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, materialselect: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    addChopper = e => {
        e.preventDefault()

        if (!this.state.wares.yardstorage.choppername) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "choppers", name: this.state.wares.yardstorage.choppername}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, choppername: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    removeChopper = e => {
        e.preventDefault()

        if (!this.state.wares.yardstorage.chopperselect) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresRemove", settings: {list: "choppers", name: this.state.wares.yardstorage.chopperselect}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, chopperselect: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    addZone = e => {
        e.preventDefault()

        if (!this.state.wares.yardstorage.zonename || this.state.wares.yardstorage.zonename === "Kuivati") return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "zones", name: this.state.wares.yardstorage.zonename}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, zonename: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    removeZone = e => {
        e.preventDefault()

        if (!this.state.wares.yardstorage.zoneselect) return
        
        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresRemove", settings: {list: "zones", name: this.state.wares.yardstorage.zoneselect}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, zoneselect: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    saveLocation = e => {
        e.preventDefault()

        let i = e.target.getAttribute("i")
        let zone = e.target.getAttribute("zone")
        let location = e.target.getAttribute("location")
        let newLocation = this.state.wares.yardstorage.zones[zone+location+i]
        if (!newLocation) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "locations", zone, location, newLocation}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, zones: {}}}})
        }).catch(err => {
            console.log(err)
        })
    }

    removeLocation = e => {
        e.preventDefault()

        let zone = e.target.getAttribute("zone")
        let location = e.target.getAttribute("location")
        
        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresRemove", settings: {list: "locations", zone, location}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, zones: {}}}})
        }).catch(err => {
            console.log(err)
        })
    }

    addChamber = e => {
        e.preventDefault()

        if (!this.state.wares.yardstorage.chambername) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "chambers", name: this.state.wares.yardstorage.chambername}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, chambername: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    removeChamber = e => {
        e.preventDefault()

        if (!this.state.wares.yardstorage.chamberselect) return
        
        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresRemove", settings: {list: "chambers", name: this.state.wares.yardstorage.chamberselect}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, chamberselect: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    addLog = e => {
        e.preventDefault()

        let {logname, logtm} = this.state.wares.yardstorage
        if (!logname || !logtm) return
        
        logtm = logtm.replace(/,/g, '.')

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "logs", name: logname, size: logtm}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, logname: '', logtm: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    removeLog = e => {
        e.preventDefault()

        if (!this.state.wares.yardstorage.logselect) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresRemove", settings: {list: "logs", name: this.state.wares.yardstorage.logselect}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, logselect: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    saveLog = e => {
        e.preventDefault()

        let log = e.target.getAttribute("log")
        let material = e.target.getAttribute("material")
        let logs = this.state.wares.yardstorage.logs

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "logsArticles", log, material, article: logs[log+material]}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            delete logs[log+material]
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, logs}}})
        }).catch(err => {
            console.log(err)
        })
    }

    addSortiment = e => {
        e.preventDefault()

        let {sortname, sortsize} = this.state.wares.yardstorage
        if (!sortname || !sortsize) return

        sortsize = sortsize.replace(/,/g, '.')

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "sortiment", name: sortname, size: sortsize}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, sortname: '', sortsize: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    removeSortiment = e => {
        e.preventDefault()

        if (!this.state.wares.yardstorage.sortselect) return
        
        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresRemove", settings: {list: "sortiment", name: this.state.wares.yardstorage.sortselect}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, sortselect: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    saveSortiment = e => {
        e.preventDefault()

        let material = e.target.getAttribute("material")
        let sortiment = e.target.getAttribute("sortiment")
        let sortiments = this.state.wares.yardstorage.sortiments

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "sortimentArticles", material, sortiment, article: sortiments[material+sortiment]}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            delete sortiments[material+sortiment]
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, sortiments}}})
        }).catch(err => {
            console.log(err)
        })
    }

    addHandcutArticle = e => {
        e.preventDefault()

        let {handcutname, handcutarticle} = this.state.wares.yardstorage
        if (!handcutname || !handcutarticle) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresAdd", settings: {list: "handcut", name: handcutname, article: handcutarticle}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, handcutname: '', handcutarticle: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    removeHandcutArticle = e => {
        e.preventDefault()
        
        if (!this.state.wares.yardstorage.handcutselect) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/save`, {
            method: 'POST',
            body: JSON.stringify({
                setting: "waresRemove", settings: {list: "handcut", name: this.state.wares.yardstorage.handcutselect}
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, handcutselect: ''}}})
        }).catch(err => {
            console.log(err)
        })
    }

    addArticle = e => {
        e.preventDefault()
        let {artnr, artname, type, unit} = this.state.wares
        if (!artnr || !artname || !type || !unit) return
        this.socket.emit("addarticle", {article: artnr, name: artname, type, unit})
        this.setState({wares: {...this.state.wares, artname: "", artnr: ""}})
    }

    toggleConvertible = e => {
        this.socket.emit("toggleconvertible", {article: e.target.id})
        this.setState({loading: true})
    }

    editArticle = e => {
        e.preventDefault()

        fetch(`${process.env.REACT_APP_SERVER_URL}/stock/save`, {
            method: 'POST',
            body: JSON.stringify({article: e.target.id, name: document.getElementsByClassName(e.target.id)[0].value}),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).catch(err => {
            console.log(err)
        })
    }

    removeArticle = e => {
        e.preventDefault()

        this.socket.emit("removearticle", {article: e.target.id})
    }

    addImage = e => {
        e.preventDefault()

        if (!this.state.wares.yardstorage.imgarticle || !this.state.image) return

        let base64String = ""
        let reader = new FileReader()
          
        reader.onload = () => {
            base64String = reader.result.replace("data:", "").replace(/^.+,/, "")

            fetch(`${process.env.REACT_APP_SERVER_URL}/image/save`, {
                method: 'POST',
                body: JSON.stringify({article: this.state.wares.yardstorage.imgarticle, image: base64String}),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(res => {
                return res.json()
            }).then(data => {
                this.setState({image: null, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, imgarticle: ""}}, images: {...this.state.images, [data._id]: {article: data.article, image: data.image}}})
            }).catch(err => {
                console.log(err)
            })
        }

        reader.readAsDataURL(this.state.image);
    }

    viewImage = e => {
        e.preventDefault()
        this.setState({modal: "previewImage", wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, imgselect: {article: this.state.images[e.target.id].article, _id: e.target.id}}}})
    }

    closeModal = e => {
        e.preventDefault()
        this.setState({modal: "", wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, imgselect: {}}}})
    }

    removeImage = e => {
        e.preventDefault()

        if (this.state.modal === "confirmDelete") {
            fetch(`${process.env.REACT_APP_SERVER_URL}/image/delete`, {
                method: 'POST',
                body: JSON.stringify({id: this.state.wares.yardstorage.imgselect}),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(res => {
                return res.json()
            }).then(data => {
                let images = this.state.images
                delete images[this.state.wares.yardstorage.imgselect]
                this.setState({modal: "", images, wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, imgselect: {}}}})
            }).catch(err => {
                console.log(err)
            })
        } else {
            this.setState({modal: "confirmDelete", wares: {...this.state.wares, yardstorage: {...this.state.wares.yardstorage, imgselect: {article: this.state.images[e.target.id].article, _id: e.target.id}}}})
        }
    }

    clean = e => {
        e.preventDefault()

        let id = e.target.id
        if (id === "submit") {
            let date = document.getElementById("cleandate").value
            this.setState({modal: date})
            return
        }
        if (id === "return") {
            this.setState({modal: ""})
            return
        }
        if (id !== "continue") return

        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/clean?date=${this.state.modal}`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            alert(data.error ? "Päring tagasi lükatud! Sinu kasutajal pole selleks luba!" : "Tehtud!")
            this.setState({modal: ""})
            return
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
        const view = this.state.view
        const settings = this.state.settings

        return (
            <main>
                <div className="settings">
                    <div className="settings-menu">
                        <h1>Kategooriad</h1>
                        <ul>
                            <li>
                                <a className={view === "general" ? "active" : ""} id="general" onClick={this.route}>Üldine</a>
                            </li>
                            {/* <li>
                                <a className={view === "management" ? "active" : ""} id="management" onClick={this.route}>Juhtkond</a>
                            </li>
                            <li>
                                <a className={view === "workers" ? "active" : ""} id="workers" onClick={this.route}>Töötajad</a>
                            </li> */}
                            <li>
                                <a className={view === "wares" ? "active" : ""} id="wares" onClick={this.route}>Ladu</a>
                            </li>
                            <li>
                                <a className={view === "clean" ? "active" : ""} id="clean" onClick={this.route}>Puhastus</a>
                            </li>
                        </ul>
                    </div>
                    <div className="settings-content">
                        {/* {view !== "wares" && <a className="savebutton" onClick={this.save}>Salvesta</a>} */}
                        {view === "general" &&
                        <div className="block">
                            <h1 className="block-header">Üldine</h1>
                            <div className="block-content">
                                Üldine
                                <button onClick={this.resetStock}>Ajutine lao reset (mitte vajutada)</button>
                            </div>
                        </div> ||
                        view === "management" &&
                        <div className="block">
                            <h1 className="block-header">Juhtkond</h1>
                            <div className="block-content">
                                Juhtkond
                            </div>
                        </div> ||
                        view === "workers" &&
                        <div className="block">
                            <h1 className="block-header">Töötajad</h1>
                            <div className="block-content">
                                <h1 className="subheader">Tööajad ja lubatud ajahälbed (tundides)</h1>
                                <div className="subcontent">
                                    <div className="worker">
                                        <h1></h1>
                                        <h1>Normaalne tööaeg:</h1>
                                        <h1>Hälbe piir:</h1>
                                    </div>
                                    <div className="worker">
                                        <h1>Tootja</h1>
                                        <div>
                                            <input type="number" defaultValue={settings.workers?.prod1} id="prod1"/>
                                        </div>
                                        <div>
                                            <input type="number" defaultValue={settings.workers?.prod2} id="prod2"/>
                                        </div>
                                    </div>
                                    <div className="worker">
                                        <h1>Töötleja</h1>
                                        <div>
                                            <input type="number" defaultValue={settings.workers?.proc1} id="proc1"/>
                                        </div>
                                        <div>
                                            <input type="number" defaultValue={settings.workers?.proc2} id="proc2"/>
                                        </div>
                                    </div>
                                    <div className="worker">
                                        <h1>Tõstukijuht</h1>
                                        <div>
                                            <input type="number" defaultValue={settings.workers?.fork1} id="fork1"/>
                                        </div>
                                        <div>
                                            <input type="number" defaultValue={settings.workers?.fork2} id="fork2"/>
                                        </div>
                                    </div>
                                    <div className="worker">
                                        <h1>Öövaht</h1>
                                        <div>
                                            <input type="number" defaultValue={settings.workers?.night1} id="night1"/>
                                        </div>
                                        <div>
                                            <input type="number" defaultValue={settings.workers?.night2} id="night2"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> ||
                        view === "wares" &&
                        <div className="block">
                            <h1 className="block-header">Ladu</h1>
                            <div className="block-content">
                                <h1 className="subheader">Palgilao haldus</h1>
                                <div className="subcontent">
                                    <div className="yardstorage-items">
                                        <a className={`item-header${this.state.wares.toggles.includes("senders") ? " item-header--active" : ""}`} id="senders" onClick={this.toggleWares}><i className="fas fa-angle-right"/> Saatjad</a>
                                        {this.state.wares.toggles.includes("senders") &&
                                        <div>
                                            <div className="yardstorage-item">
                                                <input className="sendername" type="text" id="sendername" value={this.state.wares.yardstorage["sendername"] || ""} placeholder="Saatja" onChange={this.onChangeYardstorage} />
                                                <input className="sendercoef" type="text" id="sendercoef" value={this.state.wares.yardstorage["sendercoef"] || ""} placeholder="Koef." onChange={this.onChangeYardstorage} />
                                                <a className="addnew" onClick={this.addSender}>Lisa <i className="fas fa-plus-circle"/></a>
                                            </div>
                                            <div className="yardstorage-item">
                                                <select id="senderselect" value={this.state.wares.yardstorage["senderselect"] || ""} onChange={this.onChangeYardstorage} >
                                                    <option value="">Lisatud saatjad</option>
                                                    {Object.keys(settings.wares?.senders || {}).map(key => {
                                                        return <option value={key} key={key}>{key} ({settings.wares?.senders[key]})</option>
                                                    })}
                                                </select>
                                                <a className="remove" onClick={this.removeSender}>Eemalda <i className="fas fa-minus-circle"/></a>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="yardstorage-items">
                                        <a className={`item-header${this.state.wares.toggles.includes("materials") ? " item-header--active" : ""}`} id="materials" onClick={this.toggleWares}><i className="fas fa-angle-right"/> Materjalid</a>
                                        {this.state.wares.toggles.includes("materials") &&
                                        <div>
                                            <div className="yardstorage-item">
                                                <input type="text" id="materialname" value={this.state.wares.yardstorage["materialname"] || ""} placeholder="Materjal" onChange={this.onChangeYardstorage} />
                                                <input type="text" id="materialarticle" value={this.state.wares.yardstorage["materialarticle"] || ""} placeholder="Tooriku artikkel" onChange={this.onChangeYardstorage} />
                                                <a className="addnew" onClick={this.addMaterial}>Lisa <i className="fas fa-plus-circle"/></a>
                                            </div>
                                            <div className="yardstorage-item">
                                                <select id="materialselect" value={this.state.wares.yardstorage["materialselect"] || ""} onChange={this.onChangeYardstorage} >
                                                    <option value="">Lisatud materjalid</option>
                                                    {(Object.keys(settings.wares?.materials || {})).map(key => {
                                                        return <option value={key} key={key}>{key} ({settings.wares?.materials[key]?.article})</option>
                                                    })}
                                                </select>
                                                <a className="remove" onClick={this.removeMaterial}>Eemalda <i className="fas fa-minus-circle"/></a>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="yardstorage-items">
                                        <a className={`item-header${this.state.wares.toggles.includes("choppers") ? " item-header--active" : ""}`} id="choppers" onClick={this.toggleWares}><i className="fas fa-angle-right"/> Lõhkujad</a>
                                        {this.state.wares.toggles.includes("choppers") &&
                                        <div className="yardstorage-item">
                                            <input type="text" id="choppername" value={this.state.wares.yardstorage["choppername"] || ""} placeholder="Lõhkuja" onChange={this.onChangeYardstorage} />
                                            <a className="addnew" onClick={this.addChopper}>Lisa <i className="fas fa-plus-circle"/></a>
                                            <select id="chopperselect" value={this.state.wares.yardstorage["chopperselect"] || ""} onChange={this.onChangeYardstorage} >
                                                <option value="">Lisatud lõhkujad</option>
                                                {(settings.wares?.choppers || []).map(key => {
                                                    return <option value={key} key={key}>{key}</option>
                                                })}
                                            </select>
                                            <a className="remove" onClick={this.removeChopper}>Eemalda <i className="fas fa-minus-circle"/></a>
                                        </div>}
                                    </div>
                                    <div className="yardstorage-items">
                                        <a className={`item-header${this.state.wares.toggles.includes("zones") ? " item-header--active" : ""}`} id="zones" onClick={this.toggleWares}><i className="fas fa-angle-right"/> Tsoonid</a>
                                        {this.state.wares.toggles.includes("zones") &&
                                        <div>
                                            <div className="yardstorage-item">
                                                <input type="text" id="zonename" value={this.state.wares.yardstorage["zonename"] || ""} placeholder="Tsoon" onChange={this.onChangeYardstorage} />
                                                <a className="addnew" onClick={this.addZone}>Lisa <i className="fas fa-plus-circle"/></a>
                                                <select id="zoneselect" value={this.state.wares.yardstorage["zoneselect"] || ""} onChange={this.onChangeYardstorage} >
                                                    <option value="">Lisatud tsoonid</option>
                                                    {Object.keys(settings.wares?.zones || {}).map(key => {
                                                        return <option value={key} key={key}>{key}</option>
                                                    })}
                                                </select>
                                                <a className="remove" onClick={this.removeZone}>Eemalda <i className="fas fa-minus-circle"/></a>
                                            </div>
                                            <div className="yardstorage-item">
                                                <input type="text" id="chambername" value={this.state.wares.yardstorage["chambername"] || ""} placeholder="Kuivati kamber" onChange={this.onChangeYardstorage} />
                                                <a className="addnew" onClick={this.addChamber}>Lisa <i className="fas fa-plus-circle"/></a>
                                                <select id="chamberselect" value={this.state.wares.yardstorage["chamberselect"] || ""} onChange={this.onChangeYardstorage} >
                                                    <option value="">Lisatud kambrid</option>
                                                    {(settings.wares?.chambers || []).map(key => {
                                                        return <option value={key} key={key}>{key}</option>
                                                    })}
                                                </select>
                                                <a className="remove" onClick={this.removeChamber}>Eemalda <i className="fas fa-minus-circle"/></a>
                                            </div>
                                            <div className="articles-content">
                                                <div className="articles-row articles-row-sticky">
                                                    <h1 className="row-log">Tsoon</h1>
                                                    <h1 className="row-log">Asukoht</h1>
                                                    <div className="row-remove1"><h1 className="header-icon">&#9998;</h1></div>
                                                    <div className="row-remove"><h1 className="header-icon">&times;</h1></div>
                                                </div>
                                                {Object.keys(settings.wares?.zones || {}).map(zone => {
                                                    return ([...settings.wares?.zones[zone], ""] || [""]).map((location, i) => {
                                                        return <div className="articles-row" key={zone+location+i}>
                                                            <h1 className="row-log">{i === 0 ? zone : ""}</h1>
                                                            <input className="row-input" id={zone+location+i} value={typeof this.state.wares?.yardstorage?.zones?.[zone+location+i] === "string" ? this.state.wares?.yardstorage?.zones?.[zone+location+i] : location} onChange={this.onChangeZoneTable} />
                                                            <div className="row-remove1">
                                                                {(typeof this.state.wares?.yardstorage?.zones?.[zone+location+i] === "string" && !!this.state.wares?.yardstorage?.zones?.[zone+location+i]) && <h1 i={i} zone={zone} location={location} onClick={this.saveLocation}>&#9998;</h1>}
                                                            </div>
                                                            <div className="row-remove">
                                                                {location && <h1 zone={zone} location={location} onClick={this.removeLocation}>&times;</h1>}
                                                            </div>
                                                        </div>
                                                    })
                                                })}
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="yardstorage-items">
                                        <a className={`item-header${this.state.wares.toggles.includes("logs") ? " item-header--active" : ""}`} id="logs" onClick={this.toggleWares}><i className="fas fa-angle-right"/> Palgid</a>
                                        {this.state.wares.toggles.includes("logs") &&
                                        <div>
                                            <div className="yardstorage-item">
                                                <input className="sendername" type="text" id="logname" value={this.state.wares.yardstorage["logname"] || ""} placeholder="Palk" onChange={this.onChangeYardstorage} />
                                                <input className="sendercoef" type="text" id="logtm" value={this.state.wares.yardstorage["logtm"] || ""} placeholder="TM" onChange={this.onChangeYardstorage} />
                                                <a className="addnew" onClick={this.addLog}>Lisa <i className="fas fa-plus-circle"/></a>
                                            </div>
                                            <div className="yardstorage-item">
                                                <select id="logselect" value={this.state.wares.yardstorage["logselect"] || ""} onChange={this.onChangeYardstorage} >
                                                    <option value="">Lisatud palgid</option>
                                                    {Object.keys(settings.wares?.logs || {}).map(key => {
                                                        return <option value={key} key={key}>{key} ({settings.wares?.logs?.[key]?.size}TM)</option>
                                                    })}
                                                </select>
                                                <a className="remove" onClick={this.removeLog}>Eemalda <i className="fas fa-minus-circle"/></a>
                                            </div>
                                            <div className="articles-content">
                                                <div className="articles-row articles-row-sticky">
                                                    <h1 className="row-log">Palk</h1>
                                                    <h1 className="row-log">Materjal</h1>
                                                    <h1 className="row-log">Tooriku artikkel</h1>
                                                    <div className="row-remove1" style={{pointerEvents: 'none'}}><h1>&#9998;</h1></div>
                                                </div>
                                                {Object.keys(settings.wares?.logs || {}).map(log => {
                                                    return Object.keys(settings.wares?.materials || {}).map((material, i) => {
                                                        return <div className="articles-row" key={log+material}>
                                                            <h1 className="row-log">{i === 0 ? log : ""}</h1>
                                                            <h1 className="row-log">{material}</h1>
                                                            <input className="row-input" id={log+material} value={(log+material) in (this.state.wares.yardstorage?.logs || {}) ? this.state.wares.yardstorage?.logs[log+material] : settings.wares?.logs?.[log]?.materials?.[material] ? settings.wares?.logs?.[log]?.materials?.[material] : ""} placeholder="Sisesta artikkel" onChange={this.onChangeLogTable}/>
                                                            <div className="row-remove1">
                                                                {(this.state.wares.yardstorage.logs?.[log+material] || (settings.wares?.logs?.[log]?.materials?.[material] && this.state.wares.yardstorage?.logs?.[log+material] === "")) && <h1 log={log} material={material} onClick={this.saveLog}>&#9998;</h1>}
                                                            </div>
                                                        </div>
                                                    })
                                                })}
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="yardstorage-items">
                                        <a className={`item-header${this.state.wares.toggles.includes("sortiment") ? " item-header--active" : ""}`} id="sortiment" onClick={this.toggleWares}><i className="fas fa-angle-right"/> Sortimendid</a>
                                        {this.state.wares.toggles.includes("sortiment") &&
                                        <div>
                                            <div className="yardstorage-item">
                                                <input type="text" id="sortname" value={this.state.wares.yardstorage["sortname"] || ""} placeholder="Sortiment" onChange={this.onChangeYardstorage} />
                                                <input type="text" id="sortsize" value={this.state.wares.yardstorage["sortsize"] || ""} placeholder="Resti kubatuur (RM)" onChange={this.onChangeYardstorage} />
                                                <a className="addnew" onClick={this.addSortiment}>Lisa <i className="fas fa-plus-circle"/></a>
                                            </div>
                                            <div className="yardstorage-item">
                                                <select id="sortselect" value={this.state.wares.yardstorage["sortselect"] || ""} onChange={this.onChangeYardstorage} >
                                                    <option value="">Lisatud sortimendid</option>
                                                    {Object.keys(settings.wares?.sortiment || {}).map(key => {
                                                        return <option value={key} key={key}>{key} ({settings.wares?.sortiment[key]}rm)</option>
                                                    })}
                                                </select>
                                                <a className="remove" onClick={this.removeSortiment}>Eemalda <i className="fas fa-minus-circle"/></a>
                                            </div>
                                            <div className="articles-content">
                                                <div className="articles-row articles-row-sticky">
                                                    <h1 className="row-log">Materjal</h1>
                                                    <h1 className="row-log">Sortiment</h1>
                                                    <h1 className="row-log">Artikkel</h1>
                                                    <div className="row-remove1" style={{pointerEvents: 'none'}}><h1>&#9998;</h1></div>
                                                </div>
                                                {Object.keys(settings.wares?.materials || {}).map(material => {
                                                    return Object.keys(settings.wares?.sortiment || {}).map((sortiment, i) => {
                                                        return <div className="articles-row" key={material+sortiment}>
                                                            <h1 className="row-log">{i === 0 ? material : ""}</h1>
                                                            <h1 className="row-log">{sortiment}</h1>
                                                            <input className="row-input" id={material+sortiment} value={(material+sortiment) in (this.state.wares.yardstorage?.sortiments || {}) ? this.state.wares.yardstorage?.sortiments[material+sortiment] : settings.wares?.materials?.[material]?.sortiments?.[sortiment] ? settings.wares?.materials?.[material]?.sortiments?.[sortiment] : ""} placeholder="Sisesta artikkel" onChange={this.onChangeSortimentTable}/>
                                                            <div className="row-remove1">
                                                                {(this.state.wares.yardstorage.sortiments?.[material+sortiment] || (settings.wares?.materials?.[material]?.sortiments?.[sortiment] && this.state.wares.yardstorage.sortiments?.[material+sortiment] === "")) && <h1 sortiment={sortiment} material={material} onClick={this.saveSortiment}>&#9998;</h1>}
                                                            </div>
                                                        </div>
                                                    })
                                                })}
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="yardstorage-items">
                                        <a className={`item-header${this.state.wares.toggles.includes("images") ? " item-header--active" : ""}`} id="images" onClick={this.toggleWares}><i className="fas fa-angle-right"/> Etiketi pildid</a>
                                        {this.state.wares.toggles.includes("images") &&
                                        <div>
                                            <div className="yardstorage-item">
                                                <input type="text" id="imgarticle" value={this.state.wares.yardstorage["imgarticle"] || ""} placeholder="Artikkel" onChange={this.onChangeYardstorage} />
                                                {/* <img src={this.state.image} /> */}
                                                <input type="file" onChange={this.onChangeImage} />
                                                <a className="addnew" onClick={this.addImage}>Lisa <i className="fas fa-plus-circle"/></a>
                                            </div>
                                            {/* <div className="yardstorage-item">
                                                <select id="imgselect" value={this.state.wares.yardstorage["imgselect"] || ""} onChange={this.onChangeYardstorage} >
                                                    <option value="">Lisatud pildid</option>
                                                    {Object.keys(this.state.images || {}).map(key => {
                                                        return <option value={key} key={key}>{this.state.images[key].article}</option>
                                                    })}
                                                </select>
                                                <a className="remove" onClick={this.removeImage}>Eemalda <i className="fas fa-minus-circle"/></a>
                                            </div> */}
                                            <div className="articles-content">
                                                {Object.keys(this.state.images || {}).map(key => {
                                                    // console.log(this.state.images[key].article, this.state.wares.yardstorage["imgarticle"])
                                                    if (this.state.wares.yardstorage["imgarticle"] && !this.state.images[key].article.toLowerCase().includes(this.state.wares.yardstorage["imgarticle"].toLowerCase())) return
                                                    return <div className="articles-row articles-row-images" key={key}>
                                                        <h1 className="row-image">{this.state.images[key].article}</h1>
                                                        <a className="image-view" id={key} onClick={this.viewImage}><i className="far fa-eye"/></a>
                                                        <a className="image-delete" id={key} onClick={this.removeImage}><i className="fas fa-trash-alt"/></a>
                                                    </div>
                                                })}
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="yardstorage-items">
                                        <a className={`item-header${this.state.wares.toggles.includes("handcut") ? " item-header--active" : ""}`} id="handcut" onClick={this.toggleWares}><i className="fas fa-angle-right"/> Käsilõigatud toote lõhkumise tulemid</a>
                                        {this.state.wares.toggles.includes("handcut") &&
                                        <div>
                                            <div className="yardstorage-item">
                                                <input type="text" id="handcutarticle" value={this.state.wares.yardstorage["handcutarticle"] || ""} placeholder="Artikkel" onChange={this.onChangeYardstorage} />
                                                <input type="text" id="handcutname" value={this.state.wares.yardstorage["handcutname"] || ""} placeholder="Lühike nimetus" onChange={this.onChangeYardstorage} />
                                                <a className="addnew" onClick={this.addHandcutArticle}>Lisa <i className="fas fa-plus-circle"/></a>
                                            </div>
                                            <div className="yardstorage-item">
                                                <select id="handcutselect" value={this.state.wares.yardstorage["handcutselect"] || ""} onChange={this.onChangeYardstorage} >
                                                    <option value="">Lisatud artiklid</option>
                                                    {Object.keys(settings.wares?.handcut || {}).map(key => {
                                                        return <option value={key} key={key}>{key} ({settings.wares?.handcut[key].article})</option>
                                                    })}
                                                </select>
                                                <a className="remove" onClick={this.removeHandcutArticle}>Eemalda <i className="fas fa-minus-circle"/></a>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <h1 className="subheader">Lao artiklite haldus</h1>
                                <div className="subcontent">
                                    <div className="new-article">
                                        <h1>Artikli nr:</h1>
                                        <input className="article-nr" type="text" id="artnr" value={this.state.wares.artnr} onChange={this.onChange} />
                                        <h1>Nimetus:</h1>
                                        <input className="article-name" type="text" id="artname" value={this.state.wares.artname} onChange={this.onChange} />
                                        <h1>Tüüp:</h1>
                                        <select className="article-type" id="type" value={this.state.wares.type} onChange={this.onChange}>
                                            <option value=""></option>
                                            <option value="Palgid">Palgid</option>
                                            <option value="Toore, märg">Toore, märg</option>
                                            <option value="Kuivatis">Kuivatis</option>
                                            <option value="Kuiv pakkimata">Kuiv pakkimata</option>
                                            <option value="Valmis toodang">Valmis toodang</option>
                                            <option value="Küttepuu">Küttepuu</option>
                                            <option value="Pakkevahendid">Pakkevahendid</option>
                                            <option value="Sildid">Sildid</option>
                                        </select>
                                        <h1>Ühik:</h1>
                                        <select className="article-unit" id="unit" value={this.state.wares.unit} onChange={this.onChange}>
                                            <option value=""></option>
                                            <option value="TK">TK</option>
                                            <option value="TM">TM</option>
                                            <option value="RM">RM</option>
                                            <option value="KG">KG</option>
                                        </select>
                                        <a className="addnew" onClick={this.addArticle}>Lisa <i className="fas fa-plus-circle"/></a>
                                    </div>
                                    <div className={`articles${this.state.wares.toggles.includes("articles") ? " articles--active" : ""}`}>
                                        <div className="articles-header">
                                            <a className={`toggle-articles${this.state.wares.toggles.includes("articles") ? " toggle-articles--active" : ""}`} id="articles" onClick={this.toggleWares}><i className="fas fa-angle-right"/> Artiklite list</a>
                                        </div>
                                        {this.state.wares.toggles.includes("articles") &&
                                        <div className="articles-content">
                                            <div className="articles-row articles-row-sticky">
                                                <h1 className="row-nr">Artikli nr</h1>
                                                <h1 className="row-name">Nimi</h1>
                                                <h1 className="row-unit">Ühik</h1>
                                                <h1 className="row-conv">C</h1>
                                                <h1 className="row-type">Tüüp</h1>
                                                <h1 className="row-remove"></h1>
                                            </div>
                                            {Object.keys(this.state.stock || {}).map(key => {
                                                let {name, unit, type, convertible} = this.state.stock[key]
                                                let {artnr, artname} = this.state.wares

                                                if (!key.toLowerCase().includes(artnr.toLowerCase()) || !name.toLowerCase().includes(artname.toLowerCase())) return
                                                if (this.state.wares.type && type !== this.state.wares.type) return

                                                return <div className="articles-row" key={key}>
                                                    <h1 className="row-nr">{key}</h1>
                                                    <div className="row-name">
                                                        <input defaultValue={name} className={key} />
                                                        <h1 id={key} onClick={this.editArticle}>&#9998;</h1>
                                                    </div>
                                                    <h1 className="row-unit">{unit}</h1>
                                                    <h1 className="row-conv"><input type="checkbox" id={key} checked={!!convertible} onChange={this.toggleConvertible} /></h1>
                                                    <h1 className="row-type">{type}</h1>
                                                    <div className="row-remove"><h1 id={key} onClick={this.removeArticle}>&times;</h1></div>
                                                </div>
                                            })}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            {this.state.loading && <div className="loader">
                                <div className="lds-ring"><div/><div/><div/><div/></div>
                            </div>}
                        </div> ||
                        view === "clean" &&
                        <div className="block">
                            <h1 className="block-header">Puhastus</h1>
                            <div className="block-content">
                                <input type="date" className="cleandate" id="cleandate" />
                                <a className="cleansubmit" id="submit" onClick={this.clean}>Puhasta</a>
                            </div>
                        </div>}
                    </div>
                    {this.state.modal === "confirmDelete" && <div className="modal">
                        <div className="modal-delete">
                            <h1>KAS OLED KINDEL?</h1>
                            <p>{this.state.stock[this.state.wares.yardstorage.imgselect.article]?.name || "Hoiatus: Artiklit ei leitud andmebaasist!"} - ({this.state.wares.yardstorage.imgselect.article})</p>
                            <a id="return" onClick={this.closeModal}>Tagasi</a>
                            <a id="continue" onClick={this.removeImage}>Kustutan</a>
                        </div>
                    </div> ||
                    this.state.modal === "previewImage" && <div className="modal">
                        <div className="modal-container-image">
                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                            <h1>{this.state.stock[this.state.wares.yardstorage.imgselect.article]?.name || "Hoiatus: Artiklit ei leitud andmebaasist!"} - ({this.state.wares.yardstorage.imgselect.article})</h1>
                            <div className="image-container">
                                <img src={`data:image/png;base64,${this.state.images[this.state.wares.yardstorage.imgselect._id].image}`}/>
                            </div>
                        </div>
                    </div> ||
                    this.state.modal && <div className="modal">
                        <div className="modal-container">
                            <h1>KAS OLED KINDEL, ET KUSTUTADA KÕIK ANDMED KUUPÄEVANI <span>{this.state.modal}</span>?</h1>
                            <a id="return" onClick={this.clean}>Tagasi</a>
                            <a id="continue" onClick={this.clean}>Kustutan</a>
                        </div>
                    </div>}
                </div>
            </main>
        )
    }
}

export default Settings