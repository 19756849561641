import React from 'react'
import moment from 'moment'
import socketIOClient from 'socket.io-client'

class Workspace extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.socket = socketIOClient(`${process.env.REACT_APP_SERVER_URL}/`, {
            withCredentials: true
        })

        this.state = {
            _id: "",
            cardID: "",
            employees: {},
            time: moment().format("HH:mm:ss"),
            notif: {},
            job: {},
            view: null,
            stock: {},
            modal: null,
            errorCode: 0,
            teams: {},
            team: {},
            allWorkers: [],
            search: "",
            finishJob: {
                productCount: {}
            }
        }
    }
    
    onCardInsert = e => {
        if (this.state.view === "job" || this.state.view === "finish" || this.state.view === "team") return
        const date = moment().format("YYYY-MM-DD")
        const time = moment().format("HH:mm:ss")
        if (e.key === "Enter") {
            this.socket.emit("enterWorkspace", {cardID: this.state.cardID, date, time})
            this.getNames()
        } else {
            this.setState({cardID: this.state.cardID + e.key})
        }
    }

    getTeam = data => {
        if (data?.error || !data) return
        data.forEach((team, i) => this.setState({teams: {...this.state.teams, [team._id]: {...team}}}))
    }

    // getEmployee = data => {
    //     if (data?.error || !data) return
    //     if (!data.length) {
    //         this.setState({employees: {}})
    //         return
    //     }
    //     data.forEach((item, i) => {
    //         if (i === 0) {
    //             this.setState({employees: {[item._id]: item.firstName + " " + item.lastName}})
    //         } else {
    //             this.setState({employees: {...this.state.employees, [item._id]: item.firstName + " " + item.lastName}})
    //         }
    //         if (i+1 === data.length) {
    //             this.socket.on("get_employee", this.getEmployee)
    //         }
    //     })
    // }

    getStock = data => {
        if (data?.error || !data) return
        if (!data.length) {
            this.setState({stock: {}})
            return
        }
        data.forEach((item, i) => {
            if (i === 0) {
                this.setState({stock: {[item.article]: {name: item.name, count: item.count, unit: item.unit, type: item.type}}})
            } else {
                this.setState({stock: {...this.state.stock, [item.article]: {name: item.name, count: item.count, unit: item.unit, type: item.type}}})
            }
            if (i+1 === data.length) {
                this.socket.off("get_stock", this.getStock)
            }
        })
    }

    changeWorkspace = data => {
        if (data.cardID !== this.state.cardID) return
        
        if (data.error) this.setState({errorCode: data.code, view: "error"})
        else if (data.workers) this.setState({team: {name: data.team, workers: data.workers}, allWorkers: data.allWorkers, view: "team"})
        else {
            let teamName = data.team
            console.log(teamName)
            data = {...data.data, ongoing: !!data.ongoing}
            fetch(`${process.env.REACT_APP_SERVER_URL}/image/getone?article=${data.silt[0]}`, {
                credentials: 'include'
            }).then(res => {
                return res.json()
            }).then(image => {
                // let img = new Image()
                image = 'data:image/png;base64,' + image.image
                this.setState({job: {...data, image}, view: "job", team: {name: teamName, workers: {}}})
            }).catch(err => {
                console.log(err)
            })
        }
    }

    changeJob = data => {
        if (data.cardID !== this.state.cardID) return
        if (this.state.job.type === "production" && this.state.job.ongoing) {
            let q = `
                ^XA
                ^FO75,25^AVN,420,300^FD${data.restNr}
                ^FS
                ^XZ`
            this.zebraPrinter.send(q, undefined, err => console.log(err))
            this.zebraPrinter.send(q, undefined, err => console.log(err))
            q = `
                ^XA
                ^FO75,50^BY6,3,10
                ^B3N,N,250,Y,N^FD${data.restNr}^FS
                ^XZ`
            this.zebraPrinter.send(q, undefined, err => console.log(err))
            this.zebraPrinter.send(q, undefined, err => console.log(err))
        }
        this.setState({cardID: "", job: {}, view: null, team: {}, finishJob: {productCount: {}}})
    }

    getNames = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/employee/names`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            let employees = {}
            data.forEach((worker, i) => {
                employees = {...employees, [worker._id]: worker.firstName + " " + worker.lastName}
                if (i+1 === data.length) {
                    this.setState({employees})
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    componentDidMount() {
        window.removeEventListener('keydown', this.onCardInsert)
        window.addEventListener('keydown', this.onCardInsert)

        window.BrowserPrint.getDefaultDevice("printer", device => {
            this.zebraPrinter = new window.Zebra.Printer(device)
        }, error => {console.log(error)})

        this.socket.emit("employeeGet", {start: moment().format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD")})
        this.socket.emit("stockGet", {})
        // this.socket.on("get_employee", this.getEmployee)
        this.socket.emit("teamGet")
        this.socket.on("get_team", this.getTeam)
        this.socket.on("get_stock", this.getStock)
        this.socket.on("change_workspace", this.changeWorkspace)
        this.socket.on("change_job", this.changeJob)
        this.socket.on("print", this.print)

        this.getNames()
    
        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(settings => {
            this.setState({settings})
        }).catch(err => {
            console.log(err)
        })
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onCardInsert)
        this.socket.off("change_workspace", this.changeWorkspace)
        // this.socket.off("get_employee", this.getEmployee)
        this.socket.off("get_stock", this.getStock)
        this.socket.off("change_job", this.changeJob)
        this.socket.off("get_team", this.getTeam)
        this.socket.off("print", this.print)
    }

    accept = e => {
        e.preventDefault()
        this.socket.emit("start_job", {cardID: this.state.cardID, job: this.state.job._id, isTeam: this.state.team.name ? true : false})
    }

    toggleModal = e => {
        e.preventDefault()

        if (e.target.className === "decline-job") this.setState({modal: "decline"})
        else if (e.target.className === "cancel-job") this.setState({modal: "cancel"})
        else if (e.target.className === "accept-job") {
            if (this.state.job.type === "production") this.setState({modal: null, view: "finish"})
            else this.setState({modal: "finish"})
        }
    }

    confirmation = e => {
        e.preventDefault()
        
        if (this.state.modal === "decline") {
            if (e.target.className === "no") this.setState({modal: null})
            else if (e.target.className === "yes") this.setState({modal: null, cardID: "", job: {}, view: null, team: {}})
        } else if (this.state.modal === "finish") {
            if (e.target.className === "no") this.setState({modal: null})
            else if (e.target.className === "yes") this.setState({modal: null, view: "finish"})
        } else if (this.state.modal === "cancel") {
            if (e.target.className === "no") this.setState({modal: null})
            else if (e.target.className === "yes") {
                this.socket.emit("cancel_job", {cardID: this.state.cardID, job: this.state.job._id, isTeam: this.state.team.name ? true : false})
                this.setState({modal: null, cardID: "", job: {}, view: null, team: {}})
            }
        }
    }

    exitError = e => {
        e.preventDefault()
        this.setState({cardID: "", view: null, errorCode: 0, team: {}})
    }

    finish = e => {
        e.preventDefault()
        if (this.state.job.type === "processor") this.socket.emit("finish_job", {finishJob: this.state.finishJob, jobID: this.state.job._id, cardID: this.state.cardID})
        if (this.state.job.type === "production") this.socket.emit("finish_job", {finishJob: this.state.finishJob, jobID: this.state.job._id, cardID: this.state.cardID, isTeam: this.state.team.name ? true : false})
    }

    continueJob = e => {
        e.preventDefault()
        this.setState({modal: null, cardID: "", job: {}, view: null, team: {}})
    }

    back = e => {
        e.preventDefault()
        this.setState({view: "job"})
    }

    onChangeFinishJob = e => {
        e.preventDefault()

        if (e.target.className === "hourly") {
            let hours = e.target.value.replace(/,/g, '.')
            this.setState({finishJob: {...this.state.finishJob, hours}})
        }
        else if (e.target.className === "comment") this.setState({finishJob: {...this.state.finishJob, comment: e.target.value}})
        else if (e.target.className === "restcomment") this.setState({finishJob: {...this.state.finishJob, restComment: e.target.value}})
        else {
            let value = e.target.value.replace(/,/g, '.')
            this.setState({finishJob: {...this.state.finishJob, productCount: {...this.state.finishJob.productCount, [e.target.id]: value}}})
        }
    }

    onChange = e => {
        e.preventDefault()
        this.setState({search: e.target.value})
    }

    addToTeam = e => {
        e.preventDefault()
        this.setState({team: {...this.state.team, workers: [...this.state.team.workers, e.target.id]}})
    }

    removeFromTeam = e => {
        e.preventDefault()
        let workers = this.state.team.workers.filter(x => x !== e.target.id)
        this.setState({team: {...this.state.team, workers}})
    }

    confirmTeam = e => {
        e.preventDefault()
        const date = moment().format("YYYY-MM-DD")
        const time = moment().format("HH:mm:ss")
        this.socket.emit("enterWorkspace", {cardID: this.state.cardID, date, time, team: this.state.team.workers})
    }

    render() {
        let {workers, silt, pakend, alus, poster, pm, type, duedate, product, per, progress, comments, ongoing, tas} = this.state.job
        let {modal, employees, view, stock, errorCode} = this.state
        return (
            <div className="workspace">
                {view === "job" && <div className="content">
                    <h1 className="heading">{ongoing ? "Käesolev töö" : "Sinu töö"}</h1>
                    {type === "processor" && <div className="job-content">
                        <h1 className="job-heading">Vajadusel kirjuta vajalik informatsioon üles enne kui jätkad!</h1>
                        <div className="job-content-wrapper">
                            <div className="job-content-column">
                                <h1 className="job-item">Tooted</h1>
                                {Object.keys(product || {}).map(key => {
                                    if (product[key] === "side") return
                                    return <p key={key}><span>{progress?.ovr?.[key] ? product[key]-progress.ovr[key] : product[key]}x</span> {stock[key].name}</p>
                                })}
                                <h1 className="job-item">Kõrvaltooted</h1>
                                {Object.keys(product || {}).map(key => {
                                    if (product[key] !== "side") return
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                            </div>
                            <div className="job-content-column">
                                <h1 className="job-item">Tähtaeg</h1>
                                <p>{moment(duedate, "YYYY-MM-DD").format("DD/MM/YYYY")}</p>
                                <h1 className="job-item">Alus</h1>
                                {(alus || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                                <h1 className="job-item">Pakkematerjal</h1>
                                {(pm || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                            </div>
                            <div className="job-content-column">
                                <h1 className="job-item">Töötajad</h1>
                                {(workers || []).map(key => {
                                    return <p key={key}>{employees[key]}</p>
                                })}
                                <h1 className="job-item">Tasustamine</h1>
                                <p>{tas === "0" ? "Tunnitöö" : tas === "1" ? "Tükitöö" : ""}</p>
                                <h1 className="job-item">Kommentaarid</h1>
                                <p>{comments}</p>
                            </div>
                        </div>
                    </div> ||
                    type === "production" &&
                    <div className="job-content1">
                        <h1 className="job-heading">Vajadusel kirjuta vajalik informatsioon üles enne kui jätkad!</h1>
                        <div className="job-content-wrapper">
                            <div className="job-content1-column">
                                <h1 className="job-item">Tooted</h1>
                                    {Object.keys(product || {}).map(key => {
                                        if (product[key] === "side") return
                                        return <p key={key}><span>{per}x</span> {stock[key].name}</p>
                                    })}
                                <h1 className="job-item">Tähtaeg</h1>
                                <p>{moment(duedate, "YYYY-MM-DD").format("DD/MM/YYYY")}</p>
                                <h1 className="job-item">Tasustamine</h1>
                                <p>{tas === "0" ? "Tunnitöö" : tas === "1" ? "Tükitöö" : ""}</p>
                                <h1 className="job-item">Kommentaarid</h1>
                                <p>{comments}</p>
                            </div>
                            <div className="job-content1-column">
                                <h1 className="job-item">Silt</h1>
                                {(silt || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                                <h1 className="job-item">Pakend</h1>
                                {(pakend || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                                <h1 className="job-item">Alus</h1>
                                {(alus || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                                <h1 className="job-item">Poster</h1>
                                {(poster || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                                <h1 className="job-item">Pakkematerjal</h1>
                                {(pm || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                            </div>
                        </div>
                        <div className="image-wrapper">
                            <img src={this.state.job.image} />
                        </div>
                    </div>}
                    <div className="buttons">
                        <div className="accept-job" onClick={ongoing ? this.toggleModal : this.accept}>
                            <i className="fas fa-arrow-right"/>
                            <h1>{ongoing ? "Lõpetan töö" : "Sain aru ja võtan töö"}</h1>
                        </div>
                        {(ongoing && this.state.job.type === "production") && <div className="cancel-job" onClick={this.toggleModal}>
                            <i className="fas fa-times"/>
                            <h1>Katkestan töö</h1>
                        </div>}
                        <div className="decline-job" onClick={ongoing ? this.continueJob : this.toggleModal}>
                            <i className="fas fa-arrow-left"/>
                            <h1>{ongoing ? "Jätkan tööd" : "Väljun"}</h1>
                        </div>
                    </div>
                    {modal && <div className="confirm-modal">
                        <div className="confirm-modal-container">
                            <h1 className="text">{modal === "decline" ? "Kas väljute tööd võtmata?" : modal === "finish" ? "Kas olid masinajuht?" : modal === "cancel" ? "Kas soovid töö katkestada?" : ""}</h1>
                            <div className="confirmation-buttons">
                                <div className="yes" onClick={this.confirmation}>
                                    <h1>Jah</h1>
                                </div>
                                <div className="no" onClick={this.confirmation}>
                                    <h1>Ei</h1>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div> ||
                view === "finish" && <div className="finish">
                    <h1 className="heading">Tehtud töö kokkuvõte</h1>
                    <div className="finish-content">
                        {type === "processor" ? <>
                        <h1 className="finish-heading">Märgi üles kui palju viimasel töösessioonil tegid.</h1>
                        <div className="right">
                            <h1 className="block-heading">Tunnitöö:</h1>
                            <p>Mitu tundi?</p>
                            <input className="hourly" type="number" onChange={this.onChangeFinishJob} />
                            <p>Lisa kommentaar tunnitöö kohta</p>
                            <textarea className="comment" onChange={this.onChangeFinishJob} />
                        </div>
                        <div className="left">
                            <h1 className="block-heading">Tooted:</h1>
                            {Object.keys(product).map(key => {
                                return <span className="product" key={key}>
                                    <p>{stock[key].name}</p>
                                    <input id={key} type="number" onChange={this.onChangeFinishJob} />
                                    <p>{product[key] === "side" ? "" : `/${Number(product[key])-Number(progress.ovr?.[key] || 0)}`}</p>
                                </span>
                            })}
                        </div>
                        </> : <>
                        <div className="right">
                            <h1 className="block-heading">Tunnitöö:</h1>
                            <p>Mitu tundi?</p>
                            <input className="hourly" type="number" onChange={this.onChangeFinishJob} />
                            <p>Lisa kommentaar tunnitöö kohta</p>
                            <textarea className="comment" onChange={this.onChangeFinishJob} />
                        </div>
                        <div className="left">
                            <h1 className="block-heading">Märkused aluse kohta:</h1>
                            <textarea className="restcomment" onChange={this.onChangeFinishJob} />
                        </div>
                        </>}
                    </div>
                    <div className="backbutton" onClick={this.back}>
                        <i className="fas fa-arrow-left"/>
                    </div>
                    <div className="finish-job" onClick={this.finish}>
                        <i className="fas fa-arrow-right"/>
                        <h1>Lõpetan töö</h1>
                    </div>
                </div> ||
                view === "team" && <div className="team">
                    <h1 className="team-heading">{this.state.team.name}</h1>
                    <div className="team-workers">
                        <h1 className="subheading">Liikmed</h1>
                        <div className="worker-list">
                            {this.state.team.workers.map(worker => {
                                return <div key={worker}>
                                    <span>{this.state.employees[worker]}</span>
                                    <a id={worker} onClick={this.removeFromTeam}>&times;</a>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="all-workers">
                        <input value={this.state.search} onChange={this.onChange}/>
                        <div>
                            {this.state.allWorkers.map(worker => {
                                if (this.state.team.workers.includes(worker)) return
                                let name = this.state.employees[worker]
                                if (this.state.search && !name.toLowerCase().includes(this.state.search.toLowerCase())) return
                                return <span key={worker} id={worker} onClick={this.addToTeam}>{name}</span>
                            })}
                        </div>
                    </div>
                    <div className="buttons">
                        <div className="continue" onClick={this.confirmTeam}>
                            <i className="fas fa-arrow-right"/>
                            <h1>Kinnitan meeskonna</h1>
                        </div>
                        <div className="exit" onClick={this.continueJob}>
                            <i className="fas fa-arrow-left"/>
                            <h1>Väljun</h1>
                        </div>
                    </div>
                </div> ||
                view === "error" && <div className="error">
                    <h1 className="error-message">{errorCode === 1 ? "Süsteemiviga! Teata sellest juhtkonnale." : errorCode === 2 ? "Kiipi ei leitud! Teata sellest juhtkonnale." : errorCode === 3 ? "Kehtetu kiip!" : errorCode === 4 ? "Te pole veel ennast sisse registreerinud." : errorCode === 5 ? "Ei leidnud sulle tööd. Teata sellest juhtkonnale." : errorCode === 6 ? "Jätsid meeskonna tühjaks! Kasuta + nuppu, et töötajaid meeskonda lisada." : "Süsteemiviga! [kood: 42]"}</h1>
                    <div className="backbutton" onClick={this.exitError}>
                        <i className="fas fa-arrow-left"/>
                        <h1>Väljun</h1>
                    </div>
                </div>
                ||
                <div className="default">

                </div>}
            </div>
        )
    }
}

export default Workspace