import React from 'react'
import socketIOClient from 'socket.io-client'
import moment from 'moment'
import {history} from '../../routers/AppRouter'

class Wares extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.socket = socketIOClient(`${process.env.REACT_APP_SERVER_URL}/`, {
            withCredentials: true
        })

        this.state = {
            view: "yardStorage",
            filters: {
                active: false
            },
            modal: "",
            confirmFinish: "",
            _id: "",
            data: {},
            jobs: {},
            shipments: {},
            modalData: {},
            settings: {},
            dimensions: {},
            newAlus: {},
            sum: 0,
            senderToggle: false,
            restToggle: false,
            menuToggle: false,
            kooremToggle: true,
            page: 1,
            toggledDates: [],
            scan: {toggled: false},
            loading: false,
            latestSE: {
                start: moment().startOf("month").format("YYYY-MM-DD"),
                end: moment().endOf("month").format("YYYY-MM-DD")
            },
            toggledAlused: [],
            finished: {},
            warehouseUnits: false
        }

        this.lastTimeout = 0
    }
    
    onScan = e => {
        // if (e.key === "Enter") {
        //     fetch(`${process.env.REACT_APP_SERVER_URL}/wares/scanrest?restnr=${this.state.scan.restNr}`, {
        //         credentials: 'include'
        //     }).then(res => {
        //         return res.json()
        //     }).then(data => {
        //         if (data.restNr) this.setState({scan: {...this.state.scan, ...data, active: true}})
        //         else this.setState({scan: {...this.state.scan, modal: "notfound"}})
        //         window.removeEventListener('keydown', this.onScan)
        //     }).catch(err => {
        //         console.log(err)
        //     })
        // } else {
        //     this.setState({scan: {...this.state.scan, restNr: this.state.scan.restNr + e.key}})
        // }

        if (Number(e.key) >= 0 && Number(e.key) <= 9) {
            clearTimeout(this.lastTimeout)
            this.setState({scan: {...this.state.scan, restNr: this.state.scan.restNr + e.key}})
        }

        this.lastTimeout = setTimeout(() => {
            fetch(`${process.env.REACT_APP_SERVER_URL}/wares/scanrest?restnr=${this.state.scan.restNr}`, {
                credentials: 'include'
            }).then(res => {
                return res.json()
            }).then(data => {
                if (data.restNr) this.setState({scan: {...this.state.scan, ...data, active: true}})
                else this.setState({scan: {...this.state.scan, modal: "notfound"}})
                window.removeEventListener('keydown', this.onScan)
            }).catch(err => {
                console.log(err)
            })
        }, 50)
    }
    
    onScanShip = e => {
        if (this.state.modalData.modal) return
        if (Number(e.key) >= 0 && Number(e.key) <= 9) {
            clearTimeout(this.lastTimeout)
            this.setState({scan: {...this.state.scan, restNr: this.state.scan.restNr + e.key}})
        }

        this.lastTimeout = setTimeout(() => {
            this.setState({scan: {toggled: false, restNr: ""}, modalData: {...this.state.modalData, modal: "add", unit: this.state.scan.restNr}})
        }, 50)
    }

    openScan = e => {
        e.preventDefault()
        this.setState({scan: {toggled: true, restNr: ""}})

        window.removeEventListener('keydown', this.onScan)
        window.addEventListener('keydown', this.onScan)
    }

    closeScan = e => {
        e.preventDefault()
        this.setState({scan: {toggled: false}})

        window.removeEventListener('keydown', this.onScan)
    }

    scanModal = e => {
        e.preventDefault()
        let scan = this.state.scan
        if (scan.modal === "notfound") {
            if (e.target.id === "yes") {
                this.setState({scan: {...this.state.scan, active: true, modal: null}})
            } else if (e.target.id === "no") {
                this.setState({scan: {toggled: true, restNr: ""}})
                window.addEventListener('keydown', this.onScan)
            }
        } else if (scan.modal === "generated") {
            this.setState({scan: {toggled: true, restNr: ""}})
            window.addEventListener('keydown', this.onScan)
        }
    }

    toggleManualScan = e => {
        e.preventDefault()
        if (this.state.scan.manual) window.addEventListener('keydown', this.onScan)
        else window.removeEventListener('keydown', this.onScan)

        this.setState({scan: {...this.state.scan, manual: !this.state.scan.manual}})
    }

    submitScan = e => {
        e.preventDefault()
        let restNr = document.getElementById("scan-input").value
        if (!restNr) return
        
        fetch(`${process.env.REACT_APP_SERVER_URL}/wares/scanrest?restnr=${restNr}`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            if (data.restNr) {
                let type = restNr.length === 4 ? "rest" : "alus"
                this.setState({scan: {...this.state.scan, restNr, ...data, active: true, type}})
            }
            else this.setState({scan: {...this.state.scan, restNr, modal: "notfound"}})
            window.removeEventListener('keydown', this.onScan)
        }).catch(err => {
            console.log(err)
        })
    }

    newAlus = e => {
        e.preventDefault()
        this.setState({scan: {...this.state.scan, type: "alus", restNr: "Uus alus", active: true}})
        window.removeEventListener('keydown', this.onScan)
    }

    onChangeScan = e => {
        if (e.target.id === "fsc") {
            this.setState({scan: {...this.state.scan, [e.target.id]: e.target.checked}})
            return
        }
        e.preventDefault()
        if (e.target.id.includes("zone")) {
            this.setState({scan: {...this.state.scan, location: {...this.state.scan?.location, [e.target.id]: e.target.value}}})
            return
        }
        this.setState({scan: {...this.state.scan, [e.target.id]: e.target.value}})
    }

    toggleProductSearch = e => {
        e.preventDefault()
        this.setState({scan: {...this.state.scan, searchToggle: !this.state.scan.searchToggle}})
    }

    selectProduct = e => {
        e.preventDefault()
        this.setState({scan: {...this.state.scan, searchToggle: false, article: e.target.id, name: this.state.stock[e.target.id]?.name}})
    }

    getRestid = data => {
        if (data?.error || !data) return
        this.setState({restid: {}})
        let restid = {}
        data.forEach((item, i) => {
            // console.log(item)
            // if (item.finished) {
            //     if (i+1===data.length) {
            //         if (this.state.modal) this.setState({restid, modalData: this.state.data[this.state._id]})
            //         else this.setState({restid})
            //     } else return
            // }
            restid = {...restid, [item._id]: item}

            if (i+1===data.length) {
                if (this.state.modal) this.setState({restid, modalData: this.state.data[this.state._id]})
                else this.setState({restid})
            }
        })
    }

    getAlused = data => {
        if (data?.error || !data) return
        this.setState({alused: {}})
        let alused = {}
        data.forEach((item, i) => {
            alused = {...alused, [item._id]: item}
            if (i+1===data.length) {
                if (this.state.modal) this.setState({alused, modalData: this.state.data[this.state._id]})
                else this.setState({alused})
            }
        })
    }

    getYardstorage = data => {
        if (data?.error || !data) return
        this.setState({data: {}})
        data.forEach(item => {
            if (item.finished) {
                this.setState({finished: {...this.state.finished, [item._id]: item.koorem}})
            } else {
                let maht = Number(item.received) - Number(item.used || 0)
                Object.keys(item.logs || {'':''}).forEach((key, i) => {
                    if (item.logs) maht -= item.logs[key] * this.state.settings?.wares?.logs?.[key]?.size || 0

                    if (i+1 === Object.keys(item.logs || {'':''}).length) {
                        this.setState({data: {...this.state.data, [item._id]: {...item, maht}}})
                    }
                })
            }
        })
    }

    getShipments = data => {
        if (data?.error || !data) return
        this.setState({shipments: {}})
        let shipments = {}
        data.forEach((item, i) => {
            shipments = {...shipments, [item._id]: item}
            if (i+1===data.length) {
                if (this.state.modal) this.setState({shipments})
                else this.setState({shipments})
            }
        })
    }

    fetchStock = (start, end) => {
        this.setState({latestSE: {start, end}})
        this.socket.emit("stockGet", {start, end})
    }

    getStock = data => {
        if (data?.error || !data) return
        let stock = {}
        data.forEach((item, i) => {
            stock = {...stock, [item.article]: {name: item.name, count: item.count, unit: item.unit, type: item.type, log: item.log, haljala: item.haljala, myyk: item.myyk, convertible: item.convertible, fsc: item.fsc}}
        })
        this.setState({stock})
    }

    changeRestid = () => this.socket.emit("restidGet")
    changeAlused = () => this.socket.emit("alusedGet")
    changeYardstorage = () => this.socket.emit("yardstorageGet")
    changeStock = () => this.socket.emit("stockGet", {start: this.state.latestSE.start, end: this.state.latestSE.end})
    changeShipments = () => this.socket.emit("shipmentsGet")

    done = data => {
        if (this.state.scan.restNr === "Uus alus") {
            this.setState({scan: {...this.state.scan, restNr: data.restNr, modal: "generated"}, loading: false})

            let q = ''
            if (data.restNr.toString().length > 4) {
                q = `^XA
                    ^FO75,25^AVN,420,300^FD${data.restNr}
                    ^FS
                    ^XZ`
            } else {
                q = `^XA
                    ^FO75,25^AVN,420,350^FD${data.restNr}
                    ^FS
                    ^XZ`
            }

            this.zebraPrinter.send(q, undefined, err => console.log(err))
            this.zebraPrinter.send(q, undefined, err => console.log(err))
        }
        else this.setState({scan: {toggled: true, restNr: ""}, loading: false})
    }

    printNumber = e => {
        e.preventDefault()

        if (this.state.modal === "print") {
            let type = e.target.id
            if (type === "return") {
                this.setState({modal: ""})
                return
            }
            
            let restNr = this.state.scan.restNr
            let q = ''
            
            if (this.state.scan.restNr.toString().length > 4) {
                q = type === "num" ? `^XA
                    ^FO75,25^AVN,420,300^FD${restNr}
                    ^FS
                    ^XZ"` : `^XA
                    ^FO75,50^BY6,3,10
                    ^B3N,N,250,Y,N^FD${restNr}^FS
                    ^XZ`
            } else {
                q = type === "num" ? `^XA
                    ^FO75,25^AVN,420,350^FD${restNr}
                    ^FS
                    ^XZ"` : `^XA
                    ^FO125,50^BY6,3,10
                    ^B3N,N,250,Y,N^FD${restNr}^FS
                    ^XZ`
            }

            this.zebraPrinter.send(q, undefined, err => console.log(err))
            this.setState({modal: ""})
        } else {
            this.setState({modal: "print"})
        }
    }

    getTeam = data => {
        if (data?.error || !data) return
        data.forEach((team, i) => this.setState({teams: {...this.state.teams, [team._id]: {...team}}}))
    }

    getEmployeeNames = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/employee/names`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            let workers = {}
            data.forEach((worker, i) => {
                workers = {...workers, [worker._id]: worker}
                // if (this.state.modal.id === worker._id) this.setState({modal: {...this.state.modal, data: job}})
                if (i+1 === data.length) {
                    this.setState({workers})
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    changeJob = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/jobs/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            let jobs = {}
            data.forEach((job, i) => {
                jobs = {...jobs, [job._id]: job}
                if (i+1 === data.length) {
                    this.setState({jobs})
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data})
        }).catch(err => {
            console.log(err)
        })
        this.getEmployeeNames()

        window.BrowserPrint.getDefaultDevice("printer", device => {
            this.zebraPrinter = new window.Zebra.Printer(device)
        }, error => {console.log(error)})

        this.socket.emit("teamGet")
        this.socket.on("get_team", this.getTeam)

        this.socket.emit("stockGet", {start: this.state.latestSE.start, end: this.state.latestSE.end})
        this.socket.on("get_stock", this.getStock)
        this.socket.on("change_stock", this.changeStock)

        this.socket.emit("restidGet")
        this.socket.on("get_restid", this.getRestid)
        this.socket.on("change_restid", this.changeRestid)

        this.socket.emit("alusedGet")
        this.socket.on("get_alused", this.getAlused)
        this.socket.on("change_alused", this.changeAlused)

        this.socket.emit("yardstorageGet")
        this.socket.on("get_yardstorage", this.getYardstorage)
        this.socket.on("change_yardstorage", this.changeYardstorage)

        this.socket.emit("shipmentsGet")
        this.socket.on("get_shipments", this.getShipments)
        this.socket.on("change_shipment", this.changeShipments)
        this.socket.on("return_shipment", this.returnShipment)

        this.socket.on("change_job", this.changeJob)
        
        this.socket.on("done", this.done)
    }

    componentWillUnmount() {
        this.socket.off("change_restid", this.changeRestid)
        this.socket.off("get_yardstorage", this.getYardstorage)
        this.socket.off("change_yardstorage", this.changeYardstorage)
        this.socket.off("get_team", this.getTeam)
        
        this.socket.off("done", this.done)
    }

    logout = e => {
        e.preventDefault()
        fetch(`${process.env.REACT_APP_SERVER_URL}/auth/logout`, {
            credentials: 'include'
        }).then(res => {
            if (res.status === 200) {
                history.push('/')
            }
        }).catch(err=>console.log(err))
    }

    route = e => {
        e.preventDefault()
        if (e.target.id === 'jobs') {
            fetch(`${process.env.REACT_APP_SERVER_URL}/jobs/get`, {
                credentials: 'include'
            }).then(res => {
                return res.json()
            }).then(data => {
                if (!data.length) this.setState({view: e.target.id, jobView: "jobs", filters: {name: '', type: '', active: false}})
                let jobs = {}
                data.forEach((job, i) => {
                    jobs = {...jobs, [job._id]: job}
                    if (i+1 === data.length) {
                        this.setState({view: e.target.id, page: 1, toggledDates: [], menuToggle: false, _id: "", jobs, filters: {active: false}})
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        } else {
            let {start, end} = this.state.latestSE
            if (start !== moment().startOf("month").format("YYYY-MM-DD") || end !== moment().endOf("month").format("YYYY-MM-DD")) this.fetchStock(moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD"))
            this.setState({view: e.target.id, page: 1, toggledDates: [], menuToggle: false, _id: "", filters: {active: false}})
        }
    }

    openDry = e => {
        e.preventDefault()
        this.setState({modal: "dry", menuToggle: false})
    }

    paginate = e => {
        e.preventDefault()
        this.setState({page: Number(e.target.getAttribute("page"))})
    }

    toggleFilters = e => {
        e.preventDefault()
        this.setState({filters: {...this.state.filters, active: !this.state.filters.active}})
    }

    toggleActive = e => {
        this.setState({kooremToggle: !this.state.kooremToggle})
    }
    
    toggleWarehouseUnits = e => {
        this.setState({warehouseUnits: !this.state.warehouseUnits})
    }

    highlight = e => {
        e.preventDefault()
        this.setState({_id: e.target.id})
    }

    dateToggle = e => {
        e.preventDefault()
        // console.log("asd")
        let toggledDates = this.state.toggledDates
        if (toggledDates.includes(e.target.id)) {
            toggledDates.splice(toggledDates.indexOf(e.target.id), 1)
            this.setState({toggledDates})
        } else {
            this.setState({toggledDates: [...toggledDates, e.target.id]})
        }
    }

    // deleteLine = e => {
    //     e.preventDefault()

    //     let dimensions = this.state.modalData.dimensions
    //     delete dimensions[e.target.getAttribute("line")]
    //     let newDimensions = {}

    //     Object.keys(dimensions).forEach((key, i) => {
    //         newDimensions = {...newDimensions, [i+1]: dimensions[key]}
    //         if (i+1 === Object.keys(dimensions).length) {
    //             this.setState({modalData: {...this.state.modalData, dimensions: newDimensions}})
    //         }
    //     })
    // }

    edit = e => {
        e.preventDefault()

        if (this.state.view === "yardStorage") {
            this.setState({
                senderToggle: Object.keys(this.state.settings.wares?.senders)?.includes(this.state.data[this.state._id]?.sender) ? false : true,
                modalData: this.state.data[this.state._id],
                rest: {},
                modal: "edit"
            })
        }
    }

    new = e => {
        e.preventDefault()
        this.setState({senderToggle: false, modal: "new", _id: "", rest: {}, modalData: {harvester: true, fsc: false}})
    }

    viewJob = e => {
        e.preventDefault()
        this.setState({modalData: this.state.jobs[this.state._id], modal: "job"})
    }

    closeModal = e => {
        e.preventDefault()
        if (this.state.modal === "shipment") {
            window.removeEventListener('keydown', this.onScanShip)
            this.socket.off("addunit_error", this.addUnitError)
        }
        this.setState({modal: "", sum: 0, rest: {}, modalData: {}, restToggle: false, _id: ""})
    }

    toggleSender = e => {
        e.preventDefault()
        this.setState({senderToggle: !this.state.senderToggle})
    }

    toggleHarvester = e => {
        this.setState({modalData: {...this.state.modalData, harvester: !this.state.modalData.harvester}})
    }

    toggleFSC = e => {
        this.setState({modalData: {...this.state.modalData, fsc: !this.state.modalData.fsc}})
    }

    changeDimension = e => {
        e.preventDefault()

        let sum = 0
        Object.keys(this.state.modalData.dimensions).forEach(key => {
            //MIS SIIN TOIMUB????
            let dimensions = {...this.state.modalData.dimensions[key]}
            let field = e.target.getAttribute("field")
            let operator = e.target.getAttribute("operator")
            let line = e.target.getAttribute("line")
            if (key === line) {
                dimensions = {...dimensions, [field]: operator === "plus" ? Number(dimensions[field] || 0) + 5 : Number(dimensions[field] || 0) - 5}
            }
            if (Object.keys(dimensions).length === 4) {
                let {height, width, length, coef} = dimensions
                if (typeof height === "string") height = height?.replace(/,/g, '.')
                if (typeof width === "string") width = width?.replace(/,/g, '.')
                if (typeof length === "string") length = length?.replace(/,/g, '.')
                if (typeof coef === "string") coef = coef?.replace(/,/g, '.')
                sum += (parseFloat(height||0)*parseFloat(width||0)*parseFloat(length||0)*parseFloat(coef||0))/1000000
            }
            if (Number(key) === Object.keys(this.state.modalData.dimensions).length) {
                // if (Object.keys(dimensions).length === 4) {
                //     this.setState({sum, modalData: {...this.state.modalData, dimensions: {...this.state.modalData.dimensions, [line]: {...this.state.modalData.dimensions?.[line], [field]: operator === "plus" ? Number(this.state.modalData.dimensions?.[line][field] || 0) + 5 : Number(this.state.modalData.dimensions?.[line][field] || 0) - 5}, [Number(key)+1]: {width: 235, length: 300,  coef: this.state.settings?.wares?.senders?.[this.state.modalData.sender] || this.state.modalData.dimensions[Number(key)]?.coef}}}})
                // } else {
                    this.setState({sum, modalData: {...this.state.modalData, dimensions: {...this.state.modalData.dimensions, [line]: {...this.state.modalData.dimensions?.[line], [field]: operator === "plus" ? Number(this.state.modalData.dimensions?.[line][field] || 0) + 5 : Number(this.state.modalData.dimensions?.[line][field] || 0) - 5}}}})
                // }
            }
        })
    }

    onChangeDimension = e => {
        e.preventDefault()

        let sum = 0
        
        Object.keys(this.state.modalData.dimensions).forEach((key, i) => {
            let dimensions = {...this.state.modalData.dimensions[key]}
            let line = e.target.getAttribute("line")
            if (key === line) {
                dimensions = {...dimensions, [e.target.getAttribute("field")]: e.target.value}
            }
            if (Object.keys(dimensions).length === 4) {
                let {height, width, length, coef} = dimensions
                if (typeof coef === "string") coef = coef?.replace(/,/g, '.')
                sum += (parseFloat(height||0)*parseFloat(width||0)*parseFloat(length||0)*parseFloat(coef||0))/1000000
            }
            if (i+1 === Object.keys(this.state.modalData.dimensions).length) {
                // if (Object.keys(dimensions).length === 4) {
                //     this.setState({sum, modalData: {...this.state.modalData, dimensions: {...this.state.modalData.dimensions, [line]: {...this.state.modalData.dimensions?.[line], [e.target.getAttribute("field")]: e.target.value}, [Number(key)+1]: {width: 235, length: 300,  coef: this.state.settings?.wares?.senders?.[this.state.data[this.state._id]?.sender] || this.state.modalData.dimensions[Number(key)]?.coef}}}})
                // } else {
                this.setState({sum, modalData: {...this.state.modalData, dimensions: {...this.state.modalData.dimensions, [line]: {...this.state.modalData.dimensions?.[line], [e.target.getAttribute("field")]: e.target.value}}}})
                // }
            }
        })
    }

    onChange = e => {
        e.preventDefault()

        if (e.target.id === "sender") {
            let dimensions = this.state.modalData.dimensions

            if (dimensions) {
                let sum = 0

                Object.keys(dimensions).forEach((key, i) => {
                    dimensions[key].coef = this.state.settings.wares.senders[e.target.value] 

                    if (Object.keys(dimensions[key]).length === 4) {
                        let {height, width, length, coef} = dimensions[key]
                        if (typeof coef === "string") coef = coef?.replace(/,/g, '.')
                        sum += (parseFloat(height||0)*parseFloat(width||0)*parseFloat(length||0)*parseFloat(coef||0))/1000000
                    }

                    if (i+1 === Object.keys(dimensions).length) {
                        this.setState({sum, modalData: {...this.state.modalData, sender: e.target.value, dimensions}})
                    }
                })
            } else {
                this.setState({modalData: {...this.state.modalData, sender: e.target.value}})
            }
        } else if (e.target.id === "maha") {
            if (e.target.value === "yes") this.setState({modalData: {...this.state.modalData, maha: true}})
            else this.setState({modalData: {...this.state.modalData, maha: false}})
        } else {
            this.setState({modalData: {...this.state.modalData, [e.target.id]: e.target.value}})
        }
    }

    changePackages = e => {
        e.preventDefault()
        
        let modalData = this.state.modalData
        let operator = e.target.getAttribute("operator")
        let sum = 0

        if (modalData.packages) {
            if (operator === "plus") this.setState({modalData: {...modalData, packages: modalData.packages+1, dimensions: {...modalData.dimensions, [modalData.packages+1]: {width: 235, length: 300,  coef: modalData.dimensions[modalData.packages]?.coef || this.state.settings?.wares?.senders?.[modalData.sender]}}}})
            else {
                if (modalData.dimensions[modalData.packages]) delete modalData.dimensions[modalData.packages]
                if (Object.keys(modalData.dimensions).length) Object.keys(modalData.dimensions).forEach(key => {
                    let {height, width, length, coef} = modalData.dimensions[key]
                    sum += (parseFloat(height||0)*parseFloat(width||0)*parseFloat(length||0)*parseFloat(coef||0))/1000000

                    if (Object.keys(modalData.dimensions).length === Number(key)) {
                        this.setState({sum, modalData: {...modalData, packages: modalData.packages-1}})
                    }
                })
                else {
                    this.setState({sum: 0, modalData: {...modalData, packages: 0, dimensions: {}}})
                }
            }
        }
        else {
            if (operator === "plus") this.setState({modalData: {...modalData, packages: 1, dimensions: {1: {width: 235, length: 300,  coef: this.state.settings?.wares?.senders?.[modalData.sender] || 0}}}})
            else this.setState({sum, modalData: {...modalData, packages: 0, dimensions: {}}})
        }
    }

    changeLogs = e => {
        e.preventDefault()

        let field = e.target.getAttribute("field")
        let operator = e.target.getAttribute("operator")

        this.setState({modalData: {...this.state.modalData, logs: {...this.state.modalData?.logs, [field]: operator === "plus" ? Number(typeof this.state.modalData?.logs?.[field] === "number" ? this.state.modalData?.logs?.[field] : this.state.modalData?.logs?.[field] ? this.state.modalData?.logs?.[field] : 0) + 1 : Number(typeof this.state.modalData?.logs?.[field] === "number" ? this.state.modalData?.logs?.[field] : this.state.modalData?.logs?.[field] ? this.state.modalData?.logs?.[field] : 0) - 1}}})
    }

    onChangeLogs = e => {
        e.preventDefault()
        this.setState({modalData: {...this.state.modalData, logs: {...this.state.modalData?.logs, [e.target.id]: Number(e.target.value)}}})
    }

    togglenewAlus = e => {
        e.preventDefault()
        this.setState({restToggle: !this.state.restToggle})
    }
    
    onChangeRest = e => {
        e.preventDefault()
        this.setState({newAlus: {...this.state.newAlus, [e.target.id]: e.target.value}})
    }

    onChangeFilters = e => {
        e.preventDefault()

        let id = e.target.id
        let value = e.target.value
        if (this.state.view === "warehouse") {
            if (id === "fromf") {
                if (this.state.filters.tof) {
                    this.fetchStock(value, this.state.filters.tof)
                }
            } else if (id === "tof") {
                if (this.state.filters.fromf) {
                    this.fetchStock(this.state.filters.fromf, value)
                }
            }
        }

        this.setState({filters: {...this.state.filters, [id]: value}})
    }

    clearFilters = e => {
        e.preventDefault()
        this.setState({filters: {active: true}})
    }

    saveRest = e => {
        e.preventDefault()

        if (e.target.id === "scan") {
            this.socket.emit("restSaveScan", {scan: this.state.scan})
            this.setState({loading: true})
            window.addEventListener('keydown', this.onScan)
            return
        }

        if (this.state.view === "yardStorage") { // kui koorma vaates lisatakse rest
            this.socket.emit("restSave", {...this.state.newAlus, parent: this.state._id, harvester: this.state.modalData.harvester})
            this.setState({newAlus: {}})
            if ((this.state.modalData.restid?.length+1 || 1) * this.state.settings.wares?.sortiment?.[this.state.modalData.sortiment] >= this.state.modalData.received) this.setState({restToggle: false})
        }
        // else if (this.state.view === "chopped") {
        //     this.socket.emit("restSave", {mainzone: this.state.modalData.location?.mainzone, subzone: this.state.modalData.location?.subzone, _id: this.state._id, rest: this.state.modalData.restNr, parent: this.state.modalData.parent})
        //     this.setState({modal: "", _id: ""})
        // }
    }

    save = e => {
        e.preventDefault()
        
        let {_id, sender, veoseleht, sent, defect, material, comments, zone, chopper, sortiment, maha, logs, packages, dimensions, harvester, fsc} = this.state.modalData
        let received = document.getElementById("received")?.value || document.getElementById("receivedManual")?.value
        
        if (!harvester) {
            material = "Kask"
            sortiment = "Ümarnott"
            maha = true
        }

        let missing = ""
        if (!sender) missing += "saatja "
        if (!veoseleht) missing += "veoseleht "
        if (!sent) missing += "saadetud "
        if (!received) missing += "vastuvõetud "
        if (!material) missing += "materjal "
        if (missing) alert(`Täida järgnevad väljad: \n ${missing}`)
        else {
            this.socket.emit("yardstorageSave", {_id, harvester, sender, veoseleht, sent, received, defect: defect || 0, material, comments, zone, chopper, sortiment, maha, logs, prevlogs: this.state.data[_id]?.logs || {}, packages, dimensions, fsc})
            this.setState({modal: "", restToggle: false, _id: "", sum: 0})
        }
    }

    // kuivatisse = e => {
    //     e.preventDefault()
    //     this.setState({modalData: this.state.restid[this.state._id], modal: "kuivatisse"})
    // }

    drySubmit = e => {
        e.preventDefault()

        if (e.target.id === "continue") {
            let chamber = document.getElementById("chamberselect").value
            if (!chamber) return
            this.setState({modalData: {chamber}})
        } else if (e.target.id === "start" || e.target.id === "end") {
            this.socket.emit("submitDry", {chamber: this.state.modalData.chamber, action: e.target.id})
            this.setState({modal: "", modalData: {}})
        }
    }

    kuivatisseSubmit = e => {
        e.preventDefault()
        this.socket.emit("toChamber", {_id: this.state.modalData?._id, chamber: this.state.modalData?.location?.chamber || ""})
        this.setState({modal: "", _id: ""})
    }

    finish = e => {
        e.preventDefault()

        if (this.state.confirmFinish === "finishys") {
            if (e.target.id === "yes") {
                this.socket.emit("yardstorageSave", {_id: this.state._id, finished: true})
                this.setState({modal: "", _id: "", confirmFinish: ""})
            }
            if (e.target.id === "no") {
                this.setState({confirmFinish: ""})
            }
        } else {
            this.setState({confirmFinish: "finishys"})
        }
    }

    toggleAlused = e => {
        e.preventDefault()
        let alused = [...this.state.toggledAlused]
        if (alused.includes(e.target.id)) {
            alused = alused.filter(item => item !== e.target.id)
            this.setState({toggledAlused: alused})
        } else this.setState({toggledAlused: [...alused, e.target.id]})
    }

    finishRest = e => {
        e.preventDefault()
        
        let type = e.target.id
        let myykAmount = document.getElementById("myykamount")?.value === undefined ? 1 : document.getElementById("myykamount").value

        if (type === "return") {
            this.setState({modal: ""})
            return
        }
        if (type === "return1") {
            this.setState({confirmFinish: ""})
            return
        }
        if (type === "myyk" && myykAmount < 1) return
        if (type === "haljala" && myykAmount < 1) return
        if (type === "mahakandmine" && myykAmount < 1) return
        if (!this.state.confirmFinish) {
            this.setState({confirmFinish: type})
            return
        }


        this.socket.emit("restSaveScan", {/*_id: this.state.modalData?._id, */scan: {...this.state.scan, finishType: type, myykAmount: /*type === "myyk" ? (*/myykAmount > this.state.scan.amount ? this.state.scan.amount : myykAmount/*) : null*/}/*, parent: this.state.modalData?.parent*/})
        this.setState({loading: true})
        window.addEventListener('keydown', this.onScan)
        // if (type === "myyk") {
        // } else if (type === "haljala") {
        //     this.socket.emit("restSave", {_id: this.state.modalData?._id, finished: "haljala"/*, parent: this.state.modalData?.parent*/})
        // } 
        // else if (type === "tootmine") {
        //     this.socket.emit("restSave", {_id: this.state.modalData?._id, finished: "tootmine", parent: this.state.modalData?.parent})
        // }
        this.setState({confirmFinish: "", modal: "", _id: ""})
    }

    finishRestMyyk = e => {
        e.preventDefault()
        
        let type = e.target.id

        if (this.state.scan.amount) {
            this.setState({modal: "myyk"})
        } else {
            if (!this.state.confirmFinish) {
                this.setState({confirmFinish: type})
                return
            }

            this.socket.emit("restSaveScan", {scan: {...this.state.scan, finishType: type}})
            this.setState({loading: true})
            window.addEventListener('keydown', this.onScan)
            this.setState({modal: "", _id: ""})
        }
        
    }

    finishRestHaljala = e => {
        e.preventDefault()
        
        let type = e.target.id

        if (this.state.scan.amount) {
            this.setState({modal: "haljala"})
        } else {
            if (!this.state.confirmFinish) {
                this.setState({confirmFinish: type})
                return
            }

            this.socket.emit("restSaveScan", {scan: {...this.state.scan, finishType: type}})
            this.setState({loading: true})
            window.addEventListener('keydown', this.onScan)
            this.setState({modal: "", _id: ""})
        }
        
    }

    finishRestMK = e => {
        e.preventDefault()
        
        let type = e.target.id

        if (this.state.scan.amount) {
            this.setState({modal: "mahakandmine"})
        } else {
            if (!this.state.confirmFinish) {
                this.setState({confirmFinish: type})
                return
            }

            this.socket.emit("restSaveScan", {scan: {...this.state.scan, finishType: type}})
            this.setState({loading: true})
            window.addEventListener('keydown', this.onScan)
            this.setState({modal: "", _id: ""})
        }
        
    }

    onToggleMenu = e => {
        e.preventDefault()
        this.setState({menuToggle: !this.state.menuToggle})
    }

    printSelections = e => {
        e.preventDefault()
        
        let start = document.getElementById("test-print-start").value
        let end = document.getElementById("test-print-end").value

        if (start.length !== 4 || end.length !== 4) {
            alert("Vale koodi pikkus")
            return
        }

        for (let i = Number(start); i <= Number(end); i++) {
            setTimeout(() => {
                let nr = ('000' + i).slice(-4)
                let q = `^XA
                        ^FO75,25^AVN,420,350^FD${nr}
                        ^FS
                        ^XZ`
        
                this.zebraPrinter.send(q, undefined, err => console.log(err))
                this.zebraPrinter.send(q, undefined, err => console.log(err))
        
                q = `^XA
                    ^FO125,50^BY6,3,10
                    ^B3N,N,250,Y,N^FD${nr}^FS
                    ^XZ`
                
                this.zebraPrinter.send(q, undefined, err => console.log(err))
                this.zebraPrinter.send(q, undefined, err => console.log(err))
            }, 250);
        }
    }

    newShipment = e => {
        e.preventDefault()
        this.setState({modal: "newshipment"})
    }

    continueShipment = e => {
        e.preventDefault()
        this.socket.on("addunit_error", this.addUnitError)
        this.setState({modal: "shipment", modalData: {ship: this.state._id}})
        window.addEventListener('keydown', this.onScanShip)
    }

    returnShipment = data => {
        this.setState({modalData: {...this.state.modalData, ship: data._id}})
    }

    addUnitError = data => {
        this.setState({modalData: {...this.state.modalData, modal: "error", error: data}})
    }
    
    errorOK = e => {
        e.preventDefault()
        this.setState({modalData: {...this.state.modalData, modal: "", error: {}}})
    }

    startShipment = e => {
        e.preventDefault()

        let tempName = document.getElementById("tempname").value
        if (!tempName) return
        if (e.target.id === "start") {
            this.socket.emit("startShipment", {tempName})
            this.socket.on("addunit_error", this.addUnitError)
            this.setState({modal: "shipment", modalData: {}})
            window.addEventListener('keydown', this.onScanShip)
        }
    }

    typeCode = e => {
        e.preventDefault()
        
        if (e.target.id === "type") this.setState({modalData: {...this.state.modalData, modal: "type"}})
        else if (e.target.id === "no") this.setState({modalData: {...this.state.modalData, modal: ""}})
        else if (e.target.id === "yes") this.setState({modalData: {...this.state.modalData, modal: "add", unit: document.getElementById("type-input").value}})
        // else if (e.target.id === "submit") {
        //     this.socket.emit("addUnit", {shipment: this.state.modalData.ship, unit: this.state.modalData.unit})
        //     this.setState({modalData: {...this.state.modalData, unit: "", modal: ""}})
        // }
    }

    addUnit = e => {
        e.preventDefault()

        if (e.target.id === "yes") {
            this.socket.emit("addUnit", {shipment: this.state.modalData.ship, unit: this.state.modalData.unit})
            this.setState({modalData: {...this.state.modalData, unit: "", modal: ""}})
        } else if (e.target.id === "no") this.setState({modalData: {...this.state.modalData, unit: "", modal: ""}})
    }

    removeUnit = e => {
        e.preventDefault()

        if (e.target.id === "yes") {
            this.socket.emit("removeUnit", {shipment: this.state.modalData.ship, unit: this.state.modalData.unit})
            this.setState({modalData: {...this.state.modalData, unit: "", modal: ""}})
        } else if (e.target.id === "no") this.setState({modalData: {...this.state.modalData, unit: "", modal: ""}})
        else this.setState({modalData: {...this.state.modalData, unit: e.target.id, modal: "remove"}})
    }

    finishShipment = e => {
        e.preventDefault()

        if (e.target.id === "yes") {
            let invoice = document.getElementById("invoice").value
            let type = document.getElementById("ship-type").value
            if (!invoice || !type) return
            this.setState({modalData: {...this.state.modalData, modal: "confirm", finish: {type, invoice}}})
        } else if (e.target.id === "no") this.setState({modalData: {...this.state.modalData, unit: "", modal: ""}})
        else if (e.target.id === "return") this.setState({modalData: {...this.state.modalData, modal: "finish"}})
        else if (e.target.id === "confirm") {
            this.socket.emit("finishShipment", {shipment: this.state.modalData.ship, invoice: this.state.modalData.finish.invoice, type: this.state.modalData.finish.type})
            this.setState({modal: "", modalData: {}, _id: ""})
            window.removeEventListener('keydown', this.onScanShip)
            this.socket.off("addunit_error", this.addUnitError)
        }
        else this.setState({modalData: {...this.state.modalData, unit: e.target.id, modal: "finish"}})
    }

    render() {
        let {_id, sender, veoseleht, sent, received, receivedManual, defect, zone, material, packages, chopper, sortiment, maha, logs, comments, dimensions, restid, location, restNr, harvester, fsc} = this.state.modalData
        let {kooremf, senderf, veoselehtf, fscf, zonef, materialf, chopperf, sortimentf, restf, articlef, subzonef, receivedfromf, receivedtof, choppedfromf, choppedtof, drystartfromf, drystarttof, dryendfromf, dryendtof, namef, fromf, tof, typef, restnrf, phasef, driverf, locationf, chopfromf, choptof, finishedfromf, finishedtof, clientf, workerf, productf, pakendf, siltf, posterf, pmf, addedfromf, addedtof, duedatefromf, duedatetof, orderf} = this.state.filters
        let {settings, menuToggle, kooremToggle, scan, stock, loading, jobs, shipments, warehouseUnits} = this.state

        return (
                <div className="wares">
                    <div className="menu-btn" onClick={this.onToggleMenu}>
                        <span className={`menu-btn--burger${menuToggle ? " open" : ""}`}></span>
                    </div>
                    <nav>
                        <ul className={`menu${menuToggle ? " open" : ""}`}>
                            <h1 className={menuToggle ? "open" : ""}>{settings.uname}</h1>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="yardStorage" onClick={this.route}>Palgiladu</li>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="rest" onClick={this.route}>Pooltoodang</li>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="alus" onClick={this.route}>Valmistoodang</li>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="warehouse" onClick={this.route}>Põhiladu</li>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="stock" onClick={this.route}>Inventuur</li>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="haljala" onClick={this.route}>Haljala</li>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="myyk" onClick={this.route}>Müük</li>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="jobs" onClick={this.route}>Tellimused</li>
                            {/* <li className={`menu-item${menuToggle ? " open" : ""}`} id="finished" onClick={this.route}>Lõpetatud restid</li> */}
                            <div>
                                <span className={menuToggle ? "open" : ""} id="dry" onClick={this.openDry}><i className="fas fa-fire"/></span>
                                <span className={menuToggle ? "open" : ""} id="ship" onClick={this.route}><i className="fas fa-truck"/></span>
                                <span className={menuToggle ? "open" : ""} id="test" onClick={this.route}><i className="fas fa-cog"/></span>
                            </div>
                            <span className={menuToggle ? "open" : ""} onClick={this.logout}><i className="fas fa-sign-out-alt"/></span>
                        </ul>
                    </nav>
                    <div className="wares-header">
                        {(this.state.view !== "ship" && this.state.view !== "test") && <a className="filterbutton" onClick={this.toggleFilters}>Filtreeri</a>}
                        {this.state.view === "yardStorage" && <a className={`edit${!this.state._id ? " edit--disabled" : ""}`} onClick={this.edit}>Muuda <i className="fas fa-pencil-alt"/></a>}
                        {this.state.view === "yardStorage" && <a className="addnew" onClick={this.new}>Lisa uus <i className="fas fa-plus-circle"/></a>}
                        {this.state.view === "ship" && <a className={`edit${!this.state._id ? " edit--disabled" : ""}`} onClick={this.continueShipment}>Jätka <i className="fas fa-pencil-alt"/></a>}
                        {this.state.view === "ship" && <a className="addnew" onClick={this.newShipment}>Uus laadung</a>}
                        {this.state.view === "yardStorage" && <div>
                            <p>Näita aktiivseid</p>
                            <input type="checkbox" id="kooremtoggle" className="switchbutton" checked={kooremToggle} onChange={this.toggleActive} />
                        </div>}
                        {this.state.view === "warehouse" && <div>
                            <p>{warehouseUnits ? "Restid" : "Ühikud"}</p>
                            <input type="checkbox" className="switchbutton" checked={warehouseUnits} onChange={this.toggleWarehouseUnits} />
                        </div>}
                        {this.state.view === "jobs" && <a className={`edit${!this.state._id ? " edit--disabled" : ""}`} onClick={this.viewJob}>Detailid</a>}
                        {/* {this.state.view === "chopped" && <a className={`addnew${(!this.state._id || this.state.restid?.[this.state._id]?.phase === "Kuivati") ? " addnew--disabled" : ""}`} onClick={this.kuivatisse}>Kuivatisse</a>} */}
                    </div>
                    <div className={`wares-filters${this.state.filters.active ? " wares-filters--active" : ""}`}>
                        <span className="modal-close" onClick={this.toggleFilters}>&times;</span>
                        {this.state.view === "yardStorage" &&
                        <div className="edit-row">
                            <h1 className="clear-filters" onClick={this.clearFilters}>Tühjenda filtrid</h1>
                            <div>
                                <h1>Koorma number</h1>
                                <input type="text" id="kooremf" value={kooremf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Saatja</h1>
                                <input type="text" id="senderf" value={senderf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Veoseleht</h1>
                                <input type="text" id="veoselehtf" value={veoselehtf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>FSC</h1>
                                <select id="fscf" value={fscf || ""} onChange={this.onChangeFilters}>
                                    <option value="2">Kõik</option>
                                    <option value="1">Ainult FSC</option>
                                    <option value="0">Peida FSC</option>
                                </select>
                            </div>
                            <div>
                                <h1>Tsoon</h1>
                                <select id="zonef" value={zonef || ""} onChange={this.onChangeFilters}>
                                    <option value=""></option>
                                    {Object.keys(settings.wares?.zones || {}).map(key => {
                                        return <option value={key} key={key}>{key}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <h1>Materjal</h1>
                                <input type="text" id="materialf" value={materialf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Lõhkuja</h1>
                                <input type="text" id="chopperf" value={chopperf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Sortiment</h1>
                                <input type="text" id="sortimentf" value={sortimentf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Vastuvõtu aeg</h1>
                                <input type="date" id="receivedfromf" value={receivedfromf || ""} onChange={this.onChangeFilters} />
                                <input type="date" id="receivedtof" value={receivedtof || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div className="date-margin">
                                <h1>Lõhkumise algus</h1>
                                <input type="date" id="choppedfromf" value={choppedfromf || ""} onChange={this.onChangeFilters} />
                                <input type="date" id="choppedtof" value={choppedtof || ""} onChange={this.onChangeFilters} />
                            </div>
                        </div> ||
                        (this.state.view === "rest" || this.state.view === "finished") &&
                        <div className="edit-row">
                            <h1 className="clear-filters" onClick={this.clearFilters}>Tühjenda filtrid</h1>
                            <div>
                                <h1>Resti number</h1>
                                <input type="text" id="restf" value={restf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Koorma number</h1>
                                <input type="text" id="kooremf" value={kooremf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Lõhkuja</h1>
                                <input type="text" id="chopperf" value={chopperf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Sortiment</h1>
                                <input type="text" id="sortimentf" value={sortimentf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Artikkel</h1>
                                <input type="text" id="articlef" value={articlef || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>FSC</h1>
                                <select id="fscf" value={fscf || ""} onChange={this.onChangeFilters}>
                                    <option value="2">Kõik</option>
                                    <option value="1">Ainult FSC</option>
                                    <option value="0">Peida FSC</option>
                                </select>
                            </div>
                            <div>
                                <h1>Tsoon</h1>
                                <select id="zonef" value={zonef || ""} onChange={this.onChangeFilters}>
                                    <option value=""></option>
                                    <option value="Kuivati">Kuivati</option>
                                    {Object.keys(settings.wares?.zones || {}).map(key => {
                                        return <option value={key} key={key}>{key}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <h1>Asukoht</h1>
                                <select id="subzonef" value={subzonef || ""} onChange={this.onChangeFilters}>
                                    <option value=""></option>
                                    {zonef === "Kuivati" ?
                                    (settings.wares?.chambers || []).map(key => {
                                        return <option value={key} key={key}>{key}</option>
                                    }) :
                                    (settings.wares?.zones?.[zonef] || []).map(key => {
                                        return <option value={key} key={key}>{key}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <h1>Lõhkumise kuupäev</h1>
                                <input type="date" id="choppedfromf" value={choppedfromf || ""} onChange={this.onChangeFilters} />
                                <input type="date" id="choppedtof" value={choppedtof || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div className="date-margin">
                                <h1>Kuivatisse mineku kuupäev</h1>
                                <input type="date" id="drystartfromf" value={drystartfromf || ""} onChange={this.onChangeFilters} />
                                <input type="date" id="drystarttof" value={drystarttof || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div className="date-margin">
                                <h1>Kuivatist tuleku kuupäev</h1>
                                <input type="date" id="dryendfromf" value={dryendfromf || ""} onChange={this.onChangeFilters} />
                                <input type="date" id="dryendtof" value={dryendtof || ""} onChange={this.onChangeFilters} />
                            </div>
                        </div> ||
                        (this.state.view === "alus") &&
                        <div className="edit-row">
                            <h1 className="clear-filters" onClick={this.clearFilters}>Tühjenda filtrid</h1>
                            <div>
                                <h1>Resti number</h1>
                                <input type="text" id="restf" value={restf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Artikkel</h1>
                                <input type="text" id="articlef" value={articlef || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Tellimus</h1>
                                <input type="text" id="orderf" value={orderf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>FSC</h1>
                                <select id="fscf" value={fscf || ""} onChange={this.onChangeFilters}>
                                    <option value="2">Kõik</option>
                                    <option value="1">Ainult FSC</option>
                                    <option value="0">Peida FSC</option>
                                </select>
                            </div>
                            <div>
                                <h1>Tsoon</h1>
                                <select id="zonef" value={zonef || ""} onChange={this.onChangeFilters}>
                                    <option value=""></option>
                                    <option value="Kuivati">Kuivati</option>
                                    {Object.keys(settings.wares?.zones || {}).map(key => {
                                        return <option value={key} key={key}>{key}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <h1>Asukoht</h1>
                                <select id="subzonef" value={subzonef || ""} onChange={this.onChangeFilters}>
                                    <option value=""></option>
                                    {zonef === "Kuivati" ?
                                    (settings.wares?.chambers || []).map(key => {
                                        return <option value={key} key={key}>{key}</option>
                                    }) :
                                    (settings.wares?.zones?.[zonef] || []).map(key => {
                                        return <option value={key} key={key}>{key}</option>
                                    })}
                                </select>
                            </div>
                        </div> ||
                        (this.state.view === "warehouse" || this.state.view === "haljala" || this.state.view === "tootmine" || this.state.view === "myyk") &&
                        <div className="edit-row">
                            <div>
                                <h1>FSC</h1>
                                <select id="fscf" value={fscf || ""} onChange={this.onChangeFilters}>
                                    <option value="2">Kõik</option>
                                    <option value="1">Ainult FSC</option>
                                    <option value="0">Mitte FSC</option>
                                </select>
                            </div>
                            <div className="filter">
                                <h1>Artikkel</h1>
                                <input type="text" id="articlef" value={articlef || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div className="filter">
                                <h1>Nimi</h1>
                                <input type="text" id="namef" value={namef || ""} onChange={this.onChangeFilters} />
                            </div>
                            {/* <div className="filter">
                                <h1>Vaade</h1>
                                <select type="text" id="view" value={this.state.filters.view || "day"} onChange={this.onChange}>
                                    <option value="day">Päev</option>
                                    <option value="month">Kuu</option>
                                    <option value="year">Aasta</option>
                                </select>
                            </div> */}
                            <div className="filter">
                                <h1>Alates</h1>
                                <input type="date" id="fromf" value={fromf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div className="filter">
                                <h1>Kuni</h1>
                                <input type="date" id="tof" value={tof || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div className="filter">
                                <h1>Tüüp</h1>
                                <select className="article-type" id="typef" value={typef || ""} onChange={this.onChangeFilters}>
                                    <option value=""></option>
                                    <option value="Palgid">Palgid</option>
                                    <option value="Toore, märg">Toore, märg</option>
                                    <option value="Kuivatis">Kuivatis</option>
                                    <option value="Kuiv pakkimata">Kuiv pakkimata</option>
                                    <option value="Valmis toodang">Valmis toodang</option>
                                    <option value="Küttepuu">Küttepuu</option>
                                    <option value="Pakkevahendid">Pakkevahendid</option>
                                    <option value="Sildid">Sildid</option>
                                </select>
                            </div>
                        </div> ||
                        this.state.view === "stock" &&
                        <div className="edit-row">
                            <div className="filter">
                                <h1>Artikkel</h1>
                                <input type="text" id="articlef" value={articlef || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div className="filter">
                                <h1>Nimi</h1>
                                <input type="text" id="namef" value={namef || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div className="filter">
                                <h1>Tüüp</h1>
                                <select className="article-type" id="typef" value={typef || ""} onChange={this.onChangeFilters}>
                                    <option value=""></option>
                                    <option value="Palgid">Palgid</option>
                                    <option value="Toore, märg">Toore, märg</option>
                                    <option value="Kuivatis">Kuivatis</option>
                                    <option value="Kuiv pakkimata">Kuiv pakkimata</option>
                                    <option value="Valmis toodang">Valmis toodang</option>
                                    <option value="Küttepuu">Küttepuu</option>
                                    <option value="Pakkevahendid">Pakkevahendid</option>
                                    <option value="Sildid">Sildid</option>
                                </select>
                            </div>
                        </div> ||
                        (this.state.view === "jobs") &&
                        <div className="edit-row">
                            <h1 className="clear-filters" onClick={this.clearFilters}>Tühjenda filtrid</h1>
                            <div>
                                <h1>Töötaja tüüp</h1>
                                <select id="typef" value={typef || ""} onChange={this.onChangeFilters}>
                                    <option value=""></option>
                                    <option value="production">Tootja</option>
                                    <option value="processor">Töötleja</option>
                                </select>
                            </div>
                            <div>
                                <h1>Tellija</h1>
                                <input type="text" id="clientf" value={clientf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Tööline</h1>
                                <input type="text" id="workerf" value={workerf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Toodang</h1>
                                <input type="text" id="productf" value={productf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Pakend</h1>
                                <input type="text" id="pakendf" value={pakendf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Silt</h1>
                                <input type="text" id="siltf" value={siltf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Poster</h1>
                                <input type="text" id="posterf" value={posterf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Pakkematerjal</h1>
                                <input type="text" id="pmf" value={pmf || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div>
                                <h1>Sisestamise kuupäev</h1>
                                <input type="date" id="addedfromf" value={addedfromf || ""} onChange={this.onChangeFilters} />
                                <input type="date" id="addedtof" value={addedtof || ""} onChange={this.onChangeFilters} />
                            </div>
                            <div className="date-margin">
                                <h1>Laadimise kuupäev</h1>
                                <input type="date" id="duedatefromf" value={duedatefromf || ""} onChange={this.onChangeFilters} />
                                <input type="date" id="duedatetof" value={duedatetof || ""} onChange={this.onChangeFilters} />
                            </div>
                        </div>}
                    </div>
                    {(!["dry", "ship", "test"].includes(this.state.view)) && <div className="scanbutton">
                        <a onClick={this.openScan}>Skaneeri toode</a>
                    </div>}
                    <div className="wares-content">
                        {(this.state.view === "warehouse" && (!articlef && !namef && !typef)) &&
                        <div className="pagination">
                            {Array(Math.ceil(Object.keys(stock || {}).length / 50)).fill(0,0,Math.ceil(Object.keys(stock || {}).length / 50)).map((key, i) => {
                                return <h1 className={`pagination-button${this.state.page === i+1 ? " pagination-button--active" : ""}`} key={i} page={i+1} onClick={this.paginate}>{i+1}</h1>
                            })}
                        </div>}
                        {this.state.view === "yardStorage" && <div className="table" key="1">
                            <div className="row row-sticky">
                                <div className="column-wrapper">
                                    <h1 className="wares-koorem">Koorma nr</h1>
                                </div>
                                <h1 className="wares-15">Saatja</h1>
                                <h1 className="wares-9">Veoseleht</h1>
                                <h1 className="wares-9">Saadetud</h1>
                                <h1 className="wares-11">Vastuvõetud</h1>
                                <h1 className="wares-6">Praak</h1>
                                <h1 className="wares-6">Tsoon</h1>
                                <h1 className="wares-16">Vastuvõtu aeg</h1>
                                <h1 className="wares-8">Materjal</h1>
                                <h1 className="wares-11">Lõhkuja</h1>
                                <h1 className="wares-10">Algus</h1>
                                <h1 className="wares-9">Sortiment</h1>
                                <h1 className="wares-6">FSC</h1>
                                <h1 className="wares-6">Maha</h1>
                                {Object.keys(settings?.wares?.logs || {}).map(key => {
                                    return <h1 className="wares-7" key={key}>{key}</h1>
                                })}
                                <h1 className="wares-7">Maht</h1>
                                {/* <h1 className="wares-9">Lõpetatud</h1>
                                <h1 className="wares-10">Kuupäev</h1> */}
                                <h1 className="wares-15">Kommentaarid</h1>
                            </div>
                            {Object.keys(this.state.data || {}).map(key => {
                                let {koorem, sender, veoseleht, sent, received, defect, zone, startDate, material, chopper, chopStart, sortiment, fsc, maha, logs, finished, finishDate, comments, maht} = this.state.data[key]

                                if (kooremToggle && !chopper) return
                                if (kooremf && !(koorem||"").toLowerCase().includes(kooremf.toLowerCase())) return
                                if (senderf && !(sender||"").toLowerCase().includes(senderf.toLowerCase())) return
                                if (veoselehtf && !(veoseleht||"").toLowerCase().includes(veoselehtf.toLowerCase())) return
                                if (zonef && !(zone||"").toLowerCase().includes(zonef.toLowerCase())) return
                                if (materialf && !(material||"").toLowerCase().includes(materialf.toLowerCase())) return
                                if (chopperf && !(chopper||"").toLowerCase().includes(chopperf.toLowerCase())) return
                                if (sortimentf && !(sortiment||"").toLowerCase().includes(sortimentf.toLowerCase())) return
                                if (fscf === "1" && !fsc) return
                                if (fscf === "0" && fsc) return

                                let receivedDate = moment(startDate, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD")
                                if (receivedfromf > receivedDate || receivedtof < receivedDate) return
                                let chopStartDate = moment(chopStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                if (choppedfromf > chopStartDate || choppedtof < chopStartDate) return

                                return <div className={`row${this.state._id === key ? " highlight" : ""}`} key={key} id={key} onClick={this.highlight}>
                                    <div className="column-wrapper" id={key} onClick={this.highlight}>
                                        <h1 className={`wares-koorem${this.state._id === key ? " highlight" : ""}`}>{koorem}</h1>
                                    </div>
                                    <h1 className="wares-15">{sender}</h1>
                                    <h1 className="wares-9">{veoseleht}</h1>
                                    <h1 className="wares-9">{sent}</h1>
                                    <h1 className="wares-11">{received}</h1>
                                    <h1 className="wares-6">{defect}</h1>
                                    <h1 className="wares-6">{zone}</h1>
                                    <h1 className="wares-16">{startDate}</h1>
                                    <h1 className="wares-8">{material}</h1>
                                    <h1 className="wares-11">{chopper}</h1>
                                    <h1 className="wares-10">{chopStart}</h1>
                                    <h1 className="wares-9">{sortiment}</h1>
                                    <h1 className="wares-6">{fsc ? "Jah" : "Ei"}</h1>
                                    <h1 className="wares-6">{maha ? "Jah" : "Ei"}</h1>
                                    {Object.keys(settings?.wares?.logs || {}).map(key => {
                                        return <h1 className="wares-7" key={key}>{logs?.[key] || ""}</h1>
                                    })}
                                    <h1 className="wares-7">{Math.round((maht + Number.EPSILON) * 100) / 100}</h1>
                                    {/* <h1 className="wares-9">{finished ? "Lõpetatud" : ""}</h1>
                                    <h1 className="wares-10">{finishDate}</h1> */}
                                    <h1 className="wares-15">{comments}</h1>
                                </div>
                            })}
                        </div> ||
                        this.state.view === "rest" && <div className="table" key="2">
                            <div className="row row-sticky">
                                <div className="column-wrapper">
                                    <h1 className="wares-koorem">Resti nr</h1>
                                </div>
                                <h1 className="wares-8">Koorma nr</h1>
                                <h1 className="wares-9">Lõhkuja</h1>
                                <h1 className="wares-9">Lõhkumine</h1>
                                <h1 className="wares-10">Sortiment</h1>
                                <h1 className="wares-11 wares-left">Artikkel</h1>
                                <h1 className="wares-9">Tootmisjärk</h1>
                                <h1 className="wares-9">Tsoon</h1>
                                <h1 className="wares-9">Asukoht</h1>
                                <h1 className="wares-6">Tükiarv</h1>
                                <h1 className="wares-10">Kuivatisse</h1>
                                <h1 className="wares-10">Kuivatist välja</h1>
                                <h1 className="wares-6">FSC</h1>
                                {/* <h1 className="wares-8">Valminud</h1> */}
                            </div>
                            {Object.keys(this.state.restid || {}).map(key => {
                                let {chopper, finished, parent, phase, restNr, sortiment, fsc, article, location, chopStart, amount, dryStart, dryEnd} = this.state.restid[key]
                                if (finished) return
                                let koorem = this.state.data[parent]?.koorem || this.state.finished[parent]
                                if (restf && !(restNr||"").toLowerCase().includes(restf.toLowerCase())) return
                                if (kooremf && !(koorem||"").toLowerCase().includes(kooremf.toLowerCase())) return
                                if (chopperf && !(chopper||"").toLowerCase().includes(chopperf.toLowerCase())) return
                                if (sortimentf && !(sortiment||"").toLowerCase().includes(sortimentf.toLowerCase())) return
                                if (articlef && !(article||"").toLowerCase().includes(articlef.toLowerCase())) return
                                if (fscf === "1" && !fsc) return
                                if (fscf === "0" && fsc) return
                                if (zonef) {
                                    if (zonef === "Kuivati" && phase !== "Kuivati") return
                                    if (zonef !== "Kuivati" && zonef !== location.mainzone) return

                                    if (subzonef) {
                                        if (zonef === "Kuivati" && subzonef !== location.chamber) return
                                        if (zonef !== "Kuivati" && subzonef !== location.subzone) return
                                    }
                                }

                                let chopStartDate = moment(chopStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                if (choppedfromf > chopStartDate || choppedtof < chopStartDate) return
                                let dryStartDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                if (drystartfromf > dryStartDate || drystarttof < dryStartDate) return
                                let dryEndDate = moment(dryEnd, "DD/MM/YYYY").format("YYYY-MM-DD")
                                if (dryendfromf > dryEndDate || dryendtof < dryEndDate) return

                                // if (zonef && ) return
                                return <div className={`row ${this.state._id === key ? "highlight" : ""}`} key={key} id={key} /*onClick={this.highlight}*/>
                                    <div className="column-wrapper" id={key} /*onClick={this.highlight}*/>
                                        <h1 className={`wares-koorem ${this.state._id === key ? "highlight" : ""}`}>{restNr}</h1>
                                    </div>
                                    <h1 className="wares-8">{koorem}</h1>
                                    <h1 className="wares-9">{chopper}</h1>
                                    <h1 className="wares-9">{chopStart}</h1>
                                    <h1 className="wares-10">{sortiment}</h1>
                                    <h1 className="wares-11 wares-left">{article}</h1>
                                    <h1 className="wares-9">{phase}</h1>
                                    <h1 className="wares-9">{location.mainzone || "Kuivati"}</h1>
                                    <h1 className="wares-9">{location.subzone || location.chamber}</h1>
                                    <h1 className="wares-6">{amount || "-"}</h1>
                                    <h1 className="wares-10">{dryStart}</h1>
                                    <h1 className="wares-10">{dryEnd}</h1>
                                    <h1 className="wares-6">{fsc ? "Jah" : "Ei"}</h1>
                                    {/* <h1 className="wares-8">{finished ? "Jah" : "Ei"}</h1> */}
                                </div>
                            })}
                        </div> ||
                        this.state.view === "alus" && <div className="table" key="3">
                            <div className="row row-sticky">
                                <div className="column-wrapper">
                                    <h1 className="wares-koorem">Aluse nr</h1>
                                </div>
                                <h1 className="wares-11 wares-left">Artikkel</h1>
                                <h1 className="wares-9">Tootmisjärk</h1>
                                <h1 className="wares-9">Tsoon</h1>
                                <h1 className="wares-9">Asukoht</h1>
                                <h1 className="wares-6">Tükiarv</h1>
                                <h1 className="wares-11">Tellimus</h1>
                                <h1 className="wares-6">FSC</h1>
                                {/* <h1 className="wares-8">Valminud</h1> */}
                            </div>
                            {Object.keys(this.state.alused || {}).map(key => {
                                let {finished, phase, restNr, article, location, amount, order, fsc} = this.state.alused[key]
                                if (finished) return
                                if (restf && !(restNr||"").toLowerCase().includes(restf.toLowerCase())) return
                                if (articlef && !(article||"").toLowerCase().includes(articlef.toLowerCase())) return
                                if (fscf === "1" && !fsc) return
                                if (fscf === "0" && fsc) return
                                if (zonef) {
                                    if (zonef === "Kuivati" && phase !== "Kuivati") return
                                    if (zonef !== "Kuivati" && zonef !== location.mainzone) return

                                    if (subzonef) {
                                        if (zonef === "Kuivati" && subzonef !== location.chamber) return
                                        if (zonef !== "Kuivati" && subzonef !== location.subzone) return
                                    }
                                }

                                return <div className={`row ${this.state._id === key ? "highlight" : ""}`} key={key} id={key} /*onClick={this.highlight}*/>
                                    <div className="column-wrapper" id={key} /*onClick={this.highlight}*/>
                                        <h1 className={`wares-koorem ${this.state._id === key ? "highlight" : ""}`}>{restNr}</h1>
                                    </div>
                                    <h1 className="wares-11 wares-left">{article}</h1>
                                    <h1 className="wares-9">{phase}</h1>
                                    <h1 className="wares-9">{location.mainzone || "Kuivati"}</h1>
                                    <h1 className="wares-9">{location.subzone || location.chamber}</h1>
                                    <h1 className="wares-6">{amount || "-"}</h1>
                                    <h1 className="wares-11">{order}</h1>
                                    <h1 className="wares-6">{fsc ? "Jah" : "Ei"}</h1>
                                </div>
                            })}
                        </div> ||
                        this.state.view === "warehouse" && 
                        <div className="table" key="4">
                            <div className="row row-sticky">
                                {Array((fromf && tof) ? moment(tof).diff(moment(fromf), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(fromf && tof) ? moment(tof).diff(moment(fromf), 'days')+2 : moment().daysInMonth()+1).map((key, i) => {
                                    if (i===0) return <h1 key={i} className="empty"></h1>
                                    let date = fromf && tof ? moment(fromf).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                    if ((fromf && tof) && moment(date, "DD/MM/YY") > moment(tof)) return
                                    return <h1 key={i} id={date} className={`date${this.state.toggledDates.includes(date) ? " date--active" : ""} ${date === moment().format("DD/MM/YY") ? "currentdate" : ""}`} onClick={this.dateToggle}>{date}</h1>
                                })}
                                <h1 id="summary" className="date date--active1">Kokku</h1>
                            </div>
                            <div className="row row-sticky2">
                                {Array((fromf && tof) ? moment(tof).diff(moment(fromf), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(fromf && tof) ? moment(tof).diff(moment(fromf), 'days')+2 : moment().daysInMonth()+1).map((key, i) => {
                                    if (i===0) return <h1 key={i} className="empty"></h1>
                                    let date = fromf && tof ? moment(fromf).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                    if ((fromf && tof) && moment(date, "DD/MM/YY") > moment(tof)) return
                                    return <div key={i} className={`datecolumns${this.state.toggledDates.includes(date) ? " datecolumns--active" : ""}`}>
                                        <h1>Seis</h1>
                                        {this.state.toggledDates.includes(date) && <h1>Sisse</h1>}
                                        {this.state.toggledDates.includes(date) && <h1>Välja</h1>}
                                    </div>
                                })}
                                <div className="datecolumns datecolumns--active1">
                                    <h1>Algus</h1>
                                    <h1>Sisse</h1>
                                    <h1>Välja</h1>
                                    <h1>Lõpp</h1>
                                </div>
                            </div>
                            {Object.keys(stock).map((key, i) => {
                                let {name, count, unit, log, type, convertible} = stock[key]
                                let toggledValues = this.state.toggledValues
                                if (articlef) {
                                    if (!key.toLowerCase().includes(articlef.toLowerCase())) return
                                }
                                if (namef) {
                                    if (!name.toLowerCase().includes(namef.toLowerCase())) return
                                }
                                if (typef) {
                                    if (type !== typef) return
                                }
                                if (!articlef && !namef && !typef) {
                                    if (i >= this.state.page*50 || i < (this.state.page-1)*50) return
                                }
                                let first = 0
                                let countSum = 0
                                let inSum = 0
                                let outSum = 0
                                let countValue
                                let inValue
                                let outValue
                                return <div className={`row${convertible ? " convertible" : ""}`} key={key}>
                                    {Array((fromf && tof) ? moment(tof).diff(moment(fromf), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(fromf && tof) ? moment(tof).diff(moment(fromf), 'days')+2 : moment().daysInMonth()+1).map((key1, i) => {
                                        if (i===0) return <h1 key={i} className={`empty${convertible ? " convertible" : ""}`}>{name}</h1>
                                        let date = fromf && tof ? moment(fromf).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                        if ((fromf && tof) && moment(date, "DD/MM/YY") > moment(tof)) return
                                        countValue = 0
                                        inValue = 0
                                        outValue = 0
                                        if (fscf === "0") {
                                            if (warehouseUnits) {
                                                if (i===1) first = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0
                                                countValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count
                                                inValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.in
                                                outValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.out
                                                countSum = countValue ? Number(countValue) : countSum
                                                inSum += inValue ? Number(inValue) : 0
                                                outSum += outValue ? Number(outValue) : 0
                                            } else {
                                                if (i===1) first = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0
                                                countValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count
                                                inValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.in
                                                outValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.out
                                                if (convertible) {
                                                    let rm = Number(key.split("-")[key.split("-").length-1].replace(/,/g, '.'))
                                                    countValue = (countValue*rm || countValue*rm === 0) ? countValue*rm : undefined
                                                    inValue = (inValue*rm || inValue*rm === 0) ? inValue*rm : undefined
                                                    outValue = (outValue*rm || outValue*rm === 0) ? outValue*rm : undefined
                                                }
                                                countSum = countValue ? Number(countValue) : countSum
                                                inSum += inValue ? Number(inValue) : 0
                                                outSum += outValue ? Number(outValue) : 0
                                            }
                                        } else if (fscf === "1") {
                                            if (warehouseUnits) {
                                                if (i===1) first = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count || 0
                                                countValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count
                                                inValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.in
                                                outValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.out
                                                countSum = countValue ? Number(countValue) : countSum
                                                inSum += inValue ? Number(inValue) : 0
                                                outSum += outValue ? Number(outValue) : 0
                                            } else {
                                                if (i===1) first = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count || 0
                                                countValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count
                                                inValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.in
                                                outValue = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.out
                                                if (convertible) {
                                                    let rm = Number(key.split("-")[key.split("-").length-1].replace(/,/g, '.'))
                                                    countValue = (countValue*rm || countValue*rm === 0) ? countValue*rm : undefined
                                                    inValue = (inValue*rm || inValue*rm === 0) ? inValue*rm : undefined
                                                    outValue = (outValue*rm || outValue*rm === 0) ? outValue*rm : undefined
                                                }
                                                countSum = countValue ? Number(countValue) : countSum
                                                inSum += inValue ? Number(inValue) : 0
                                                outSum += outValue ? Number(outValue) : 0
                                            }
                                        } else {
                                            if (warehouseUnits) {
                                                if (i===1) first = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count || 0)
                                                countValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count || 0)
                                                inValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.in || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.in || 0)
                                                outValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.out || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.out || 0)
                                                countSum = countValue ? Number(countValue) : countSum
                                                inSum += inValue ? Number(inValue) : 0
                                                outSum += outValue ? Number(outValue) : 0
                                            } else {
                                                if (i===1) first = log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0
                                                countValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.count || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.count || 0)
                                                inValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.in || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.in || 0)
                                                outValue = (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.a?.out || 0) + (log[moment(date, "DD/MM/YY").format("YYYY-MM-DD")]?.fsc?.out || 0)
                                                if (convertible) {
                                                    let rm = Number(key.split("-")[key.split("-").length-1].replace(/,/g, '.'))
                                                    countValue = (countValue*rm || countValue*rm === 0) ? countValue*rm : undefined
                                                    inValue = (inValue*rm || inValue*rm === 0) ? inValue*rm : undefined
                                                    outValue = (outValue*rm || outValue*rm === 0) ? outValue*rm : undefined
                                                }
                                                countSum = countValue ? Number(countValue) : countSum
                                                inSum += inValue ? Number(inValue) : 0
                                                outSum += outValue ? Number(outValue) : 0
                                            }
                                        }
                                        return <div key={i} className={`datecolumns${this.state.toggledDates.includes(date) ? " datecolumns--active" : ""}`}>
                                            <h1 item={key} date={date} valuetype="count" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{isNaN(countValue) ? "" : Math.round((countValue + Number.EPSILON) * 100) / 100 || ""}</h1>
                                            {this.state.toggledDates.includes(date) && <h1 item={key} date={date} valuetype="in" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{isNaN(inValue) ? "" : Math.round((inValue + Number.EPSILON) * 100) / 100 || ""}</h1>}
                                            {this.state.toggledDates.includes(date) && <h1 item={key} date={date} valuetype="out" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{isNaN(outValue) ? "" : Math.round((outValue + Number.EPSILON) * 100) / 100 || ""}</h1>}
                                            {/* <input id={"out"+key+date} ref={this.wrapperRef} value={typeof toggledValues.value === "string" ? toggledValues.value : (outValue || "")} item={key} date={date} valuetype="out" onChange={this.onChangeValue} /> || */}
                                        </div>
                                    })}
                                    <div className="datecolumns datecolumns--active1">
                                        <h1>{isNaN(first) ? "" : Math.round((first + Number.EPSILON) * 100) / 100}</h1>
                                        <h1>{isNaN(inSum) ? "" : Math.round((inSum + Number.EPSILON) * 100) / 100}</h1>
                                        <h1>{isNaN(outSum) ? "" : Math.round((outSum + Number.EPSILON) * 100) / 100}</h1>
                                        <h1>{isNaN(countSum) ? "" : Math.round((countSum+(inValue||0)-(outValue||0) + Number.EPSILON) * 100) / 100}</h1>
                                    </div>
                                </div>
                            })}
                        </div> ||
                        this.state.view === "stock" && 
                        <div className="table" key="5">
                            <div className="row row-sticky">
                                <h1 className="stock-nr">Artikkel</h1>
                                <h1 className="stock-name">Nimi</h1>
                                <h1 className="stock-unit">Ühik</h1>
                                <h1 className="stock-count">Laoseis</h1>
                                <h1 className="stock-count">Laoseis FSC</h1>
                            </div>
                            {Object.keys(stock).map(key => {
                                let {name, type, count, unit, fsc} = stock[key]
                                if (articlef && !key.toLowerCase().includes(articlef.toLowerCase())) return
                                if (namef && !name.toLowerCase().includes(namef.toLowerCase())) return
                                if (typef && !type.toLowerCase().includes(typef.toLowerCase())) return
                                return <div className="row" key={key}>
                                    <h1 className="stock-nr">{key}</h1>
                                    <h1 className="stock-name">{name}</h1>
                                    <h1 className="stock-unit">{unit}</h1>
                                    <h1 className="stock-count">{Math.round((count + Number.EPSILON) * 100) / 100}</h1>
                                    <h1 className="stock-count">{Math.round((fsc + Number.EPSILON) * 100) / 100}</h1>
                                    {/* <div className="stock-edit">
                                        <input type="number" value={this.state.editStock[key] || ""} id={key} onChange={this.onChangeStock}/>
                                        {this.state.editStock[key] && <h1 id={key} onClick={this.editStock}>&#9998;</h1>}
                                    </div> */}
                                </div>
                            })}
                        </div> ||
                        this.state.view === "haljala" &&
                        <div className="table" key="6">
                            <div className="row row-sticky">
                                {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key, i) => {
                                    if (i===0) return <h1 key={i} className="empty"></h1>
                                    let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                    if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                    return <h1 key={i} id={date} className={`date ${date === moment().format("DD/MM/YY") ? "currentdate" : ""}`}>{date}</h1>
                                })}
                                <h1 id="summary" className="date">Kokku</h1>
                            </div>
                            {Object.keys(this.state.stock).map((key, i) => {
                                let {name, type, count, unit, log, haljala} = this.state.stock[key]
                                if (!haljala) return
                                if (!Object.keys(haljala).length) return
                                if (articlef) {
                                    if (!key.toLowerCase().includes(articlef.toLowerCase())) return
                                }
                                if (namef) {
                                    if (!name.toLowerCase().includes(namef.toLowerCase())) return
                                }
                                if (typef) {
                                    if (type !== typef) return
                                }
                                // if (!articlef && !namef && !typef) {
                                //     if (i >= this.state.page*50 || i < (this.state.page-1)*50) return
                                // }
                                let inSum = 0
                                return <div className="row" key={key}>
                                    {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key1, i) => {
                                        if (i===0) return <h1 key={i} className="empty">{name}</h1>
                                        let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                        if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                        let inValue
                                        if (fscf === "0") {
                                            inValue = haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0
                                        } else if (fscf === "1") {
                                            inValue = haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0
                                        } else {
                                            inValue = (haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0) + (haljala[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0)
                                        }
                                        inSum += inValue ? Number(inValue) : 0
                                        return <div key={i} className="datecolumns">
                                            <h1 item={key} date={date} valuetype="count" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{isNaN(inValue) ? "" : Math.round((inValue + Number.EPSILON) * 100) / 100 || ""}</h1>
                                        </div>
                                    })}
                                    <div className="datecolumns">
                                        <h1>{isNaN(inSum) ? "" : Math.round((inSum + Number.EPSILON) * 100) / 100}</h1>
                                    </div>
                                </div>
                            })}
                        </div> ||
                        // this.state.view === "tootmine" &&
                        // <div className="table" key="6">
                        //     <div className="row row-sticky">
                        //         {Array((fromf && tof) ? moment(tof).diff(moment(fromf), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(fromf && tof) ? moment(tof).diff(moment(fromf), 'days')+2 : moment().daysInMonth()+1).map((key, i) => {
                        //             if (i===0) return <h1 key={i} className="empty"></h1>
                        //             let date = fromf && tof ? moment(fromf).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                        //             if ((fromf && tof) && moment(date, "DD/MM/YY") > moment(tof)) return
                        //             return <h1 key={i} id={date} className={`date ${date === moment().format("DD/MM/YY") ? "currentdate" : ""}`}>{date}</h1>
                        //         })}
                        //         <h1 id="summary" className="date">Kokku</h1>
                        //     </div>
                        //     {Object.keys(stock).map((key, i) => {
                        //         let {name, count, unit, log, presence} = stock[key]
                        //         if (!presence?.includes("tootmine")) return
                        //         if (namef) {
                        //             if (!name.toLowerCase().includes(namef.toLowerCase())) return
                        //         } else {
                        //             // if (i >= this.state.page*50 || i < (this.state.page-1)*50) return 
                        //         }
                        //         let inSum = 0
                        //         return <div className="row" key={key}>
                        //             {Array((fromf && tof) ? moment(tof).diff(moment(fromf), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(fromf && tof) ? moment(tof).diff(moment(fromf), 'days')+2 : moment().daysInMonth()+1).map((key1, i) => {
                        //                 if (i===0) return <h1 key={i} className="empty">{name}</h1>
                        //                 let date = fromf && tof ? moment(fromf).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                        //                 if ((fromf && tof) && moment(date, "DD/MM/YY") > moment(tof)) return
                        //                 let inValue = 0
                        //                 Object.keys(this.state.restid).forEach(key => {
                        //                     let rest = this.state.restid[key]
                        //                     if (rest.phase !== "Tootmine" || rest.finishDate !== moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")) return
                        //                     inValue += 1
                        //                 })
                        //                 inSum += inValue ? Number(inValue) : 0
                        //                 return <div key={i} className="datecolumns">
                        //                     <h1 item={key} date={date} valuetype="count" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{inValue || ""}</h1>
                        //                 </div>
                        //             })}
                        //             <div className="datecolumns">
                        //                 <h1>{inSum}</h1>
                        //             </div>
                        //         </div>
                        //     })}
                        // </div> ||
                        this.state.view === "myyk" &&
                        <div className="table" key="7">
                            <div className="row row-sticky">
                                {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key, i) => {
                                    if (i===0) return <h1 key={i} className="empty"></h1>
                                    let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                    if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                    return <h1 key={i} id={date} className={`date ${date === moment().format("DD/MM/YY") ? "currentdate" : ""}`}>{date}</h1>
                                })}
                                <h1 id="summary" className="date">Kokku</h1>
                            </div>
                            {Object.keys(this.state.stock).map((key, i) => {
                                let {name, type, count, unit, log, myyk} = this.state.stock[key]
                                if (!myyk) return
                                if (!Object.keys(myyk).length) return
                                if (articlef) {
                                    if (!key.toLowerCase().includes(articlef.toLowerCase())) return
                                }
                                if (namef) {
                                    if (!name.toLowerCase().includes(namef.toLowerCase())) return
                                }
                                if (typef) {
                                    if (type !== typef) return
                                }
                                // if (!articlef && !namef && !typef) {
                                //     if (i >= this.state.page*50 || i < (this.state.page-1)*50) return
                                // }
                                let inSum = 0
                                return <div className="row" key={key}>
                                    {Array((this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).fill(0,0,(this.state.filters.from && this.state.filters.to) ? moment(this.state.filters.to).diff(moment(this.state.filters.from), 'days')+2 : moment().daysInMonth()+1).map((key1, i) => {
                                        if (i===0) return <h1 key={i} className="empty">{name}</h1>
                                        let date = this.state.filters.from && this.state.filters.to ? moment(this.state.filters.from).add(i-1, 'days').format("DD/MM/YY") : moment().startOf('month').add(i-1, 'days').format("DD/MM/YY")
                                        if ((this.state.filters.from && this.state.filters.to) && moment(date, "DD/MM/YY") > moment(this.state.filters.to)) return
                                        let inValue
                                        if (fscf === "0") {
                                            inValue = myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0
                                        } else if (fscf === "1") {
                                            inValue = myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0
                                        } else {
                                            inValue = (myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.a || 0) + (myyk[moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")]?.fsc || 0)
                                        }
                                        inSum += inValue
                                        return <div key={i} className="datecolumns">
                                            <h1 item={key} date={date} valuetype="count" style={{pointerEvents: moment(date, "DD/MM/YY") > moment() ? 'none' : ''}}>{isNaN(inValue) ? "" : Math.round((inValue + Number.EPSILON) * 100) / 100 || ""}</h1>
                                        </div>
                                    })}
                                    <div className="datecolumns">
                                        <h1>{isNaN(inSum) ? "" : Math.round((inSum + Number.EPSILON) * 100) / 100}</h1>
                                    </div>
                                </div>
                            })}
                        </div> ||
                        this.state.view === "jobs" &&
                        <div className="table" key="8">
                            <div className="row">
                                <h1 className="wares-job">Tellimus</h1>
                                <h1 className="wares-8">Laadimise aeg</h1>
                                <h1 className="wares-7">Töötaja tüüp</h1>
                                <h1 className="wares-8">Sisestamise aeg</h1>
                                <h1 className="wares-7">Tellija</h1>
                                <h1 className="wares-10">Töölised</h1>
                                <h1 className="wares-8">Progress</h1>
                                <h1 className="wares-10 wares-left">Toodang</h1>
                                <h1 className="wares-6">FSC</h1>
                                <h1 className="wares-10 wares-left">Silt</h1>
                                <h1 className="wares-10 wares-left">Pakend</h1>
                                <h1 className="wares-10 wares-left">Alus</h1>
                                <h1 className="wares-10 wares-left">Poster</h1>
                                <h1 className="wares-10 wares-left">Pakkematerjal</h1>
                                <h1 className="wares-6">Aktiivne</h1>
                                <h1 className="wares-6">Valmis</h1>
                                <h1 className="wares-10">Aktiivsed töötajad</h1>
                                <h1 className="wares-15">Kommentaarid</h1>
                            </div>
                            {Object.keys(this.state.jobs || {}).sort((a, b) => {
                                if (this.state.jobs[a].finished === this.state.jobs[b].finished) {
                                    if (this.state.jobs[a].duedate > this.state.jobs[b].duedate) return 1
                                    else return -1
                                } else {
                                    if (this.state.jobs[a].finished) return 1
                                    else return -1
                                }
                            }).map(key => {
                                let {type, duedate, product, silt, workers, client, number, pakend, alus, poster, pm, comments, createdAt, active, finished, activeWorkers, progress, fsc} = this.state.jobs[key]
                                let shouldReturn = []
                                if (typef && typef !== type) return
                                if (clientf && !(client || " ").toLowerCase().includes(clientf.toLowerCase())) return
                                if (addedfromf > moment(createdAt).format("YYYY-MM-DD") || addedtof < moment(createdAt).format("YYYY-MM-DD")) return
                                if (duedatefromf > moment(duedate).format("YYYY-MM-DD") || duedatetof < moment(duedate).format("YYYY-MM-DD")) return
                                if (workerf) {
                                    let boolArray = []
                                    if (!workers.length) shouldReturn.push(true)
                                    workers.forEach((key, i) => {
                                        if ((this.state.workers[key].firstName + " " + this.state.workers[key].lastName).toLowerCase().includes(workerf.toLowerCase())) boolArray.push(i)
                                        if (i+1 === workers.length) {
                                            if (!boolArray.length) shouldReturn.push(true)
                                        }
                                    })
                                }
                                if (productf) {
                                    let boolArray = []
                                    if (!Object.keys(product).length) shouldReturn.push(true)
                                    Object.keys(product).forEach((key, i) => {
                                        if (this.state.stock[key]?.name.toLowerCase().includes(productf.toLowerCase())) boolArray.push(i)
                                        if (i+1 === Object.keys(product).length) {
                                            if (!boolArray.length) shouldReturn.push(true)
                                        }
                                    })
                                }
                                if (pakendf) {
                                    let boolArray = []
                                    if (!pakend.length) shouldReturn.push(true)
                                    pakend.forEach((key, i) => {
                                        if (this.state.stock[key]?.name.toLowerCase().includes(pakendf.toLowerCase())) boolArray.push(i)
                                        if (i+1 === pakend.length) {
                                            if (!boolArray.length) shouldReturn.push(true)
                                        }
                                    })
                                }
                                if (siltf) {
                                    let boolArray = []
                                    if (!silt.length) shouldReturn.push(true)
                                    silt.forEach((key, i) => {
                                        if (this.state.stock[key]?.name.toLowerCase().includes(siltf.toLowerCase())) boolArray.push(i)
                                        if (i+1 === silt.length) {
                                            if (!boolArray.length) shouldReturn.push(true)
                                        }
                                    })
                                }
                                if (posterf) {
                                    let boolArray = []
                                    if (!poster.length) shouldReturn.push(true)
                                    poster.forEach((key, i) => {
                                        if (this.state.stock[key]?.name.toLowerCase().includes(posterf.toLowerCase())) boolArray.push(i)
                                        if (i+1 === poster.length) {
                                            if (!boolArray.length) shouldReturn.push(true)
                                        }
                                    })
                                }
                                if (pmf) {
                                    let boolArray = []
                                    if (!pm.length) shouldReturn.push(true)
                                    pm.forEach((key, i) => {
                                        if (this.state.stock[key]?.name.toLowerCase().includes(pmf.toLowerCase())) boolArray.push(i)
                                        if (i+1 === pm.length) {
                                            if (!boolArray.length) shouldReturn.push(true)
                                        }
                                    })
                                }

                                let availableWorkers = 0
                                workers?.forEach(key => {
                                    if (this.state.workers?.[key]?.checkedIn) availableWorkers++
                                })

                                if (shouldReturn.includes(true)) return
                                return <div className={`row${this.state._id === key ? " highlight" : ""}`} id={key} key={key} onClick={this.highlight}>
                                    <h1 className="wares-job">{client+number}</h1>
                                    <h1 className="wares-8">{moment(duedate, "YYYY-MM-DD").format("DD/MM/YYYY")}</h1>
                                    <h1 className="wares-7">{type === "processor" ? "Töötleja" : "Tootja"}</h1>
                                    <h1 className="wares-8">{moment(createdAt).format("DD/MM/YYYY")}</h1>
                                    <h1 className="wares-7">{client}</h1>
                                    <h1 className="wares-10">{(workers || []).map(worker => this.state.workers[worker] ? `${this.state.workers[worker]?.firstName || ""} ${this.state.workers[worker]?.lastName || ""}\n` : `${this.state.teams[worker]?.name || ""}\n`)}</h1>
                                    <h1 className="wares-8">{type === "processor" ? "" : type === "production" ? `${progress.ovr?.[Object.keys(product)[0]] || 0}(+${activeWorkers.length}) / ${product[Object.keys(product)[0]]}` : ""}</h1>
                                    <h1 className="wares-10 wares-left">{Object.keys(product || {}).map(product => `${this.state.stock[product]?.name || ""}\n`)}</h1>
                                    <h1 className="wares-6">{fsc ? "Jah" : "Ei"}</h1>
                                    <h1 className="wares-10 wares-left">{(silt || []).map(silt => `${this.state.stock[silt]?.name || ""}\n`)}</h1>
                                    <h1 className="wares-10 wares-left">{(pakend || []).map(pakend => `${this.state.stock[pakend]?.name || ""}\n`)}</h1>
                                    <h1 className="wares-10 wares-left">{(alus || []).map(alus => `${this.state.stock[alus]?.name || ""}\n`)}</h1>
                                    <h1 className="wares-10 wares-left">{(poster || []).map(poster => `${this.state.stock[poster]?.name || ""}\n`)}</h1>
                                    <h1 className="wares-10 wares-left">{(pm || []).map(pm => `${this.state.stock[pm]?.name || ""}\n`)}</h1>
                                    <h1 className="wares-6">{active ? "Jah" : "Ei"}</h1>
                                    <h1 className="wares-6">{finished ? "Jah" : "Ei"}</h1>
                                    <h1 className="wares-10">{availableWorkers}</h1>
                                    <h1 className="wares-15">{comments}</h1>
                                </div>
                            })}
                        </div> ||
                        this.state.view === "ship" &&
                        <div className="table" key="9">
                            <div className="row">
                                <h1 className="wares-koorem">Veoki number</h1>
                                <h1 className="wares-8">Lisatud üksused</h1>
                                <h1 className="wares-7">Tõstukijuht</h1>
                                <h1 className="wares-8">Alustamise aeg</h1>
                            </div>
                            {Object.keys(this.state.shipments || {}).map(key => {
                                let {content, tempName, driver, createdAt} = this.state.shipments[key]
                                
                                return <div className={`row${this.state._id === key ? " highlight" : ""}`} id={key} key={key} onClick={this.highlight}>
                                    <h1 className="wares-koorem">{tempName}</h1>
                                    <h1 className="wares-8">{content.length || 0}</h1>
                                    <h1 className="wares-7">{driver.name || ""}</h1>
                                    <h1 className="wares-8">{moment(createdAt).format("DD/MM/YYYY")}</h1>
                                </div>
                            })}
                        </div> ||
                        this.state.view === "test" &&
                        <div className="test" key="10">
                            <div className="test-barcodes">
                                <h1>Koodide massprintimine</h1>
                                <div>
                                    <span>Alates:</span>
                                    <input type="text" id="test-print-start" />
                                    <span>Kuni:</span>
                                    <input type="text" id="test-print-end" />
                                    <a onClick={this.printSelections}>Prindi</a>
                                </div>
                            </div>
                        </div>/*||
                        this.state.view === "finished" &&
                        <div className="table centered" key="8">
                            <div className="row">
                                <h1 className="wares-koorem">Resti nr</h1>
                                <h1 className="wares-6">Koorma nr</h1>
                                <h1 className="wares-7">Lõhkuja</h1>
                                {this.state.showDates && <h1 className="wares-7">Lõhkumine</h1>}
                                <h1 className="wares-6">Sortiment</h1>
                                <h1 className="wares-7">Artikkel</h1>
                                <h1 className="wares-7">Tootmisjärk</h1>
                                {this.state.showDates && <h1 className="wares-7">Kuivatisse</h1>}
                                {this.state.showDates && <h1 className="wares-7">Kuivatist välja</h1>}
                                {this.state.showDates && <h1 className="wares-10">Lõpetamine</h1>}
                                {/* <h1 className="wares-7">Lõpetatud</h1> }
                                {/* <h1 className="wares-7">Tõstukijuht</h1> }
                            </div>
                            {Object.keys(this.state.restid).map(key => {
                                let {chopper, finished, parent, phase, restNr, sortiment, article, driver, location, chopStart, dryStart, dryEnd, finishDate} = this.state.restid[key]
                                if (!finished) return
                                if (restnrf && !(restNr||"").toLowerCase().includes(restnrf.toLowerCase())) return
                                if (kooremf && !((this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem)||"").toLowerCase().includes(kooremf.toLowerCase())) return
                                if (chopperf && !(chopper||"").toLowerCase().includes(chopperf.toLowerCase())) return
                                if (sortimentf && !(sortiment||"").toLowerCase().includes(sortimentf.toLowerCase())) return
                                if (phasef && !(phase||"").toLowerCase().includes(phasef.toLowerCase())) return
                                if (driverf && !(driver?.name||"").toLowerCase().includes(driverf.toLowerCase())) return
                                if (zonef && !(location.mainzone||"Kuivati").toLowerCase().includes(zonef.toLowerCase())) return
                                if (locationf && !((location.subzone||location.chamber)||"").toLowerCase().includes(locationf.toLowerCase())) return
                                
                                let chopStartDate = moment(chopStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                if (chopfromf > chopStartDate || choptof < chopStartDate) return
                                let dryStartDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                if (drystartfromf > dryStartDate || drystarttof < dryStartDate) return
                                let dryEndDate = moment(dryStart, "DD/MM/YYYY").format("YYYY-MM-DD")
                                if (drystartfromf > dryEndDate || drystarttof < dryEndDate) return
                                let finishedDate = moment(finishDate, "DD/MM/YYYY").format("YYYY-MM-DD")
                                if (finishedfromf > finishedDate || finishedtof < finishedDate) return

                                return <div className="row" key={key}>
                                    <h1 className="wares-koorem">{restNr}</h1>
                                    <h1 className="wares-6">{this.state.data[parent]?.koorem || this.state.archive[parent]?.koorem}</h1>
                                    <h1 className="wares-7">{chopper}</h1>
                                    {this.state.showDates && <h1 className="wares-7">{chopStart}</h1>}
                                    <h1 className="wares-6">{sortiment}</h1>
                                    <h1 className="wares-7">{article}</h1>
                                    <h1 className="wares-7">{phase}</h1>
                                    {this.state.showDates && <h1 className="wares-7">{dryStart}</h1>}
                                    {this.state.showDates && <h1 className="wares-7">{dryEnd}</h1>}
                                    {this.state.showDates && <h1 className="wares-10">{finishDate}</h1>}
                                    {/* <h1 className="wares-7">{finished ? "Jah" : "Ei"}</h1> }
                                    {/* <h1 className="wares-7">{driver?.name || ""}</h1> }
                                </div>
                            })}
                        </div>*/}
                    </div>
                    {(this.state.modal === "edit" || this.state.modal === "new") && 
                    <div className="modal">
                        <div className={`modal-content ${this.state.modal === 'edit' ? "modal-content--edit" : ""}`}>
                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                            <div className="edit-row">
                                <div>
                                    <h1>Harvester</h1>
                                    {this.state.modal === "edit" && 
                                    <input type="checkbox" className="switchbutton" checked={harvester} disabled /> ||
                                    <input type="checkbox" id="harvester" className="switchbutton" checked={harvester} onChange={this.toggleHarvester} />}
                                </div>
                                <div>
                                    <h1>Saatja {this.state.modal === "new" && <i className="fas fa-pencil-alt" onClick={this.toggleSender}/>}</h1>
                                    {this.state.senderToggle && 
                                    <input type="text" id="sender" value={sender || ""} onChange={this.onChange} /> ||
                                    <select id="sender" value={sender || ""} onChange={this.onChange} >
                                        <option value=""></option>
                                        {Object.keys(settings.wares?.senders).map(key => {
                                            return <option value={key} key={key}>{key}</option>
                                        })}
                                    </select>}
                                </div>
                                <div>
                                    <h1>Veoseleht</h1>
                                    <input type="text" id="veoseleht" value={veoseleht} onChange={this.onChange} />
                                </div>
                                <div>
                                    <h1>Saadetud</h1>
                                    {this.state.modal === "edit" &&
                                    <input type="number" value={sent} disabled /> ||
                                    <input type="number" id="sent" value={sent} onChange={this.onChange} />}
                                </div>
                                {harvester && <div>
                                    <h1>Materjal</h1>
                                    {this.state.modal === "edit" &&
                                    <input type="text" value={material} disabled /> ||
                                    <select id="material" value={material} onChange={this.onChange}>
                                        <option value=""></option>
                                        {Object.keys(settings.wares?.materials || {}).map(key => {
                                            return <option value={key} key={key}>{key}</option>
                                        })}
                                    </select>}
                                </div>}
                                <div>
                                    <h1>FSC Sertifitseeritud</h1>
                                    {this.state.modal === "edit" && 
                                    <input type="checkbox" className="switchbutton" checked={fsc} disabled /> ||
                                    <input type="checkbox" id="fsc" className="switchbutton" checked={fsc} onChange={this.toggleFSC} />}
                                </div>
                                <div>
                                    <h1>Pakkide arv</h1>
                                    {this.state.modal === "edit" &&
                                    <input type="text" value={packages} disabled /> ||
                                    <div className="changable">
                                        <i className="fas fa-minus-circle" operator="minus" onClick={this.changePackages}/>
                                        <input type="number" id="packages" value={packages || ""} disabled/>
                                        <i className="fas fa-plus-circle" operator="plus" onClick={this.changePackages}/>
                                    </div>}
                                </div>
                                {/* {Array(Object.keys(dimensions || {}).length*4 || 4).fill(0,0,Object.keys(dimensions || {}).length*4 || 4).map((key, i) => {
                                    if (i%4 === 0) {
                                        return <div className="height-container" key={i}>
                                            <span>{Math.ceil(i/4)+1}</span>
                                            <h1>Kõrgus</h1>
                                            <div className="changable">
                                                {this.state.modal === "new" && <i className="fas fa-minus-circle" field="height" line={Math.ceil(i/4)+1} operator="minus" onClick={this.changeDimension}/>}
                                                {this.state.modal === "edit" &&
                                                <input type="text" value={dimensions[Math.ceil(i/4)+1]?.height || ""} disabled /> ||
                                                <input type="text" field="height" value={dimensions[Math.ceil(i/4)+1]?.height || ""} line={Math.ceil(i/4)+1} onChange={this.onChangeDimension} />}
                                                {this.state.modal === "new" && <i className="fas fa-plus-circle" field="height" line={Math.ceil(i/4)+1} operator="plus" onClick={this.changeDimension}/>}
                                            </div>
                                        </div>
                                    } else if (i%4 === 1) {
                                        return <div key={i}>
                                            <h1>Laius</h1>
                                            <div className="changable">
                                                {this.state.modal === "new" && <i className="fas fa-minus-circle" field="width" line={Math.ceil(i/4)} operator="minus" onClick={this.changeDimension}/>}
                                                {this.state.modal === "edit" &&
                                                <input type="text" value={dimensions[Math.ceil(i/4)]?.width || ""} disabled /> ||
                                                <input type="text" field="width" value={dimensions[Math.ceil(i/4)]?.width || ""} line={Math.ceil(i/4)} onChange={this.onChangeDimension} />}
                                                {this.state.modal === "new" && <i className="fas fa-plus-circle" field="width" line={Math.ceil(i/4)} operator="plus" onClick={this.changeDimension}/>}
                                            </div>
                                        </div>
                                    } else if (i%4 === 2) {
                                        return <div key={i}>
                                            <h1>Pikkus</h1>
                                            <div className="changable">
                                                {this.state.modal === "new" && <i className="fas fa-minus-circle" field="length" line={Math.ceil(i/4)} operator="minus" onClick={this.changeDimension}/>}
                                                {this.state.modal === "edit" &&
                                                <input type="text" value={dimensions[Math.ceil(i/4)]?.length || ""} disabled /> ||
                                                <input type="text" field="length" value={dimensions[Math.ceil(i/4)]?.length || ""} line={Math.ceil(i/4)} onChange={this.onChangeDimension} />}
                                                {this.state.modal === "new" && <i className="fas fa-plus-circle" field="length" line={Math.ceil(i/4)} operator="plus" onClick={this.changeDimension}/>}
                                            </div>
                                        </div>
                                    } else if (i%4 === 3) {
                                        return <div key={i}>
                                            {this.state.modal === "new" && (i !== 3 && <i className="fas fa-trash-alt delete-line" line={Math.ceil(i/4)} onClick={this.deleteLine}/>)}
                                            <h1>Koefitsient</h1>
                                            {this.state.modal === "edit" &&
                                            <input type="text" value={dimensions[Math.ceil(i/4)]?.coef || ""} disabled /> ||
                                            <input type="text" field="coef" value={dimensions[Math.ceil(i/4)]?.coef || ""} line={Math.ceil(i/4)} onChange={this.onChangeDimension} />}
                                        </div>
                                    } 
                                })} */}
                                {this.state.modal === "new" && Array(packages || 0).fill(0).map((key, i) => {
                                    let height = dimensions[i+1]?.height || 0
                                    let width = dimensions[i+1]?.width || 235
                                    let length = dimensions[i+1]?.length || 300
                                    let coef = dimensions[i+1]?.coef || 0
                                    return <ins key={i}>
                                        <div className="height-container" key={`height${i}`}>
                                            <span>{i+1}</span>
                                            <h1>Kõrgus</h1>
                                            <div className="changable">
                                                <i className="fas fa-minus-circle" field="height" line={i+1} operator="minus" onClick={this.changeDimension}/>
                                                {/* {this.state.modal === "edit" &&
                                                <input type="text" value={height || ""} disabled /> || */}
                                                <input type="text" field="height" value={height || ""} line={i+1} onChange={this.onChangeDimension} />
                                                <i className="fas fa-plus-circle" field="height" line={i+1} operator="plus" onClick={this.changeDimension}/>
                                            </div>
                                        </div>
                                        <div key={`width${i}`}>
                                            <h1>Laius</h1>
                                            <div className="changable">
                                                <i className="fas fa-minus-circle" field="width" line={i+1} operator="minus" onClick={this.changeDimension}/>
                                                {/* {this.state.modal === "edit" &&
                                                <input type="text" value={width || ""} disabled /> || */}
                                                <input type="text" field="width" value={width || ""} line={i+1} onChange={this.onChangeDimension} />
                                                <i className="fas fa-plus-circle" field="width" line={i+1} operator="plus" onClick={this.changeDimension}/>
                                            </div>
                                        </div>
                                        <div key={`length${i}`}>
                                            <h1>Pikkus</h1>
                                            <div className="changable">
                                                <i className="fas fa-minus-circle" field="length" line={i+1} operator="minus" onClick={this.changeDimension}/>
                                                {/* {this.state.modal === "edit" &&
                                                <input type="text" value={length || ""} disabled /> || */}
                                                <input type="text" field="length" value={length || ""} line={i+1} onChange={this.onChangeDimension} />
                                                <i className="fas fa-plus-circle" field="length" line={i+1} operator="plus" onClick={this.changeDimension}/>
                                            </div>
                                        </div>
                                        <div key={`coef${i}`}>
                                            {/* {this.state.modal === "new" && (i !== 3 && <i className="fas fa-trash-alt delete-line" line={i+1} onClick={this.deleteLine}/>)} */}
                                            <h1>Koefitsient</h1>
                                            {/* {this.state.modal === "edit" &&
                                            <input type="text" value={coef || ""} disabled /> || */}
                                            <input type="text" field="coef" value={coef || ""} line={i+1} onChange={this.onChangeDimension} />
                                        </div>
                                    </ins>
                                })}
                                
                                <div className="received-container">
                                    <h1>Vastuvõetud</h1>
                                    {(received || Object.keys(dimensions || {}).length) && 
                                    <input type="number" id="received" value={this.state.modal === "edit" ? received : (this.state.sum ? this.state.sum.toFixed(2) : received ? parseFloat(received).toFixed(2) : 0)} disabled /> ||
                                    <input type="number" id="receivedManual" value={receivedManual || ""} onChange={this.onChange} />}
                                </div>
                                <div>  
                                    <h1>Praak</h1>
                                    <input type="number" id="defect" value={defect} onChange={this.onChange} />
                                </div>
                                <div>
                                    <h1>Tsoon</h1>
                                    <select id="zone" value={zone} onChange={this.onChange}>
                                        <option value=""></option>
                                        {(Object.keys(settings.wares?.zones) || {}).map(key => {
                                            return <option value={key} key={key}>{key}</option>
                                        })}
                                    </select>
                                </div>
                                {this.state.modal === "edit" &&
                                <div>
                                    <h1>Lõhkuja</h1>
                                    <select id="chopper" value={chopper} onChange={this.onChange}>
                                        <option value=""></option>
                                        {(settings.wares?.choppers || []).map(key => {
                                            return <option value={key} key={key}>{key}</option>
                                        })}
                                    </select>
                                </div>}
                                {(this.state.modal === "edit" && harvester) &&
                                <div>
                                    <h1>Sortiment (cm)</h1>
                                    <select id="sortiment" value={sortiment} onChange={this.onChange}>
                                        <option value=""></option>
                                        {Object.keys(settings.wares?.materials?.[this.state.modalData.material || ""]?.sortiments || {}).map(key => {
                                            return <option value={key} key={key}>{key}</option>
                                        })}
                                    </select>
                                </div>}
                                {(this.state.modal === "edit" && harvester) &&
                                <div>
                                    <h1>Maha lõhkumine</h1>
                                    <select id="maha" value={maha ? "yes" : "no"} onChange={this.onChange}>
                                        <option value="no">Ei</option>
                                        <option value="yes">Jah</option>
                                    </select>
                                </div>}
                                {(this.state.data?.[this.state._id]?.chopper && this.state.data?.[this.state._id]?.sortiment) && Object.keys(settings?.wares?.logs || {}).map(key => {
                                    if (this.state.modal !== "edit") return
                                    return <div key={key}>
                                        <h1>{key}</h1>
                                        <div className="changable">
                                            <i className="fas fa-minus-circle" field={key} operator="minus" onClick={this.changeLogs}/>
                                            <input type="number" id={key} value={typeof logs?.[key] === "number" ? logs?.[key] : logs?.[key] ? logs[key] : 0} onChange={this.onChangeLogs}/>
                                            <i className="fas fa-plus-circle" field={key} operator="plus" onClick={this.changeLogs}/>
                                        </div>
                                    </div>
                                })}
                                <div>
                                    {(this.state.data?.[this.state._id]?.chopper && this.state.data?.[this.state._id]?.sortiment) && <h1>Restid <i className={`fas fa-${this.state.restToggle ? "minus" : "plus"}-circle${((this.state.modalData.maht <= (this.state.modalData.received - this.state.modalData.received*1.1)) ? " disabled" : "")}`} onClick={this.togglenewAlus} /></h1>}
                                    {this.state.restToggle && <div>
                                        <div className="changable changable-restid">
                                            <h1>Resti nr</h1>
                                            <input type="number" id="rest" value={this.state.newAlus?.rest || ""} onChange={this.onChangeRest} />
                                            {/* {this.state.rest > (restid?.length || 0) && <i className="fas fa-minus-circle" operator="minus" onClick={this.changeRest} />}
                                            <input type="text" id="rest" value={this.state.rest} onChange={this.onChangeRest} />
                                            <i className="fas fa-plus-circle" operator="plus" onClick={this.changeRest} /> */}
                                        </div>
                                        {!harvester && <div className="changable changable-restid">
                                            <h1>Resti sisu</h1>
                                            <select id="content" value={this.state.newAlus?.content || ""} onChange={this.onChangeRest}>
                                                <option value=""></option>
                                                {Object.keys(settings.wares?.handcut || {}).map(key => {
                                                    return <option value={settings.wares?.handcut[key].article} key={settings.wares?.handcut[key].article}>{key}</option>
                                                })}
                                            </select>
                                        </div>}
                                        {harvester && <div className="changable changable-restid">
                                            <h1>Sortiment</h1>
                                            <select id="sortiment2" value={this.state.newAlus?.sortiment2 || sortiment} onChange={this.onChangeRest}>
                                                {Object.keys(settings.wares?.materials?.[this.state.modalData.material || ""]?.sortiments || {}).map(key => {
                                                    return <option value={key} key={key}>{key}</option>
                                                })}
                                            </select>
                                        </div>}
                                        <div className="changable changable-restid">
                                            <h1>Tsoon</h1>
                                            <select id="mainzone" value={this.state.newAlus?.mainzone || ""} onChange={this.onChangeRest}>
                                                <option value=""></option>
                                                {Object.keys(settings.wares?.zones || {}).map(key => {
                                                    return <option value={key} key={key}>{key}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="changable changable-restid">
                                            <h1>Asukoht</h1>
                                            <select id="subzone" value={this.state.newAlus?.subzone || ""} onChange={this.onChangeRest}>
                                                <option value=""></option>
                                                {(settings.wares?.zones?.[this.state.newAlus?.mainzone || ""] || []).map(key => {
                                                    return <option value={key} key={key}>{key}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="changable changable-restid">
                                            <a className={`${(!this.state.newAlus?.rest || !this.state.newAlus?.mainzone || !this.state.newAlus?.subzone) ? "submit-rest--disabled" : ""}`} onClick={this.saveRest}>Lisa rest</a>
                                        </div>
                                    </div>}
                                    <div className="restlist">
                                        {!!restid?.length && restid.map(key => {
                                            let rest = this.state.restid[key]
                                            return <div key={key}>
                                                <h1>{rest?.restNr}</h1>
                                                <h1>{rest?.finished ? rest.phase : `${rest?.location.mainzone || "Kuivati"} - ${rest?.location.subzone || rest?.location.chamber}`}</h1>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div>
                                    <h1>Kommentaarid</h1>
                                    <textarea id="comments" value={comments} onChange={this.onChange} />
                                </div>
                            </div>
                            {this.state.modal !== "new" && <a className="finish" onClick={this.finish}>Märgi lõpetatuks</a>}
                            <a className="submit" onClick={this.save}>Salvesta</a>
                        </div>
                        {this.state.confirmFinish === "finishys" && <div className="modal1">
                            <div className="modal1-content">
                                <h1>Kas oled kindel, et soovid märkida koorma lõpetatuks?</h1>
                                <a className="no" id="no" onClick={this.finish}>Ei</a>
                                <a className="yes" id="yes" onClick={this.finish}>Jah</a>
                            </div>
                        </div>}
                    </div> ||
                    // this.state.modal === "rest" &&
                    // <div className="modal">
                    //     <div className="modal-content">
                    //         <span className="modal-close" onClick={this.closeModal}>&times;</span>
                    //         <div className="edit-row">
                    //             <div>
                    //                 <h1>Resti nr</h1>
                    //                 <input type="text" id="restNr" value={restNr} onChange={this.onChange} />
                    //             </div>
                    //             <div>
                    //                 <h1>Tsoon</h1>
                    //                 <select id="mainzone" value={location.mainzone} onChange={this.onChangeLocation}>
                    //                     <option value=""></option>
                    //                     {Object.keys(settings.wares?.zones || {}).map(key => {
                    //                         return <option value={key} key={key}>{key}</option>
                    //                     })}
                    //                 </select>
                    //             </div>
                    //             <div style={{marginBottom: "6rem"}}>
                    //                 <h1>Asukoht</h1>
                    //                 <select id="subzone" value={location.subzone} onChange={this.onChangeLocation}>
                    //                     <option value=""></option>
                    //                     {(settings.wares?.zones?.[location.mainzone] || []).map(key => {
                    //                         return <option value={key} key={key}>{key}</option>
                    //                     })}
                    //                 </select>
                    //             </div>
                    //         </div>
                    //         <a className="myyk" id="myyk" onClick={this.finishRest}>Müük</a>
                    //         <a className="haljala" id="haljala" onClick={this.finishRest}>Haljala</a>
                    //         <a className="tootmine" id="tootmine" onClick={this.finishRest}>Tootmine</a>
                    //         <a className={`submit${(!restNr || !location.mainzone || !location.subzone) ? " submit--disabled" : ""}`} onClick={this.saveRest}>Salvesta</a>
                    //     </div>
                    // </div> ||
                    this.state.modal === "dry" &&
                    <div className="modal">
                        <div className="modal-content">
                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                            {this.state.modalData?.chamber ? <>
                                <h1 className="dry-query">Kas kamber {this.state.modalData.chamber} alustas või lõpetas programmi?</h1>
                                <a className="submit dry-start" id="start" onClick={this.drySubmit}>Alustas</a>
                                <a className="submit dry-end" id="end" onClick={this.drySubmit}>Lõpetas</a>
                            </> :
                            <>
                                <h1 className="dry-query">Millise kambriga on tegu?</h1>
                                <div className="edit-row">
                                    <div style={{marginTop: "-6rem", marginBottom: "3rem"}}>
                                        <h1>Kamber</h1>
                                        <select id="chamberselect" defaultValue="">
                                            <option value=""></option>
                                            {(settings.wares?.chambers || []).map(key => {
                                                return <option value={key} key={key}>{key}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <a className="submit" id="continue" onClick={this.drySubmit}>Edasi</a>
                            </>}
                        </div>
                    </div> ||
                    this.state.modal === "newshipment" &&
                    <div className="modal">
                        <div className="modal-content">
                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                            <h1 className="dry-query">Sisesta veoki number</h1>
                            <div className="edit-row">
                                <div style={{marginTop: "-6rem", marginBottom: "3rem"}}>
                                    <input className="input2" id="tempname" defaultValue="" />
                                </div>
                            </div>
                            <a className="submit" id="start" onClick={this.startShipment}>Edasi</a>
                        </div>
                    </div> ||
                    this.state.modal === "shipment" &&
                    <div className="modal">
                        <div className="modal-content modal-content-ship">
                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                            <h1 className="ship-title">Veoki number: {shipments[this.state.modalData?.ship]?.tempName || ""}</h1>
                            <div className="ship-content">
                                {shipments[this.state.modalData?.ship]?.content.map(unit => {
                                    return <span id={unit} onClick={this.removeUnit}>{this.state.restid[unit]?.restNr || this.state.alused[unit]?.restNr}</span>
                                })}
                            </div>
                            <a id="type" className="submit submit-left" onClick={this.typeCode}>Sisestan käsitsi</a>
                            <a className="submit" onClick={this.finishShipment}>Laadimine valmis</a>
                        </div>
                        {(this.state.modalData.modal) && <div className="modal1">
                            {this.state.modalData.modal === "remove" && <div className="modal1-content">
                                <h1>Kas soovid eemaldada aluse {this.state.restid[this.state.modalData.unit]?.restNr || this.state.alused[this.state.modalData.unit]?.restNr} laadungist?</h1>
                                <a className="no" id="no" onClick={this.removeUnit}>Ei</a>
                                <a className="yes" id="yes" onClick={this.removeUnit}>Jah</a>
                            </div> ||
                            this.state.modalData.modal === "type" && <div className="modal1-content">
                                <h1>Sisesta aluse number</h1>
                                <input id="type-input" defaultValue="" />
                                <a className="no" id="no" onClick={this.typeCode}>Tagasi</a>
                                <a className="yes" id="yes" onClick={this.typeCode}>Lisa</a>
                            </div> ||
                            this.state.modalData.modal === "add" && <div className="modal1-content">
                                <h1>Kas soovid lisada aluse {this.state.modalData.unit} laadungisse?</h1>
                                <a className="no" id="no" onClick={this.addUnit}>Ei</a>
                                <a className="yes" id="yes" onClick={this.addUnit}>Jah</a>
                            </div> ||
                            this.state.modalData.modal === "error" && <div className="modal1-content">
                                <h1>{this.state.modalData.error.error === "No such unit" ? `Alust ${this.state.modalData.error.unit} ei leitud!` : this.state.modalData.error.error === "Duplicate unit" ? `Alus ${this.state.modalData.error.unit} on juba lisatud!` : ""}</h1>
                                <a className="ok" onClick={this.errorOK}>OK</a>
                            </div> ||
                            this.state.modalData.modal === "finish" && <div className="modal1-content modal1-extra">
                                <h1>Sisesta arve number</h1>
                                <input id="invoice" defaultValue={this.state.modalData.finish?.invoice || ""} />
                                <h1 className="second-h1">Kuhu koorem läheb?</h1>
                                <select id="ship-type" defaultValue={this.state.modalData.finish?.type || ""}>
                                        <option value=""></option>
                                        <option value="myyk">Müüki</option>
                                        <option value="haljala">Haljalasse</option>
                                    </select>
                                <a className="no" id="no" onClick={this.finishShipment}>Tagasi</a>
                                <a className="yes" id="yes" onClick={this.finishShipment}>Edasi</a>
                            </div> ||
                            this.state.modalData.modal === "confirm" && <div className="modal1-content">
                                <h1>Arve number: {this.state.modalData.finish.invoice}</h1>
                                <h1 className="second-h1">Laadungi tüüp: {this.state.modalData.finish.type === "myyk" ? "Müüki" : this.state.modalData.finish.type === "haljala" ? "Haljalasse" : ""}</h1>
                                <a className="no" id="return" onClick={this.finishShipment}>Tagasi</a>
                                <a className="yes" id="confirm" onClick={this.finishShipment}>Valmis</a>
                            </div>}
                        </div>}
                    </div> ||
                    this.state.modal === "job" &&
                    <div className="job-modal">
                        <div className="job-modal-content">
                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                            <div className="edit-job">
                                <h1>Töötaja tüüp</h1>
                                <input id="type" value={this.state.jobs?.[this.state.modal.id]?.type} onChange={this.onChangeJobModal} disabled />
                                <h1>Laadimise kuupäev</h1>
                                <input type="date" id="duedate" value={jobs[this.state._id]?.duedate || this.state.jobs?.[this.state.modal.id]?.duedate || ""} disabled />
                                <h1>Toodang</h1>
                                {Object.keys(jobs[this.state._id]?.product || {}).map(key => {
                                    let count = jobs[this.state._id].product[key]
                                    return <span className="jobitem" key={key}>
                                        <p>{count === "side" ? "Kõrvaltoode" : `${count}x`} {this.state.stock[key]?.name}</p>
                                    </span>
                                })}
                                {jobs[this.state._id].type === "production" && <h1>Tellija</h1>}
                                {jobs[this.state._id].type === "production" && <input type="text" id="client" value={jobs[this.state._id]?.client || this.state.jobs?.[this.state.modal.id]?.client} disabled />}
                                {jobs[this.state._id].type === "production" && <h1>Tooteid alusel</h1>}
                                {jobs[this.state._id].type === "production" && <input type="text" id="per" value={jobs[this.state._id]?.per || this.state.jobs?.[this.state.modal.id]?.per} disabled />}
                                {jobs[this.state._id].type === "production" && <h1>Silt</h1>}
                                {jobs[this.state._id].type === "production" && (jobs[this.state._id]?.silt || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                    </span>
                                })}
                                {jobs[this.state._id].type === "production" && <h1>Pakend</h1>}
                                {jobs[this.state._id].type === "production" && (jobs[this.state._id]?.pakend || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                    </span>
                                })}
                                {(jobs[this.state._id].type === "production" && !(jobs[this.state._id]?.pakend || []).length) && <a className="add-item" id="pakend" onClick={this.toggleModal}>Lisa pakend</a>}
                                <h1>Alus</h1>
                                {(jobs[this.state._id]?.alus || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                    </span>
                                })}
                                {jobs[this.state._id].type === "production" && <h1>Poster</h1>}
                                {jobs[this.state._id].type === "production" && (jobs[this.state._id]?.poster || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                    </span>
                                })}
                                <h1>Pakkematerjal</h1>
                                {(jobs[this.state._id]?.pm || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                    </span>
                                })}
                                <h1>Töölised</h1>
                                {(jobs[this.state._id]?.workers || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        {this.state.workers[key] ? <p>{this.state.workers[key].firstName} {this.state.workers[key].lastName}</p> :
                                        <p>{this.state.teams[key].name}</p>}
                                    </span>
                                })}
                                <h1>Kommentaarid</h1>
                                <textarea id="comments" value={jobs[this.state._id].comments} disabled />
                            </div>
                            <div className="edit-job-right">
                                <h1>Tellimus: <b>{jobs[this.state._id]?.client+jobs[this.state._id]?.number}</b></h1>
                                <h1>Aktiivne</h1>
                                <span className="jobitem">
                                    <p type="checkbox" checked={jobs[this.state._id].active}>{jobs[this.state._id].active ? "Jah" : "Ei"}</p>
                                </span>
                                <h1>Aktiivsed töölised</h1>
                                {jobs[this.state._id]?.activeWorkers.map(key => {
                                    return <p className="active-worker" key={key} id={key}>{this.state.workers[key] ? `${this.state.workers[key].firstName} ${this.state.workers[key].lastName}` : `${this.state.teams[key].lastName}`}</p>
                                })}
                                <h1>Töötajate panus</h1>
                                <div className="worker-contribution">
                                    {Object.keys(jobs[this.state._id]?.progress).sort((key) => {
                                        if (key === "ovr") return -1
                                        else return 1
                                    }).map(key => {
                                        // return <div key={key}>
                                        //     <h2>{key === "ovr" ? "Kokku" : `${this.state.workers[key].firstName} ${this.state.workers[key].lastName}`}</h2>
                                        //     {Object.keys(jobs[this.state._id].progress[key]).map(key1 => {
                                        //         return <div key={key1}>
                                        //             <p>{this.state.stock[key1]?.name} - </p>
                                        //             <span> {jobs[this.state._id].progress[key][key1]}</span>
                                        //         </div>
                                        //     })}
                                        // </div>
                                        return <div key={key}>
                                            <h2>{key === "ovr" ? "Kokku" : (this.state.workers[key] ? `${this.state.workers[key].firstName} ${this.state.workers[key].lastName}` : `${this.state.teams[key].name}`)}</h2>
                                            {Object.keys(jobs[this.state._id]?.progress[key]).map(key1 => {
                                                return <div key={key1}>
                                                    <p>{this.state.stock[key1]?.name} - </p>
                                                    <span id={key} onClick={this.toggleAlused}> {jobs[this.state._id]?.progress[key][key1]}</span>
                                                </div>
                                            })}
                                            {this.state.toggledAlused?.includes(key) &&
                                            <div className="worker-contribution-alused">
                                                {jobs[this.state._id]?.alused?.[key]?.map(alus => {
                                                    return <p>{alus}</p>
                                                })}
                                            </div>}
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className={`scan${scan.toggled ? " scan--active" : ""}`}>
                        <span className="scan-close" onClick={this.closeScan}>&times;</span>
                        {scan.active && <span className="scan-back" onClick={this.openScan}><i className="fas fa-angle-left" /></span>}
                        {scan.active ? <h1 className="scan-header">{scan.restNr}</h1> : (scan.manual ? <><input type="number" id="scan-input" className="scan-input" /><a className="scan-button" onClick={this.submitScan}><i className="fas fa-search" /></a></> : <h1 className="scan-hint">Skaneeri resti kood</h1>)}
                        {!scan.active && <a className="submit" onClick={this.newAlus}>Uus alus</a>}
                        {!scan.active && <a className="submit submit-align-left" onClick={this.toggleManualScan}>{scan.manual ? "Skaneerin" : "Sisestan käsitsi"}</a>}
                        {scan.active && <div className="scan-container">
                            <div className="scan-edit">
                                <div>
                                    <h1>Tootmisjärk</h1>
                                    <select id="phase" value={scan.phase || ""} onChange={this.onChangeScan}>
                                        <option value=""></option>
                                        <option value="Märg ladu">Märg ladu</option>
                                        <option value="Töötleja">Töötleja</option>
                                        <option value="Kuivati">Kuivati</option>
                                        <option value="Kuiv ladu">Kuiv Ladu</option>
                                        <option value="Tootja">Tootja</option>
                                        <option value="Valmis toodang">Valmis toodang</option>
                                    </select>
                                </div>
                                <div>
                                    <h1>Tsoon</h1>
                                    <select id="mainzone" value={scan.location?.mainzone || ""} onChange={this.onChangeScan}>
                                        <option value=""></option>
                                        {scan.phase !== "Kuivati" ? Object.keys(settings.wares?.zones || {}).map(key => {
                                            return <option value={key} key={key}>{key}</option>
                                        }) : <option value="Kuivati">Kuivati</option>}
                                    </select>
                                </div>
                                <div>
                                    <h1>Asukoht</h1>
                                    <select id="subzone" value={scan.location?.subzone || ""} onChange={this.onChangeScan}>
                                        <option value=""></option>
                                        {(scan.location?.mainzone === "Kuivati" || scan.phase === "Kuivati") ?
                                        (settings.wares?.chambers || []).map(key => {
                                            return <option value={key} key={key}>{key}</option>
                                        }) : (settings.wares?.zones?.[scan.location?.mainzone] || []).map(key => {
                                            return <option value={key} key={key}>{key}</option>
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <h1>Toode</h1>
                                    <h1>{scan.name || stock[scan.article]?.name || "Otsi toodet"}</h1>
                                    <i className="fas fa-search" onClick={this.toggleProductSearch}/>
                                </div>
                                <div>
                                    <h1>FSC</h1>
                                    <input type="checkbox" id="fsc" className="switchbutton" checked={scan.fsc || false} onChange={this.onChangeScan} disabled={!!scan._id}/>
                                </div>
                                <div>
                                    <h1>Tükiarv</h1>
                                    <input type="number" id="amount" value={scan.amount || 0} onChange={this.onChangeScan} disabled={!!scan._id} />
                                </div>
                            </div>
                            {scan.searchToggle && <div className="scan-find-product">
                                <input type="text" id="search" value={scan.search || ""} onChange={this.onChangeScan} />
                                <div>
                                    {Object.keys(stock).map(key => {
                                        let name = stock[key]?.name
                                        let type = stock[key].type
                                        if (scan.search && !name.toLowerCase().includes((scan.search || "").toLowerCase())) return
                                        return <h1 key={key} id={key} onClick={this.selectProduct}>{name}{type === "Sildid" ? " (silt)" : ""}</h1>
                                    })}
                                </div>
                            </div>}
                            {(scan._id && !scan.searchToggle) && <>
                                <a className="submit submit-printnumber" id="printnumber" onClick={this.printNumber}>Prindi number</a>
                                <a className="submit submit-mahakandmine" id="mahakandmine" onClick={this.finishRestMK}>Mahakandmine</a>
                                <a className="submit submit-align-left" id="myyk" onClick={this.finishRestMyyk}>Müük</a>
                                <a className="submit submit-align-left2" id="haljala" onClick={this.finishRestHaljala}>Haljala</a>
                            </>}
                            {(scan.phase && scan.article && scan.location?.mainzone && scan.location?.subzone && !scan.searchToggle) && <a className="submit" id="scan" onClick={this.saveRest}>Salvesta</a>}
                            {this.state.modal === "myyk" && <div className="myyk-modal">
                                <div className="myyk-modal-content">
                                    <h1 className="myyk-modal-header">Mitu tükki müüki läks?</h1>
                                    <input type="number" id="myykamount" />
                                    <a className="submit submit-align-left" id="return" onClick={this.finishRest}>Tagasi</a>
                                    <a className="submit" id="myyk" onClick={this.finishRest}>Müük</a>
                                </div>
                            </div>}
                            {this.state.modal === "haljala" && <div className="myyk-modal">
                                <div className="myyk-modal-content">
                                    <h1 className="myyk-modal-header">Mitu tükki Haljalasse läks?</h1>
                                    <input type="number" id="myykamount" />
                                    <a className="submit submit-align-left" id="return" onClick={this.finishRest}>Tagasi</a>
                                    <a className="submit" id="haljala" onClick={this.finishRest}>Valmis</a>
                                </div>
                            </div>}
                            {this.state.modal === "mahakandmine" && <div className="myyk-modal">
                                <div className="myyk-modal-content">
                                    <h1 className="myyk-modal-header">Mitu tükki maha kanti?</h1>
                                    <input type="number" id="myykamount" />
                                    <a className="submit submit-align-left" id="return" onClick={this.finishRest}>Tagasi</a>
                                    <a className="submit" id="mahakandmine" onClick={this.finishRest}>Valmis</a>
                                </div>
                            </div>}
                            {this.state.modal === "print" && <div className="print-modal">
                                <div className="print-modal-content">
                                    <a id="return" onClick={this.printNumber}>Tagasi</a>
                                    <a id="num" onClick={this.printNumber}>Number</a>
                                    <a id="bar" onClick={this.printNumber}>Triipkood</a>
                                </div>
                            </div>}
                            {this.state.confirmFinish && <div className="confirm-modal">
                                <div className="confirm-modal-content">
                                    <h1 className="confirm-modal-header">{this.state.confirmFinish === "mahakandmine" ? `Alus läheb mahakandmisele${scan.amount ? ` \n(tükiarv: ${document.getElementById("myykamount").value > scan.amount ? scan.amount : document.getElementById("myykamount").value})` : ""}` : this.state.confirmFinish === "haljala" ? `Alus läheb Haljalasse${scan.amount ? ` \n(tükiarv: ${document.getElementById("myykamount").value > scan.amount ? scan.amount : document.getElementById("myykamount").value})` : ""}` : this.state.confirmFinish === "myyk" ? `Alus läheb müüki${scan.amount ? ` \n(tükiarv: ${document.getElementById("myykamount").value > scan.amount ? scan.amount : document.getElementById("myykamount").value})` : ""}` : ""}</h1>
                                    <a className="submit submit-align-left" id="return1" onClick={this.finishRest}>Tagasi</a>
                                    <a className="submit" id={this.state.confirmFinish} onClick={this.finishRest}>Jätkan</a>
                                </div>
                            </div>}
                        </div>}
                        {scan.modal && <div className="scan-modal">
                            {scan.modal === "notfound" && <div className="scan-modal-content">
                                <h1 className="scan-modal-header">Resti koodiga {scan.restNr} ei leitud. Kas soovid selle lisada?</h1>
                                <a className="submit submit-align-left" id="no" onClick={this.scanModal}>Ei</a>
                                <a className="submit" id="yes" onClick={this.scanModal}>Jah</a>
                            </div> ||
                            scan.modal === "generated" && <div className="scan-modal-content">
                                <h1 className="scan-modal-header">Resti kood on {scan.restNr}. Prindi ja kinnita see enne kui jätkad!</h1>
                                <a className="submit submit-continue" onClick={this.scanModal}>Jätkan</a>
                            </div>}
                        </div>}
                    </div>
                    {loading && <div className="loader">
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>}
                </div>
        )
    }
}

export default Wares